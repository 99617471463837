<mat-card>
  <mat-card-header>
    <mat-card-title>
      ContactUs
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <!-- <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-3 ml-auto">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" #input>
          <button type="button"  (click)="applyFilter()" class="search-btn btn"><mat-icon>search</mat-icon></button>
          
        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive" *ngIf="dataSource" >
      <mat-table [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef>Contact Date</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Contact Date">
            {{element.createdDate |  date:'medium'}}
          </mat-cell>
        </ng-container>
  
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Full Name</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Full Name">
          {{element.name}}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Phone Number">
          {{element.email}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef>Subject</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Address">
          {{element.subject}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="message">
        <mat-header-cell *matHeaderCellDef>Message</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Status">
          {{element.message}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="empty-row">
        <mat-cell   class="no-data-cell"   *matCellDef="let element">No Data Found</mat-cell>
      </ng-container> 
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
    </mat-table>
  </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex" 
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>