<mat-card>
  <mat-card-header>
    <mat-card-title>
      Settings
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="lookupsForm">
          <div class="row">
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>First Banner Arabic</mat-label>
                <input type="text" matInput formControlName="bannerar1">
                <mat-error *ngIf="lookupsForm.controls.bannerar1.invalid" class="mt-2"
                           style="font-size: 14px !important;">You Must Insert Description *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>First Banner English</mat-label>
                <input type="text" matInput formControlName="banneren1">
                <mat-error *ngIf="lookupsForm.controls.banneren1.invalid" class="mt-2"
                           style="font-size: 14px !important;">
                  You
                  Must Insert Description *
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Second Banner Arabic</mat-label>
                <input type="text" matInput formControlName="bannerar2">
                <mat-error *ngIf="lookupsForm.controls.bannerar2.invalid" class="mt-2"
                           style="font-size: 14px !important;">
                  You Must
                  Insert Description *
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Second Banner English</mat-label>
                <input type="text" matInput formControlName="banneren2">
                <mat-error *ngIf="lookupsForm.controls.banneren2.invalid" class="mt-2"
                           style="font-size: 14px !important;">
                  You
                  Must Insert Description *
                </mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>third  Banner Arabic</mat-label>
                <input type="text" matInput formControlName="bannerar3">
                <mat-error *ngIf="lookupsForm.controls.bannerar3.invalid" class="mt-2"
                           style="font-size: 14px !important;">
                  You Must
                  Insert Description *
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>third  Banner English</mat-label>
                <input type="text" matInput formControlName="banneren3">
                <mat-error *ngIf="lookupsForm.controls.banneren3.invalid" class="mt-2"
                           style="font-size: 14px !important;">
                  You
                  Must Insert Description *
                </mat-error>
              </mat-form-field>
            </div>


          </div>
          <div class="row">
            <div class="col text-right" style="margin-top: 20px;">

              <button mat-raised-button color="accent" (click)="submitForm()" style="margin: 1px;">Update</button>
              <button mat-raised-button color="accent" (click)="cancel()" class="ml-2" style="margin: 1px;">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
