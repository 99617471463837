import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-bulk-product-prices',
  templateUrl: './bulk-product-prices.component.html',
  styleUrls: ['./bulk-product-prices.component.scss']
})
export class BulkProductPricesComponent implements OnInit {

  public categoryList: any[] = [];
  public subCategoryList: any[] = [];
  @ViewChild('form') form: any;
  modifyProductsForm: FormGroup;
  
  constructor(
    private baseService: BaseService,private fb: FormBuilder,
    public spinner: NgxSpinnerService,
    public notification: NotificationService
  ) {
    this.modifyProductsForm = this.fb.group({
      category: new FormControl('', Validators.required),
      subCategory: new FormControl({ value: '', disabled: true }),
      modificationType: new FormControl('', Validators.required),
      modificationCriteria: new FormControl('', Validators.required),
      amount: new FormControl(''),
      percentage: new FormControl(''),
    });
   }

  public ngOnInit(): void {
    this.getAllCategories()
  }

  getAllCategories(): void {
    this.spinner.show();
    const Controller = Controllers.Category;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.categoryList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  categoryChanged(categoryId: number) {
    this.modifyProductsForm.reset();
    this.getSubCategoryByCategories(categoryId);
  }

  criteriaChanged() {
    var criteria = this.modifyProductsForm.controls.modificationCriteria.value;

    if (criteria == 1) {
      this.modifyProductsForm.controls.amount.setValidators(Validators.required)
      this.modifyProductsForm.controls.percentage.clearValidators()
      this.modifyProductsForm.controls.percentage.setValue('');
    } else {
      this.modifyProductsForm.controls.percentage.setValidators(Validators.required)
      this.modifyProductsForm.controls.amount.clearValidators()
      this.modifyProductsForm.controls.amount.setValue('');
    }
  }

  getSubCategoryByCategories(categoryId: number): void {
    this.spinner.show();
    const Controller = Controllers.SubCategory;
    this.baseService.getByParentId(Controller, categoryId).subscribe(res => {
      this.subCategoryList = res;
      this.modifyProductsForm.controls.subCategory.enable();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  submitForm() {   
    if (this.modifyProductsForm.invalid) {
      this.modifyProductsForm.markAllAsTouched();
      return
    }

    this.spinner.show();
    this.baseService.modifyItemPrices(this.modifyProductsForm.value).subscribe(response => {
      this.notification.showNotification('Modify Products', 'Product Modefied Successfully', 'success');
      this.form.resetForm();
      this.spinner.hide();
    }, error => {
      this.notification.showNotification('Modify Products Failed', 'please contact system admin', 'error');
      this.form.resetForm();
      this.spinner.hide();
    })
  }
}
