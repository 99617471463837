<mat-card>
  <mat-card-header>
    <mat-card-title>
      Missing Items
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <!-- <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-3 ml-auto">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" #input>
          <button type="button" (click)="applyFilter()" class="search-btn btn"><mat-icon>search</mat-icon></button>

        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive" *ngIf="dataSource" >
      <mat-table [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">


      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Name">
          {{element.name}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="MobileNumber">
        <mat-header-cell *matHeaderCellDef>Mobile Number</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Mobile Number">
          {{element.mobileNumber}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Email">
        <mat-header-cell *matHeaderCellDef>Email</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Email">
          {{element.email}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="RequierdItems">
        <mat-header-cell *matHeaderCellDef>Requierd Items</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Requierd Items">
          {{element.requierdItems}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Document">
        <mat-header-cell *matHeaderCellDef>Document</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Document">
          <a target="_blank" href="{{element.documentPath}}">View</a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="empty-row">
        <mat-cell   class="no-data-cell"   *matCellDef="let element">No Data Found</mat-cell>
      </ng-container> 
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
    </mat-table>
  </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex"
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
