import { Statuses } from './../../../shared/global-variables/lookups';
import { Actions } from '../../../shared/global-variables/api-config';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Controllers } from 'src/shared/global-variables/api-config';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { NgxGalleryComponent, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { MatSelect } from '@angular/material/select';
import { environment } from 'src/environments/environment';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { PricingMethods } from 'src/shared/global-variables/lookups';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.scss']
})
export class ProductFormComponent implements OnInit, AfterViewInit {
  public categoryList;
  public subCategoryList;
  public pricingMethods = PricingMethods;
  // public salesList;
  public itemImagesList: any[] = [];
  public itemImagesToAdd: FormData = new FormData();
  public itemVideoToAdd: FormData = new FormData();
  public setItemsList: any[] = [];
  public originalSetItemsList: any[] = [];
  public setIds: any[] = [];
  //public setName = new FormControl();
  public setItems = new FormControl();
  public brandsList;
  public itemId: number;
  public item: any;
  public isEdit = false;
  public isUpdateMode: boolean;
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public accept = 'image/*';
  public showEditor: boolean = false

  public providersList;

  public suggestedItemsList: any[];
  public itemsList: any[];

  @ViewChild(NgxGalleryComponent) ngxGalleryComponent;
  @ViewChild('allItemsList') allItemsList: MatSelect;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  public imageUrl = environment.imagesUrl;
  public imageSasToken = environment.sasToken;
  public subCategoryIdParam: number;
  public itemPrice: any[] = [];

  public customDescriptionEditorEn = ClassicEditor;
  public customDescriptionEditorAr = ClassicEditor;
  public customeDescriptionAndValueEn = '';
  public customeDescriptionAndValueAr = '';

  public videoToShow;
  public VideoUpload: any;
  public videoAccept = 'video/*';

  status: any[] = [{ id: 1, Name: 'Active' }, { id: 2, Name: 'InActive' }, { id: 8, Name: 'Empty' }]

  public productForm = new FormGroup({
    id: new FormControl(0),
    subCategoryId: new FormControl({ value: '', disabled: false }, Validators.required),
    categoryId: new FormControl(),
    brandId: new FormControl('', Validators.required),
    isItemhidden: new FormControl(false),
    titleEn: new FormControl(''),
    titleAr: new FormControl(''),
    descriptionEn: new FormControl(''),
    descriptionAr: new FormControl(''),
    status: new FormControl(1, Validators.required),
    itemOrder: new FormControl('', [Validators.min(1)]),
    isItemIdhidden: new FormControl(false),
    partNo: new FormControl(''),
    providerId: new FormControl(),
    sku: new FormControl(''),
    customDescriptionEn: new FormControl(''),
    customDescriptionAr: new FormControl(''),
    //availability: new FormControl(''),
    isFeaturedProduct: new FormControl(''),
    madarIntegrationId: new FormControl(''),

  });
  @ViewChild('form') form: any;
  itemPriceForm: FormGroup;


  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    public notification: NotificationService,
    public router: Router
  ) {
    this.itemPriceForm = new FormGroup({
      id: new FormControl(0),
      price: new FormControl(''),
      pricingMethod: new FormControl(''),
      unitType: new FormControl('', Validators.required),
      discount: new FormControl(''),
      stock: new FormControl('', Validators.pattern("[0-9]+")),
      size: new FormControl('', Validators.required),
      itemId: new FormControl(''),
      integrationId: new FormControl(''),
      status: new FormControl('', Validators.required),
      priceBeforDiscount: new FormControl('', Validators.required)
    })
  }

  getProductFormControlByName(controlName): FormControl {
    return this.productForm.get(controlName) as FormControl;
  }

  ngOnInit(): void {
    this.getAllCategories();
    this.getAllBrands();
    // this.getAllSales();
    this.getAllProviders()
    this.getItemById();
    this.initializeGalleryConfigs();
  }

  changeSizeValidation() {
    if (this.itemPriceForm.controls.pricingMethod.value == 2) {
      this.itemPriceForm.controls.size.setValidators(Validators.pattern("^-?[0-9]*[0-9,\.]+$"));
      this.itemPriceForm.controls.size.updateValueAndValidity();
    }
    else {
      this.itemPriceForm.controls.size.clearValidators();
      this.itemPriceForm.controls.size.setValidators(Validators.required);
      this.itemPriceForm.controls.size.updateValueAndValidity();
    }
    console.log(this.itemPriceForm);
  }
  getPricingMethod(id: number) {
    if (id > 0) {
      return this.pricingMethods.find(x => x.id == id).nameEn
    }
    return this.pricingMethods[0].nameEn
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
    }, 1000);
  }
  public getItemById() {
    this.route.params.subscribe(params => {
      this.itemId = params.itemId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == false) {
        this.productForm.disable();
      }
      this.baseService.getByIdWithRelated(Controllers.Item, params.itemId).subscribe(response => {
        this.item = response;        
        // console.log(this.categoryList)
        // console.log(response.subCategory.categoryId)
        this.getProductFormControlByName('categoryId').setValue(response.subCategory.categoryId);
        this.itemPrice = this.item.itemPrice
        this.subCategoryIdParam = this.item.subCategoryId;
        this.getAllItems();
        this.productForm.patchValue(response);
        this.customeDescriptionAndValueEn = this.productForm.controls.customDescriptionEn.value
        this.customeDescriptionAndValueAr = this.productForm.controls.customDescriptionAr.value
        this.showEditor = true

        this.fetchItemImages(response.itemImages?.filter(x => x.status != Statuses.VideoAttachment));
        this.videoToShow = response?.vedioUrl
      })
    })
  }
  public enableUpdateMode() {
    this.getProductFormControlByName('brandId').enable();
    this.getProductFormControlByName('itemOrder').enable();
    this.getProductFormControlByName('status').enable();
    this.isUpdateMode = true;
    this.ngAfterViewInit();
  }
  public initializeGalleryConfigs() {
    this.galleryOptions = [
      {
        width: '1000px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: 'auto',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [];
  }
  submitForm(): void {
    if (this.productForm.invalid || this.itemPrice.length == 0) {
      this.productForm.markAllAsTouched();
      this.itemPriceForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const setDto = {
        setName: '',//this.setName.value,
        setitems: this.setIds
      }
      this.getProductFormControlByName('customDescriptionEn').setValue(this.customeDescriptionAndValueEn)
      this.getProductFormControlByName('customDescriptionAr').setValue(this.customeDescriptionAndValueAr)
      const productForm = this.productForm.getRawValue();
      productForm.itemPrice = this.itemPrice
      if (!this.VideoUpload) {
        productForm.vedioUrl = this.videoToShow
      }
      // console.log(this.itemPrice)
      this.baseService.editItem(Controllers.Item, productForm).subscribe(response => {
        this.uploadItemImages();
        this.UploadVideo(response.id)
        this.addSuggestions(response.id)
        this.router.navigate(['/setup/product'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Update Product Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Update Product Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  private UploadVideo(itemId: number) {
    this.spinner.show()
    this.baseService.postItemImages(Controllers.Item, Actions.AddItemVideo, itemId, this.itemVideoToAdd).subscribe(response => {
      this.spinner.hide()
    }, error => {
      this.spinner.hide()
      this.removeVideo()
      if (error.status === 400) {
        this.notification.showNotification('Add Product Failed', 'Uploaded files are invalid', 'error');
      }
      else {
        this.notification.showNotification('Add Product Failed', 'Something went wrong please contact system admin', 'error');
      }
    })
  }



  public checkIfItemExistInSetItems(itemId) {
    const item = this.setItemsList.find(x => x.id == itemId);
    if (item) {
      return true;
    }
    return false;
  }
  private uploadItemImages() {
    this.baseService.postItemImages(Controllers.Item, Actions.AddItemImages, this.itemId, this.itemImagesToAdd).subscribe(response => {
      this.notification.showNotification('Update Product', 'Product Updated Successfully', 'success');
      this.spinner.hide();
    }, error => {
      if (error.status === 400) {
        this.notification.showNotification('Add Product Failed', 'Uploaded files are invalid', 'error');
      }
      else {
        this.notification.showNotification('Update Product Failed', 'Something went wrong please contact system admin', 'error');
      }
      this.spinner.hide();
    })
  }
  /* Get Lookups */
  async getAllCategories() {
    this.spinner.show();
    const Controller = Controllers.Category;
    await this.baseService.getAllItems(Controller).subscribe(async res => {
      this.categoryList = res;
      // console.log('this.categoryList: ', this.categoryList);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  getAllProviders(): void {
    this.spinner.show();
    const Controller = Controllers.Provider;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.providersList = res;

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  getAllBrands(): void {
    this.spinner.show();
    const Controller = Controllers.Brand;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.brandsList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }


  getAllSetItems(): void {
    this.spinner.show();
    const Controller = Controllers.Item;
    this.baseService.getItemsBySetId(Controller, this.item.setId).subscribe(res => {
      this.setItemsList = res;
      this.setItemsList.forEach(item => {
        this.setIds.push(item.id)
        this.originalSetItemsList.push(item.id);
      });
      if (this.allItemsList) {
        this.allItemsList.options.forEach(s => {
          const item = this.setItemsList.find(x => x.id == s.value);
          if (item) {
            s.select();
          }
        });
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  // getAllSales(): void {
  //   this.spinner.show();
  //   const Controller = Controllers.Sale;
  //   this.baseService.getAllItems(Controller).subscribe(res => {
  //     this.salesList = res;
  //     this.spinner.hide();
  //   }, error => {
  //     this.spinner.hide();
  //     console.log(error);
  //   });
  // }
  async getSubCategoryByCategories(categoryId: number) {
    this.spinner.show();
    const Controller = Controllers.SubCategory;
    await this.baseService.getByParentId(Controller, categoryId).subscribe(async res => {
      this.subCategoryList = res;
      console.log(' this.subCategoryList: ', this.subCategoryList);

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  public deleteSetItem(itemId: number) {
    const isItemExist = this.originalSetItemsList.find(x => x == itemId);
    if (isItemExist) {
      this.baseService.deleteSetItem(itemId).subscribe(res => {
        console.log('set item deleted');
      }, error => {

      });
    }
  }
  itemSelectionChange(event) {
    console.log(event);
    if (!event.selected) {
      this.deleteSetItem(event.value);
    }
    const originalItem = this.itemsList.find(x => x.id == event.value)
    const selectedItem = this.setItemsList.find(x => x.id == event.value)
    if (!selectedItem && event.selected) {
      console.log(originalItem);
      this.setItemsList.push(originalItem);
      this.setIds.push(event.value);
    }
    if (selectedItem && !event.selected) {
      this.setItemsList = this.setItemsList.filter(x => x.id != event.value);
      this.setIds = this.setIds.filter(x => x != event.value);
    }
  }

  public onImagesSelected() {
    this.spinner.show()
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.itemImagesToAdd.append('files', file);
        var imageToShow;
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          imageToShow = reader.result;
          this.galleryImages.push({ big: imageToShow, medium: imageToShow, small: imageToShow });
          this.spinner.hide();
        }, false);
        if (file) {
          imageToShow = reader.readAsDataURL(file);
          this.spinner.hide();
        }
      }
    };
    fileUpload.click();
  }
  fetchItemImages(itemImages: any[]) {

    itemImages.forEach(image => {
      this.galleryImages.push({
        label: image.id,
        big: image.imagePath, //this.imageUrl + +this.imageSasToken
        medium: image.imagePath, // this.imageUrl + +this.imageSasToken
        small: image.imagePath //  this.imageUrl + +this.imageSasToken
      });
    });
  }

  public removeImageFromGallery() {
    const imageId = this.ngxGalleryComponent.labels[this.ngxGalleryComponent.selectedIndex];
    this.baseService.removeItemImage(Controllers.Item, Number(imageId)).subscribe(res => {
      const imageIndex = this.ngxGalleryComponent.selectedIndex;
      const deltedImage = this.galleryImages[imageIndex];
      this.galleryImages = this.galleryImages.filter(image => image != deltedImage)
    });
  }

  isItemHiddenChanged(event) {
    if (event.checked) {
      //this.setName.setValidators();
      this.setItems.setValidators(Validators.required);
    } else {
      this.setItemsList = [];
    }
  }
  check(checked: boolean): void {
    this.getProductFormControlByName('isFeaturedProduct').setValue(checked);
  }
  public getDefaultItemImage(itemImages) {

    const defaultImage = itemImages.find(x => x.isDefault);
    const defaultFirstImage = itemImages[0].imagePath;
    if (defaultImage) {
      return defaultImage.imagePath;
    }
    else {
      return defaultFirstImage;
    }
  }
  public getDefaultSelectedItemImage(itemImages) {
    if (!itemImages.length) {
      return '../../assets/Images/logo.png';
    }
    const defaultImage = itemImages[0];
    const defaultFirstImage = itemImages[0].imagePath;
    if (defaultImage) {
      return this.imageUrl + defaultImage.imagePath;
    }
    else {
      return defaultFirstImage;
    }
  }

  onAddItemPrice() {
    if (!this.itemPriceForm.invalid) {    
      if (this.itemPriceForm.get('id').value > 0) {
        this.baseService.editItem(Controllers.ItemPrice, this.itemPriceForm.getRawValue()).subscribe(res => {
          this.notification.showNotification('Item Price', 'Item Price Has Been updated Successfully', 'success')
          this.getItemById()
          this.form.resetForm();
        })
      }else{        
      this.itemPrice.push(this.itemPriceForm.value)
      this.form.resetForm();
      this.itemPriceForm.controls.id.setValue(0)
      this.itemPriceForm.controls.itemId.setValue(0)
      }
    }  
  }
  onDeleteItemPrice(itemPrice) {
    if (itemPrice.id > 0) {
      this.spinner.show()
      this.baseService.removeItem(Controllers.ItemPrice, itemPrice.id).subscribe(res => {
        this.spinner.hide()
        this.notification.showNotification('Item Price', 'Item Price Has Been Deleted Successfully', 'success')
        this.itemPrice = this.itemPrice.filter(x => x != itemPrice)
      }, error => {
        this.spinner.hide()
        if (error.status == 400) {
          this.notification.showNotification('Item Price', error.error.Message, 'error')
        } else {
          this.notification.showNotification('Item Price', 'Something went wrong !!', 'error')
        }
      })
    } else {
      this.itemPrice = this.itemPrice.filter(x => x != itemPrice)
    }
  }

  onEditItemPrice(itemPrice) {
    this.itemPriceForm.patchValue(itemPrice)
  }
  onUpdateItemPrice() {
    if (this.itemPriceForm.get('id').value > 0) {
      this.baseService.editItem(Controllers.ItemPrice, this.itemPriceForm.getRawValue()).subscribe(res => {
        this.notification.showNotification('Item Price', 'Item Price Has Been updated Successfully', 'success')
        this.getItemById()
        this.itemPriceForm.reset()
      })
    }
  }

  GetStatusName(statusId) {
    return this.status.find(x => x.id == statusId) ? this.status.find(x => x.id == statusId).Name : null
  }

  //#region Ck Editor Handler
  /**
   * used for english editor ,handling input
   * @param param0
   */
  public onChangeDescriptionEn({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.customeDescriptionAndValueEn = data
  }
  /**
  * used for Arabic editor ,handling input
  * @param param0
  */
  public onChangeDescriptionAr({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.customeDescriptionAndValueAr = data
  }
  //#endregion

  //#region Video Handler
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  removeVideo() {
    this.videoToShow = null
    this.itemVideoToAdd = new FormData();
    this.baseService.deleteVideo(Controllers.Item, this.itemId).subscribe(res => {
    });
  }

  onVideoSelected() {
    this.spinner.show()
    this.VideoUpload = document.getElementById('videoUpload') as HTMLInputElement;
    console.log(this.VideoUpload);
    this.VideoUpload.onchange = () => {
      for (let index = 0; index < this.VideoUpload.files.length; index++) {
        const file = this.VideoUpload.files[index];
        console.log(file)
        console.log('inside vidoe upload')
        this.itemVideoToAdd.append('file', file);

        let reader = new FileReader();
        reader.addEventListener("load", () => {

          this.videoToShow = reader.result;
          //this.galleryImages.push({ big: imageToShow, medium: imageToShow, small: imageToShow });
        }, false);
        if (file) {
          this.videoToShow = reader.readAsDataURL(file);
        }
      }
    };
    this.spinner.hide();
    this.VideoUpload.click();
  }

  getAllItems() {
    this.baseService.getAllItems(Controllers.Item).subscribe(res => {
      this.itemsList = (res as any)
      this.getSuggestedItems()
    })
  }

  getSuggestedItems() {
    this.baseService.getSuggestedItems(this.itemId).subscribe(res => {
      this.suggestedItemsList = (res as any).map(item => item.id);
    })
  }

  addSuggestions(itemId) {
    this.baseService.addSuggestedItems(itemId, this.suggestedItemsList ?? []).subscribe(res => {
    })

  }

}
