import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BannerService } from 'src/shared/services/banner.service';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-brand-add',
  templateUrl: './brand-add.component.html',
  styleUrls: ['./brand-add.component.scss']
})
export class BrandAddComponent implements OnInit {
  public isEdit = false;
  public brandId: number;
  public isUpdateMode: boolean;
  brandForm = new FormGroup({
    id: new FormControl(0),
    brandNameEn: new FormControl('', Validators.required),
    brandNameAr: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required)
  });

  getFormControlByName(controlName: string): FormControl {
    return this.brandForm.get(controlName) as FormControl;
  }
  constructor( private itemCustomService: BannerService,
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    private route: ActivatedRoute,
    public notification: NotificationService) { }

    ngOnInit(): void {
      this.route.params.subscribe(params => {
        this.brandId = params.brandId;
        var stringValue = params.isUpdateMode;
        this.isUpdateMode = (stringValue == "true");
        if (this.isUpdateMode == true) {
          this.isEdit = true;
          const Controller = Controllers.Brand;
          this.baseService.getById(Controller, this.brandId ).subscribe(res => {
            console.log('res: ', res);         
            this.brandForm.patchValue(res);
          });
          
        }
      });
    }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }
  submitForm(): void {
    if (this.brandForm.invalid){
      this.brandForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const brandForm = this.brandForm.value;
      this.baseService.postItem(Controllers.Brand, Actions.PostItem, brandForm).subscribe(response => {
        this.spinner.hide();
        
        this.notification.showNotification('Add Brand', 'Brand Modified Successfully', 'success');
        this.router.navigate(['/brand'])
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Add Brand Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Brand Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.brandForm.invalid){
      this.brandForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const brandForm = this.brandForm.value;
      this.baseService.postItem(Controllers.Brand, Actions.EditItem, brandForm).subscribe(response => {
        this.spinner.hide();
       
        
        this.notification.showNotification('Edit Brand', 'Brand Modified Successfully', 'success');
        this.router.navigate(['/brand'])
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Edit Brand Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Brand Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}

