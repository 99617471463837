import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit {
  displayedColumns: string[] = ['saleName', 'isActive', 'actions'];
  public isEdit = false;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  ItemControl: FormControl = new FormControl();
  SubCategoryControl: FormControl = new FormControl();
  ClientsControl: FormControl = new FormControl();

  public subCategories : any[] = []
  public items : any[] = [];
  public Clients : any[] = [];

  filteredItems: Observable<any[]>;
  filteredSubCategories: Observable<any[]>;
  filteredClients: Observable<any[]>;

  pageIndex = 0;
  pageSize=10;
  public saleList;
  public baseSearch;
  public totalListCount;
  /* 
          public double OldPrice { get; set; }
        public double NewPrice { get; set; }
        public DateTime EndDate { get; set; }
        public DateTime? CreatedDate { get; set; }

  */
  saleForm = new FormGroup({
    id: new FormControl(0),
    itemId: new FormControl('',Validators.required),
    subCategory: new FormControl('',Validators.required),
    percentage: new FormControl('',[Validators.required,Validators.min(1),Validators.max(100)]),
    clientId:new FormControl('',Validators.required),
    endDate: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required)
  });

  getFormControlByName(controlName: string): FormControl {
    return this.saleForm.get(controlName) as FormControl;
  }
  SetFormControlValidator(controlName: string) {
     this.saleForm.get(controlName).setValidators(Validators.required);
     this.saleForm.get(controlName).updateValueAndValidity()
  }
  RemoveFormControlValidator(ControlName){
    this.saleForm.get(ControlName).clearValidators();
     this.saleForm.get(ControlName).updateValueAndValidity()
  }

  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllSales({ pageSize: this.pageSize, pageNumber: 1 });
    this.getAllItems()
    this.getAllSubCategory()
    this.getAllClients()  
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getAllSales({ pageSize: this.pageSize, pageNumber: 1, name: filterValue });
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  toggleStatus(id): void{
    this.baseService.toggleSaleStatus(id).subscribe(res => {
      this.saleList.find(x => x.id === id).status = res.status;
      this.notification.showNotification('Sale ', 'Sale Status Changed Successfully', 'success');
    }, error => {
      this.notification.showNotification('Toggle Status Failed', 'Something went wrong please contact system admin', 'error');
    });
  }
  editItem(element: any): void {
    this.isEdit = true;
    this.saleForm.patchValue(element);
  }
  getAllSales(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Sale;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.saleList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.saleList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  deleteItem(sale): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Sale',
          content: 'Are you sure you want to delete a sale?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Sale;
        this.baseService.removeItem(Controller, sale.id).subscribe(res => {
          this.notification.showNotification('Sale ', 'Sale Deleted Successfully', 'success');
          this.deleteItemFromList(sale.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Sale Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Sale Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllSales({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1 });
  }
  submitForm(): void {
    if (this.saleForm.invalid) {
      console.log(this.saleForm)
      this.saleForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const saleForm = this.saleForm.value;
      saleForm.endDate = this.convertToUTC(new Date(saleForm.endDate));
      this.baseService.postItem(Controllers.Sale, Actions.PostItem, saleForm).subscribe(response => {
        this.spinner.hide();
        this.pushItemToList(response);
        this.notification.showNotification('Add Sale', 'Sale Added Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Sale Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Sale Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  cancel(): void {
    this.isEdit = false;
    this.saleForm.reset();

    this.getFormControlByName('status').setValue(2);
    this.getFormControlByName('id').setValue(0);
  }

  pushItemToList(item): void {
    this.saleList.push(item);
  }

  deleteItemFromList(id: number): void {
    this.saleList = this.saleList.filter(sale => sale.id !== id);
  }

  convertToUTC(date: Date): Date {
    if (date) {
      date = new Date(date);
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    } else { return null; }
  }

  editForm(): void {
    if (this.saleForm.invalid) {
      this.saleForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const saleForm = this.saleForm.value;
      saleForm.endDate = this.convertToUTC(new Date(saleForm.endDate));
      this.baseService.postItem(Controllers.Sale, Actions.EditItem, saleForm).subscribe(response => {
        this.spinner.hide();
        this.deleteItemFromList((response as any).id);
        this.pushItemToList(response);
        this.notification.showNotification('Edit Sale', 'Sale Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Sale Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Sale Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  getAllItems(){
      this.baseService.getAllItems(Controllers.Item).subscribe(async res =>{
      this.items =await res as any[];
      this.filteredItems = this.ItemControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterItems(val))
      );

      })
  }
  getAllSubCategory(){
    this.baseService.getAllItems(Controllers.SubCategory).subscribe(res =>{
        this.subCategories = res as any[]
        this.filteredSubCategories = this.SubCategoryControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterSubCategoires(val))
      );
    })
  }
  getAllClients(){
    this.baseService.getAllItems(Controllers.User).subscribe(res =>{
      this.Clients = res as any[]
      this.filteredClients = this.ClientsControl.valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterClients(val))
      );
    })
  }


  filterSubCategoires(val: string): string[] {
    return this.subCategories.filter(option => option?.name?.toLowerCase().indexOf(val?.toLowerCase()) === 0 );
  }
  filterItems(val: string): string[] {
    return this.items.filter(option => option?.titleEn?.toLowerCase().indexOf(val?.toLowerCase()) === 0);
  }
  
  filterClients(val: string): string[] {
    return this.Clients.filter(option => option?.fullName?.toLowerCase().indexOf(val?.toLowerCase()) === 0);
  }

  displayWith(value: any) {
    return value?.titleEn;
  }
  displayWithCategory(value: any) {
    return value?.name;
  }
  displayWithClient(value : any){
    return value?.fullName
  }

  getClientName(id){
    return this.Clients.find(x=>x.id == id) != null ? this.Clients.find(x=>x.id == id)?.fullName : null
  }
  getSubCategorytName(id){
    return this.subCategories.find(x=>x.id == id) != null ? this.subCategories.find(x=>x.id == id)?.name : null
  }
  getItemName(id){
    const item = this.items.find(x=>x.id == id)
    return item ? item?.titleEn : null
  }

  onItemSelected(){
    this.getFormControlByName('itemId').setValue(this.ItemControl.value.id)
    this.RemoveFormControlValidator('subCategory')
    this.RemoveFormControlValidator('clientId')

  }

  onSubCategoriesSelected(){
    this.getFormControlByName('subCategory').setValue(this.SubCategoryControl.value.id)
    this.RemoveFormControlValidator('itemId')
    this.RemoveFormControlValidator('clientId')


  }
  onClientSelected(){
    this.getFormControlByName('clientId').setValue(this.ClientsControl.value.id)
    this.RemoveFormControlValidator('itemId')
    this.RemoveFormControlValidator('subCategory')
  }


}
