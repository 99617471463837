import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-faq-add',
  templateUrl: './faq-add.component.html',
  styleUrls: ['./faq-add.component.scss']
})
export class FaqAddComponent implements OnInit {
  public isEdit = false;
  public editId: number;
  public isUpdateMode: boolean;

    faqsForm = new FormGroup({
    id: new FormControl(0),
    question: new FormControl('', Validators.required),
    questionAr: new FormControl('', Validators.required),
    answer: new FormControl('', Validators.required),
    answerAr: new FormControl('', Validators.required),
    groupId: new FormControl(1),
    status: new FormControl(2, Validators.required)
  });

  getFormControlByName(controlName: string): FormControl {
    return this.faqsForm.get(controlName) as FormControl;
  }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  constructor( public router: Router,public route:ActivatedRoute,   public spinner: NgxSpinnerService,  private baseService: BaseService,public notification: NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.editId = params.editId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Faqs;
        this.baseService.getById(Controller, this.editId).subscribe(res => {
          console.log('res: ', res);
          this.faqsForm.patchValue(res);
        });

      }
    });
  }
 submitForm(): void {
    if (this.faqsForm.invalid) {
      this.faqsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const faqsForm = this.faqsForm.value;
      faqsForm.groupId = 1
      this.baseService.postItem(Controllers.Faqs, Actions.PostItem, faqsForm).subscribe(response => {
        this.spinner.hide();
    
        this.notification.showNotification('Add Faqs', 'Faqs Modified Successfully', 'success');
        this.router.navigate(['/faqs-managment'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Faqs Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Faqs Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.faqsForm.invalid) {
      this.faqsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const faqsForm = this.faqsForm.value;
      this.baseService.postItem(Controllers.Faqs, Actions.EditItem, faqsForm).subscribe(response => {
        this.spinner.hide();
       
        this.notification.showNotification('Edit Faqs', 'Faqs Modified Successfully', 'success');
        this.router.navigate(['/faqs-managment'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Faqs Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Faqs Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
