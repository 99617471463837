<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Faqs': 'Edit Faqs'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="faqsForm">
            <div class="row">
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Question </mat-label>
                  <input type="text" matInput formControlName="question">
                  <mat-error *ngIf="faqsForm.controls.question.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Question Ar</mat-label>
                  <input type="text" matInput formControlName="questionAr">
                  <mat-error *ngIf="faqsForm.controls.questionAr.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Answer</mat-label>
                  <input type="text" matInput formControlName="answer">
                  <mat-error *ngIf="faqsForm.controls.answer.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field appearance="outline">
                  <mat-label>Answer Ar</mat-label>
                  <input type="text" matInput formControlName="answerAr">
                  <mat-error *ngIf="faqsForm.controls.answerAr.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Group</mat-label>
                  <mat-select formControlName="groupId">
                    <mat-option *ngFor="let group of groupsList" [value]="group.id">
                      {{group.groupName}} </mat-option>
                  </mat-select>
                  <mat-error *ngIf="faqsForm.controls.groupId.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div> -->
              <div class="col"  style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">Is Active?</mat-checkbox>
              </div>
              
            </div>
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()" style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()" style="margin: 1px;">Update</button>
                <button mat-raised-button color="accent"  [routerLink]="['/faqs-managment']"  style="margin: 1px;">Cancel</button>
               </div>
            </div>
          </form>
        </div>
      </div>
  </mat-card-content>
  </mat-card>
 