import { environment } from 'src/environments/environment';
import { BannerCustomService } from '../../shared/services/banner-custom.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';
 
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  displayedColumns: string[] = ['DescriptionEn', 'DescriptionAr', 'Status', 'actions'];
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public hostLink = environment.host;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public bannerList;
  pageIndex=0;
  pageSize=10;
  public totalListCount;
  constructor(
    private baseService: BaseService,
    private bannerCustomService: BannerCustomService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllBanners({ pageSize: 10, pageNumber: 1 });
  }

  searchValue: string;
  applyFilter( ): void {    
    this.pageIndex = 0;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getAllBanners({ pageSize: this.pageSize, pageNumber: 1, nameAr: this.searchValue, nameEn: this.searchValue });
  }

  editItem(element: any): void {
    this.router.navigate(['/edit-banner/' + element.id+'/true'])  
  }

  getAllBanners(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Banner;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.bannerList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.bannerList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }


  deleteItem(banner): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Banner',
          content: 'Are you sure you want to delete a banner?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Banner;
        this.baseService.removeItem(Controller, banner.id).subscribe(res => {
          this.notification.showNotification('Banner ', 'Banner Deleted Successfully', 'success');
          this.deleteItemFromList(banner.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Banner Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Banner Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllBanners({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1 , nameAr: this.searchValue, nameEn: this.searchValue});
  }

 
  // cancel(): void {
  //   this.isEdit = false;
  //   this.bannerForm.reset();
  //   this.bannerForm.markAsUntouched()
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('id').setValue(0);
  //   this.clearFileInput('imageDesktopEn');
  //   this.clearFileInput('imageMobileAr');
  //   this.clearFileInput('imageMobileEn');
  //   this.clearFileInput('imageDesktopEn');
  // }

  pushItemToList(item): void {
    this.bannerList.push(item);
    this.dataSource = new MatTableDataSource(this.bannerList)
  }

  deleteItemFromList(id: number): void {
    this.bannerList = this.bannerList.filter(banner => banner.id !== id);
    this.dataSource = new MatTableDataSource(this.bannerList)
  }

 
}
