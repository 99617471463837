
import { Actions } from '../../shared/global-variables/api-config';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { environment } from 'src/environments/environment';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BannerService } from 'src/shared/services/banner.service';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  displayedColumns: string[] = ['itemDescription', 'Image', 'Status', 'actions'];
  public isEdit = false;
  public selectedFile;
  public hostLink = environment.host;
  public imageSasToken = environment.sasToken;
  @Input() title = '';
  @Input() groupId = '';
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public itemList;
  @ViewChild('fileInput') myInputVariable: ElementRef;
  public totalListCount;
  lookupsForm = new FormGroup({
    bannerar1: new FormControl(),
    banneren1: new FormControl(),
    bannerar2: new FormControl(),
    banneren2: new FormControl(),
    bannerar3: new FormControl(),
    banneren3: new FormControl(),
  });
  getFormControlByName(controlName: string): FormControl {
    return this.lookupsForm.get(controlName) as FormControl;
  }

  constructor(
    private baseService: BaseService,
    private itemCustomService: BannerService,
    public spinner: NgxSpinnerService,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllCategories({ pageSize: 10, pageNumber: 1, status: 3 });
  }
  // ngAfterViewInit(): void {
  //   this.getFormControlByName('typeId').setValue(this.groupId);
  // }
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getAllCategories({ pageSize: 10, pageNumber: 1, nameEn: filterValue, status: 3 });
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  editItem(element: any): void {
    this.isEdit = true;
    this.lookupsForm.patchValue(element);
  }

  getAllCategories(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Lookups;
    this.baseService.getBanners().subscribe(res => {
      this.getFormControlByName('bannerar1').setValue((res as any).firstBanner.discriptionAr ?? "");
      this.getFormControlByName('banneren1').setValue((res as any).firstBanner.discriptionEn ?? "");
      this.getFormControlByName('bannerar2').setValue((res as any).secondBanner.discriptionAr ?? "");
      this.getFormControlByName('banneren2').setValue((res as any).secondBanner.discriptionEn ?? "");
      this.getFormControlByName('bannerar3').setValue((res as any).thirdBanner.discriptionAr ?? "");
      this.getFormControlByName('banneren3').setValue((res as any).thirdBanner.discriptionEn ?? "");

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  handleFileInput(file): void {
    this.selectedFile = file;
    this.getFormControlByName('imagePath').setValue(file.name);
  }

  clearFileInput(): void {
    this.myInputVariable.nativeElement.value = '';
    this.selectedFile = null;
    this.getFormControlByName('imagePath').setValue('');
  }

  deleteItem(item): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Item',
          content: 'Are you sure?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Lookups;
        this.baseService.removeItem(Controller, item.id).subscribe(res => {
          this.notification.showNotification('Item ', 'Item Deleted Successfully', 'success');
          this.deleteItemFromList(item.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Item Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Item Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }

  changePage(event): void {
    this.getAllCategories({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1 });
  }

  submitForm(): void {

    this.spinner.show();
    const lookupsForm = this.lookupsForm.value;
    this.itemCustomService.postSettings(lookupsForm).subscribe(response => {
      this.spinner.hide();
      this.notification.showNotification('Add Item', 'Item Added Successfully', 'success');
    }, error => {
      console.log(error);
      if (error.status === 400) {
        this.notification.showNotification('Add Item Failed', error.error.Message, 'error');
      }
      else {
        this.notification.showNotification('Add Item Failed', 'Something went wrong please contact system admin', 'error');
      }
      this.spinner.hide();
    });
  }

  cancel(): void {
    this.isEdit = false;
    this.lookupsForm.reset();
    this.lookupsForm.markAsUntouched()
    this.getFormControlByName('status').setValue(2);
    this.getFormControlByName('id').setValue(0);
    this.clearFileInput()
  }

  pushItemToList(item): void {
    this.itemList.push(item);
    this.dataSource = new MatTableDataSource(this.itemList)
  }

  deleteItemFromList(id: number): void {
    this.itemList = this.itemList.filter(item => item.id !== id);
    this.dataSource = new MatTableDataSource(this.itemList)
  }

  editForm(): void {
    if (this.lookupsForm.invalid) {
      this.lookupsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const lookupsForm = this.lookupsForm.value;
      this.itemCustomService.updateItemWithFile(lookupsForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();
        this.deleteItemFromList((response as any).id);
        this.pushItemToList(response);
        this.notification.showNotification('Edit Item', 'Item Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Item Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Item Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}

