import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Action } from 'rxjs/internal/scheduler/Action';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  displayedColumns: string[] = ['reviewsName','description', 'isActive', 'actions'];
 
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource <any>;
  public reviewsList;
  public baseSearch;
  public totalListCount;
  pageIndex =0;
  pageSize=10;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllReviewss({pageSize: this.pageSize, pageNumber: 1});
  }

  searchValue: string;
  applyFilter( ): void {    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex =0;
    this.getAllReviewss({pageSize: this.pageSize, pageNumber: 1, name: this.searchValue});
  }

  
  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.router.navigate(['/edit-review/' + element.id+'/true'])  
  }
  getAllReviewss(baseSearch): void {    
    this.spinner.show();
    const Controller = Controllers.Reviews;
    this.baseService.getListForAdmin(Controller,Actions.GetListForAdmin, baseSearch).subscribe(res => {
      this.reviewsList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.reviewsList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  deleteItem(reviews): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Reviews',
          content:  'Are you sure you want to delete a review?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Reviews;
        this.baseService.removeItem(Controller, reviews.id).subscribe(res => {
          this.notification.showNotification('Reviews ', 'Reviews Deleted Successfully', 'success');
          this.deleteItemFromList(reviews.id);
          this.spinner.hide();
        }, error => {
            this.spinner.hide();
            if (error.status === 400){
              this.notification.showNotification('Delete Reviews Failed', error.error.Message, 'error');
            }
            else {
              this.notification.showNotification('Delete Reviews Failed', 'Something went wrong please contact system admin', 'error');
            }
        });
      }
    });
}
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllReviewss({pageSize: event.pageSize, pageNumber: event.pageIndex + 1, name: this.searchValue});
  }
 

  // cancel(): void{
  //   this.isEdit = false;
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('descriptionEn').setValue('');
  //   this.getFormControlByName('descriptionAr').setValue('');
  //   this.getFormControlByName('userDescriptionEn').setValue('');
  //   this.getFormControlByName('userDescriptionAr').setValue('');
  //   this.getFormControlByName('userName').setValue('');
  //   this.getFormControlByName('logoPath').setValue('');
  //   this.getFormControlByName('userImagePath').setValue('');
  //   this.getFormControlByName('id').setValue(0);
  //   this.clearLogoFileInput()
  //   this.clearReviewerFileInput()
  // }

  pushItemToList(item): void {
    this.reviewsList.push(item);
    this.dataSource = new MatTableDataSource(this.reviewsList)
  }

  deleteItemFromList(id: number): void{
    this.reviewsList = this.reviewsList.filter(reviews => reviews.id !== id);
    this.dataSource = new MatTableDataSource(this.reviewsList)
  }

 
}
