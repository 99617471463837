import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-application-exceptions',
  templateUrl: './application-exceptions.component.html',
  styleUrls: ['./application-exceptions.component.scss']
})
export class ApplicationExceptionsComponent implements OnInit {
  displayedColumns: string[] = ['applicationExceptionsName', 'isActive', 'actions'];
  public isEdit = false;
  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource <any>;
  public applicationExceptionsList;
  public baseSearch;
  public totalListCount;
  applicationExceptionsForm = new FormGroup({
    id: new FormControl(0),
    applicationExceptionsName: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required)
  });
  pageSize=10;
  getFormControlByName(controlName: string): FormControl {
    return this.applicationExceptionsForm.get(controlName) as FormControl;
  }

  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }
  pageIndex =0;
  ngOnInit(): void {
    this.getAllapplicationExceptionss({pageSize: 10, pageNumber: 1});
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getAllapplicationExceptionss({pageSize: this.pageSize, pageNumber: 1, name: filterValue});
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }
  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.isEdit = true;
    this.applicationExceptionsForm.patchValue(element);
  }
  getAllapplicationExceptionss(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.AppSettings;
    this.baseService.getApplicationExceptions(Controller, baseSearch).subscribe(res => {
      this.applicationExceptionsList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.applicationExceptionsList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  deleteItem(applicationExceptions): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete applicationExceptions',
          content:  'Are you sure?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.AppSettings;
        this.baseService.removeItem(Controller, applicationExceptions.id).subscribe(res => {
          this.notification.showNotification('applicationExceptions ', 'applicationExceptions Deleted Successfully', 'success');
          this.deleteItemFromList(applicationExceptions.id);
          this.spinner.hide();
        }, error => {
            this.spinner.hide();
            if (error.status === 400){
              this.notification.showNotification('Delete applicationExceptions Failed', error.error.Message, 'error');
            }
            else {
              this.notification.showNotification('Delete applicationExceptions Failed', 'Something went wrong please contact system admin', 'error');
            }
        });
      }
    });
}
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllapplicationExceptionss({pageSize: event.pageSize, pageNumber: event.pageIndex + 1});
  }
  submitForm(): void {
    if (this.applicationExceptionsForm.invalid){
      this.applicationExceptionsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const applicationExceptionsForm = this.applicationExceptionsForm.value;
      this.baseService.postItem(Controllers.AppSettings, Actions.PostItem, applicationExceptionsForm).subscribe(response => {
        this.spinner.hide();
        this.pushItemToList(response);
        this.notification.showNotification('Add applicationExceptions', 'applicationExceptions Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Add applicationExceptions Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add applicationExceptions Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  cancel(): void{
    this.isEdit = false;
    this.getFormControlByName('status').setValue(2);
    this.getFormControlByName('applicationExceptionsName').setValue('');
    this.getFormControlByName('id').setValue(0);
  }

  pushItemToList(item): void {
    this.applicationExceptionsList.push(item);
  }

  deleteItemFromList(id: number): void{
    this.applicationExceptionsList = this.applicationExceptionsList.filter(applicationExceptions => applicationExceptions.id !== id);
  }

  editForm(): void {
    if (this.applicationExceptionsForm.invalid){
      this.applicationExceptionsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const applicationExceptionsForm = this.applicationExceptionsForm.value;
      this.baseService.postItem(Controllers.AppSettings, Actions.EditItem, applicationExceptionsForm).subscribe(response => {
        this.spinner.hide();
        this.deleteItemFromList((response as any).id);
        this.pushItemToList(response);
        this.notification.showNotification('Edit applicationExceptions', 'applicationExceptions Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Edit applicationExceptions Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit applicationExceptions Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
