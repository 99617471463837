import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  displayedColumns: string[] = ['faqsQuestion', 'faqsAnswer', 'isActive', 'actions'];
  emptyData = new MatTableDataSource([{ empty: "row" }]);
 
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public faqsList;
  public baseSearch;
  public totalListCount;
  public groupsList
  pageIndex = 0;
  pageSize=10;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllFaqss({ pageSize: 10, pageNumber: 1 });
    this.getAllGroups()
  }

  getAllGroups() {
    this.baseService.getAllItems(Controllers.Groups).subscribe(res => {
      this.groupsList = res as any[]
    }, error => {
      this.spinner.hide()
    })
  }

  searchValue: string;
  applyFilter( ): void {    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex =0;
    this.getAllFaqss({ pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue });
  }

 
  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.router.navigate(['/edit-faq/' + element.id+'/true'])  
  }
  getAllFaqss(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Faqs;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.faqsList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.faqsList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide()
    });
  }

  deleteItem(faqs): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Faqs',
          content: 'Are you sure you want to delete a faq?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Faqs;
        this.baseService.removeItem(Controller, faqs.id).subscribe(res => {
          this.notification.showNotification('Faqs ', 'Faqs Deleted Successfully', 'success');
          this.deleteItemFromList(faqs.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Faqs Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Faqs Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllFaqss({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue });
  }
 

  // cancel(): void {
  //   this.isEdit = false;
  //   this.getFormControlByName('answer').setValue('');
  //   this.getFormControlByName('answerAr').setValue('');
  //   this.getFormControlByName('question').setValue('');
  //   this.getFormControlByName('questionAr').setValue('');
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('id').setValue(0);
  //   this.getFormControlByName('groupId').setValue(1);
  // }

  pushItemToList(item): void {
    this.faqsList.push(item);
    this.dataSource = new MatTableDataSource(this.faqsList)
  }

  deleteItemFromList(id: number): void {
    this.faqsList = this.faqsList.filter(faqs => faqs.id !== id);
    this.dataSource = new MatTableDataSource(this.faqsList)
  }

 
}
