import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-coupon-add',
  templateUrl: './coupon-add.component.html',
  styleUrls: ['./coupon-add.component.scss']
})
export class CouponAddComponent implements OnInit {
  public isEdit = false;
  public categoriesDiscountList = [];
  public categories;
  public editId: number;
  public isUpdateMode: boolean;
  tomorrow = new Date();
  couponForm = new FormGroup({
    id: new FormControl(0),
    code: new FormControl('', Validators.required),
    discountPercentage: new FormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
    orderLimit: new FormControl(''),
    maxDiscount: new FormControl(''),
    expiryDate: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required),
    isForOneUser: new FormControl(),
    isFreeDelivery: new FormControl(),
  });
  @ViewChild('form') form: any;
  couponRules: FormGroup;


  getFormControlByName(controlName: string): FormControl {
    return this.couponForm.get(controlName) as FormControl;
  }

  constructor(public router: Router,
    private fb: FormBuilder,private datePipe: DatePipe,
    public route: ActivatedRoute, public spinner: NgxSpinnerService, private baseService: BaseService, public notification: NotificationService) {
    this.couponRules = this.fb.group({
      id: new FormControl(0),
      categoryId: new FormControl('', Validators.required),
      couponId: new FormControl(),
      discountPercentage: new FormControl('', [Validators.required, Validators.pattern('[0-9]+')]),
      status: new FormControl(1),
    });
  }

  ngOnInit(): void {
    this.getAllCategories();
    this.route.params.subscribe(params => {
      this.editId = params.editId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Coupon;
        this.baseService.getById(Controller, this.editId).subscribe(res => {
          this.couponForm.patchValue(res);
          this.expiryDate=res.expiryDate;
          this.categoriesDiscountList = res.couponRules;
        });

      }
    });

  }
  getCategoryName(id: number) {
    return this.categories?.find(x => x.id == id).categoryName;
  }
  getAllCategories(): void {
    this.spinner.show();
    const Controller = Controllers.Category;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.categories = (res as any[]);

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  check(controlName, checked: boolean): void {
    this.getFormControlByName(controlName).setValue(checked ? 1 : 2);
  }

  checkBit(controlName, checked: boolean): void {
    this.getFormControlByName(controlName).setValue(checked ? 1 : 0);
  }
  onAddCategoryDiscount() {
    if (!this.couponRules.invalid) {
      const rule = this.couponRules.value;
      rule.couponId = this.couponForm.controls.id.value;
      this.categoriesDiscountList.push(this.couponRules.value)
      this.form.resetForm();
      this.couponRules.reset()
      this.couponRules.markAsUntouched(); // Mark the form as untouched to clear validation errors.

      this.couponRules.controls.id.setValue(0);
      this.couponRules.controls.status.setValue(1);

    }  
  }
  onDeleteCategoryDiscount(categoryDiscount) {
    if (categoryDiscount.id > 0) {
      this.baseService.deleteCouponRule(categoryDiscount.id).subscribe(response => {
        this.categoriesDiscountList = this.categoriesDiscountList.filter(x => x != categoryDiscount)
      })
    } else {
      this.categoriesDiscountList = this.categoriesDiscountList.filter(x => x != categoryDiscount)
    }
  }
  expiryDate;
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
  this.expiryDate=this.datePipe.transform(event.value, 'yyyy-MM-dd');   
  console.log('this.expiryDate: ', this.expiryDate);
  }
  submitForm(): void {
    if (this.couponForm.invalid) {
      this.couponForm.markAllAsTouched();
      
    }
    else {
      this.spinner.show();
      const couponForm = this.couponForm.value;
      couponForm.couponRules = this.categoriesDiscountList;
      couponForm.expiryDate=this.expiryDate;
      console.log('couponForm: ', couponForm);
      this.baseService.postItem(Controllers.Coupon, Actions.PostItem, couponForm).subscribe(response => {
        this.spinner.hide();

        this.notification.showNotification('Add Coupon', 'Coupon Modified Successfully', 'success');
        this.router.navigate(['/coupon-managment'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Coupon Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Coupon Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.couponForm.invalid) {
      this.couponForm.markAllAsTouched();
      // this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const couponForm = this.couponForm.value;
      couponForm.couponRules = this.categoriesDiscountList;
      couponForm.expiryDate=this.expiryDate;
      console.log('couponForm: ', couponForm);
      this.baseService.postItem(Controllers.Coupon, Actions.EditItem, couponForm).subscribe(response => {
        this.spinner.hide();

        this.notification.showNotification('Edit Coupon', 'Coupon Modified Successfully', 'success');
        this.router.navigate(['/coupon-managment'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Coupon Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Coupon Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

}
