import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-provider-managment',
  templateUrl: './provider-managment.component.html',
  styleUrls: ['./provider-managment.component.scss']
})
export class ProviderManagmentComponent implements OnInit {

  displayedColumns: string[] = ['providerName', 'isActive', 'actions'];
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public providerList;
  public baseSearch;
  public totalListCount;
  pageIndex =0;
  pageSize=10;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
     
    this.getAllProviders({ pageSize: this.pageSize, pageNumber: 1 });
  }

  searchValue: string;
  applyFilter( ): void {    
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getAllProviders({ pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue });
  }


  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.router.navigate(['/edit-provider-managment/' + element.id + '/true'])
  }
  getAllProviders(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Provider;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.providerList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.providerList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  deleteItem(provider): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Provider',
          content: 'Are you sure you want to delete a provider?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Provider;
        this.baseService.removeItem(Controller, provider.id).subscribe(res => {
          this.notification.showNotification('Provider ', 'Provider Deleted Successfully', 'success');
          this.deleteItemFromList(provider.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Provider Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Provider Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllProviders({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue });
  }


  // cancel(): void{
  //   this.isEdit = false;
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('name').setValue('');
  //   this.getFormControlByName('id').setValue(0);
  // }

  pushItemToList(item): void {
    this.providerList.push(item);
    this.dataSource = new MatTableDataSource(this.providerList)
  }

  deleteItemFromList(id: number): void {
    this.providerList = this.providerList.filter(provider => provider.id !== id);
    this.dataSource = new MatTableDataSource(this.providerList)
  }



}
