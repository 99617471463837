<mat-card>
  <mat-card-header>
    <mat-card-title>
      Modify Products Prices
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <form #form="ngForm" novalidate [formGroup]="modifyProductsForm" (submit)="submitForm()">    
 
      <div class="row">

        <mat-form-field class="col-md-4" appearance="outline">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category">
            <mat-option *ngFor="let category of categoryList" [value]="category.id"
                        (onSelectionChange)="categoryChanged(category.id)">
              {{category.categoryName}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field class="col-md-4" appearance="outline">
          <mat-label>Sub Category</mat-label>
          <mat-select formControlName="subCategory">
            <mat-option *ngFor="let subCategory of subCategoryList" [value]="subCategory.id">
              {{subCategory.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="row">
        <mat-form-field class="col-md-4" appearance="outline">
          <mat-label>Modification Type</mat-label>
          <mat-select formControlName="modificationType">
            <mat-option value=1>Addition</mat-option>
            <mat-option value=2>Deduction</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="col-md-4" appearance="outline">
          <mat-label>Criteria</mat-label>
          <mat-select formControlName="modificationCriteria" (selectionChange)="criteriaChanged()">
            <mat-option value=1>Amount</mat-option>
            <mat-option value=2>Percentage</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="modifyProductsForm.controls.modificationCriteria.value == 1" class="col-md-4" appearance="outline">
          <mat-label>Amount</mat-label>
          <input type="number" matInput formControlName="amount" placeholder="Amount in SAR">
        </mat-form-field>

        <mat-form-field *ngIf="modifyProductsForm.controls.modificationCriteria.value == 2" class="col-md-4" appearance="outline">
          <mat-label>Percentage</mat-label>
          <input type="textbox" matInput formControlName="percentage" placeholder="from 0 to 100" appPercentageDirective
                 min="0" max="100" pattern="^(?=.*\d)\d{0,2}(?:\.\d{0,2})?$">
        </mat-form-field>

      </div>

      <div class="row">
        <div class="col-8 text-right" style="margin-top: 20px;">
          <button  mat-raised-button color="accent">
            Submit
          </button>
        </div>
      </div>

    </form>
  </mat-card-content>
</mat-card>
<br>
