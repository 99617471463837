<mat-card>
    <mat-card-header>
        <mat-card-title>
            Change Password For User
        </mat-card-title>
    </mat-card-header>
    <br/>
    <mat-card-content>
        <div class="row">
            <div class="col text-center">
                <form [formGroup]="changePasswordForm" class="row">
                    <div class="col-6 text-right">
                        <mat-form-field appearance="outline">
                            <mat-label> mobile Number</mat-label>
                            <input type="text" matInput formControlName="mobileNumber" />
                        </mat-form-field>
                    </div>
                    <div class="col-6 text-left">
                        <mat-form-field appearance="outline">
                            <mat-label> New Password</mat-label>
                            <input type="password" matInput formControlName="newPassword" />
                        </mat-form-field>
                    </div>
                    <div class="col-12">
                        <button class="remove-btn" mat-raised-button color="accent" (click)="onSubmit()" mat-button
                        color="warn">
                        Change Password
                    </button>
                    </div>
                </form>
            </div>
        </div>
    </mat-card-content>

</mat-card>