import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BaseService } from 'src/shared/services/base.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { AppSettingsService } from '../../app/services/appSettings.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent {
  reactiveForm: any;
  isActive: boolean = false;
  timerValue: any
  constructor(private formBuilder: FormBuilder, private http: HttpClient, private appSettingsService: AppSettingsService, private baseService: BaseService, private router: Router) { }
  ngOnInit() {
    this.getDefaultTimer();
    this.reactiveForm = this.formBuilder.group({
      timer: ['', []]
    });
  }
  getDefaultTimer() {
    this.appSettingsService.getTimer().subscribe(res => {
      this.timerValue = (res.settingValue);
      this.isActive = (res.status) == 1 ? true : false;
    }, error => {
      console.log(error);


    });
  }

  SubmitForm() {
    if (this.reactiveForm.valid) {
      var body = { id: 1, SettingValue: this.getFormControlByName('timer').value, Status: this.isActive ? 1 : 2 };
      this.baseService.postItem(Controllers.AppSettings, Actions.EditItem, body).subscribe(response => {
        console.log(response);
        window.location.reload();
      }, error => {
        console.log(error);
        if (error.status === 400) {

          this.router.navigate(['/timer']);
        }


      });
    }
  }

  ChangeStatus() {
    this.isActive = !this.isActive;
  }
  getFormControlByName(controlName: string): FormControl {
    return this.reactiveForm.get(controlName) as FormControl;
  }


}
