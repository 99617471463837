<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{!isEdit ? 'Add Banner': 'Edit Banner'}}
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="bannerForm">
          <div class="row banner-form">
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>English Title</mat-label>
                <input type="text" matInput formControlName="titleEn">
                <mat-error *ngIf="bannerForm.controls.titleEn.invalid && bannerForm.controls.titleEn.touched"
                  style="font-size: 14px !important;">English Title is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Arabic Title</mat-label>
                <input type="text" matInput formControlName="titleAr">
                <mat-error *ngIf="bannerForm.controls.titleAr.invalid && bannerForm.controls.titleAr.touched"
                  style="font-size: 14px !important;">Arabic Title is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>English Description</mat-label>
                <input type="text" matInput formControlName="descriptionEn">
                <mat-error
                  *ngIf="bannerForm.controls.descriptionEn.invalid && bannerForm.controls.descriptionEn.touched"
                  style="font-size: 14px !important;">English Description is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Arabic Description</mat-label>
                <input type="text" matInput formControlName="descriptionAr">
                <mat-error *ngIf="bannerForm.controls.descriptionAr.invalid&& bannerForm.controls.descriptionAr.touched"
                  style="font-size: 14px !important;">Arabic Description is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Button Title Ar</mat-label>
                <input type="text" matInput formControlName="buttonTitleAr">
                <mat-error *ngIf="bannerForm.controls.buttonTitleAr.invalid&& bannerForm.controls.buttonTitleEn.touched"
                  style="font-size: 14px !important;">Button Title Ar
                  is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Button Title En</mat-label>
                <input type="text" matInput formControlName="buttonTitleEn">
                <mat-error
                  *ngIf="bannerForm.controls.buttonTitleEn.invalid && bannerForm.controls.buttonTitleEn.touched"
                  style="font-size: 14px !important;">Button Title En
                  is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">

                <mat-label>Button Internal Link</mat-label>
                <input type="text" matInput formControlName="buttonInternalLink" />
                <mat-error *ngIf="m.buttonInternalLink.touched && m.buttonInternalLink.invalid"
                  style="font-size: 14px !important;">
                  <div *ngIf="m.buttonInternalLink.errors?.required">Button Internal Link is Required *</div>
                  <div *ngIf="m.buttonInternalLink.errors?.pattern">Button Internal Link
                    invalid *</div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Type</mat-label>
                <mat-select formControlName="bannerType">
                  <mat-option *ngFor="let type of bannerTypes" [value]="type.id">
                    {{type.nameEn}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="bannerForm.controls.bannerType.invalid && bannerForm.controls.bannerType.touched"
                  style="font-size: 14px !important;">Banner Type is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-sm-12">
              <mat-form-field appearance="outline">
                <mat-label>Order</mat-label>
                <input type="text" matInput formControlName="order">
                <mat-error *ngIf="bannerForm.controls.order.invalid" style="font-size: 14px !important;">Order is
                  Required *</mat-error>
              </mat-form-field>
            </div>



            <div class="col-lg-1 col-sm-12" style="margin-top: 20px;">
              <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">
                Is Active</mat-checkbox>
            </div>


          </div>
          <div class="row">
            <div class="col-lg-3 col-sm-12">
              <div>Desktop Image En</div>
              <input #imageDesktopEnInput type="file" [hidden]="true"
                (change)="handleFileInput('imageDesktopEn',$event.target.files[0])" accept=".jpg,.jpeg, .tiff, .png " />
              <a *ngIf="!imageDesktopEn" mat-raised-button color="accent" style="margin-right:10px;margin-bottom:10px;"
                (click)="imageDesktopEnInput.click()" matTooltip="Upload Category" matTooltipPosition="above">Upload
                Desktop
                Image</a>
              <!-- <button *ngIf="imageDesktopEn" mat-raised-button color="accent" class="col-10"
                  (click)="clearFileInput('imageDesktopEn')">{{imageDesktopEn?.name}}
                  <mat-icon>delete</mat-icon>
                </button> -->
              <mat-error *ngIf="!imageDesktopEn && bannerForm.touched" style="font-size: 14px !important;">Banner Image
                is Required *</mat-error>
              <!-- <div *ngIf="bannerForm.controls.imageDesktopEn.value">
                  <a *ngIf="!imageDesktopEn" target="_blank" [href]="bannerForm.controls.imageDesktopEn.value">
                   
                    <img src="{{bannerForm.controls.imageDesktopEn.value}}" style="height: 150px;" >
                  </a>
                  <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopEn')">{{imageDesktopEn?.name}}
                    <mat-icon>delete</mat-icon>
                  </a>
                </div> -->

              <div class="avatar-upload" *ngIf="bannerForm.controls.imageDesktopEn.value!=''">
                <div class="avatar-preview">
                  <div id="imagePreview" style="background-image: url({{bannerForm.controls.imageDesktopEn.value}});">
                    <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopEn')"
                      class="avatar-edit d-flex align-items-center">
                      <mat-icon class="mx-auto">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-lg-3 col-sm-12">
              <div>Desktop Image Ar</div>
              <input #imageDesktopArInput type="file" [hidden]="true"
                (change)="handleFileInput('imageDesktopAr',$event.target.files[0])" accept=".jpg,.jpeg, .tiff, .png " />
              <button *ngIf="!imageDesktopAr" mat-raised-button color="accent"
                style="margin-right:10px;margin-bottom:10px;" (click)="imageDesktopArInput.click()"
                matTooltip="Upload Category" matTooltipPosition="above">Upload Desktop Image
                Ar</button>
              <!-- <button *ngIf="imageDesktopAr" mat-raised-button color="accent" class="col-10"
                  (click)="clearFileInput('imageDesktopAr')">{{imageDesktopAr?.name}}
                  <mat-icon>delete</mat-icon>
                </button> -->
              <mat-error *ngIf="!imageDesktopAr && bannerForm.touched" style="font-size: 14px !important;">Banner Image
                is Required *</mat-error>
              <!-- <div *ngIf="bannerForm.controls.imageDesktopAr.value">
                  <a *ngIf="!imageDesktopAr" target="_blank" [href]="bannerForm.controls.imageDesktopAr.value">
                     
                    <img src="{{bannerForm.controls.imageDesktopAr.value}}" style="height: 150px;" >
                  </a>
                  <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopAr')">{{imageDesktopAr?.name}}
                    <mat-icon>delete</mat-icon>
                  </a>
                </div> -->
              <div class="avatar-upload" *ngIf="bannerForm.controls.imageDesktopAr.value!=''">
                <div class="avatar-preview">
                  <div id="imagePreview" style="background-image: url({{bannerForm.controls.imageDesktopAr.value}});">
                    <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopAr')"
                      class="avatar-edit d-flex align-items-center">
                      <mat-icon class="mx-auto">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div>Mobile Image En</div>
              <input #imageMobileEnInput type="file" [hidden]="true"
                (change)="handleFileInput('imageMobileEn',$event.target.files[0])" accept=".jpg,.jpeg, .tiff, .png " />
              <button *ngIf="!imageMobileEn" mat-raised-button color="accent"
                style="margin-right:10px;margin-bottom:10px;" (click)="imageMobileEnInput.click()"
                matTooltip="Upload Category" matTooltipPosition="above">Upload Desktop
                Image</button>
              <!-- <button *ngIf="imageMobileEn" mat-raised-button color="accent" class="col-10"
                  (click)="clearFileInput('imageMobileEn')">{{imageMobileEn?.name}}
                  <mat-icon>delete</mat-icon>
                </button> -->
              <mat-error *ngIf="!imageMobileEn && bannerForm.touched" style="font-size: 14px !important;">Banner Image
                is Required *</mat-error>
              <!-- <div *ngIf="bannerForm.controls.imageMobileEn.value">
                  <a *ngIf="!imageMobileEn" target="_blank" [href]="bannerForm.controls.imageMobileEn.value">
                 
                    <img src="{{bannerForm.controls.imageMobileEn.value}}" style="height: 150px;" >
                  </a>
                  <a mat-icon-button color="accent" (click)="clearFileInput('imageMobileEn')">{{imageMobileEn?.name}}
                    <mat-icon>delete</mat-icon>
                  </a>
                </div> -->
              <div class="avatar-upload" *ngIf="bannerForm.controls.imageMobileEn.value!=''">
                <div class="avatar-preview">
                  <div id="imagePreview" style="background-image: url({{bannerForm.controls.imageMobileEn.value}});">
                    <a mat-icon-button color="accent" (click)="clearFileInput('imageMobileEn')"
                      class="avatar-edit d-flex align-items-center">
                      <mat-icon class="mx-auto">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <div>Mobile Image Ar</div>
              <input #imageMobileArInput type="file" [hidden]="true"
                (change)="handleFileInput('imageMobileAr',$event.target.files[0])" accept=".jpg,.jpeg, .tiff, .png " />
              <button *ngIf="!imageMobileAr" mat-raised-button color="accent"
                style="margin-right:10px;margin-bottom:10px;" (click)="imageMobileArInput.click()"
                matTooltip="Upload Category" matTooltipPosition="above">Upload Desktop Image
                En</button>
              <!-- <button *ngIf="imageMobileAr" mat-raised-button color="accent" class="col-10"
                  (click)="clearFileInput('imageMobileAr')">{{imageMobileAr?.name}}
                  <mat-icon>delete</mat-icon>
                </button> -->
              <mat-error *ngIf="!imageMobileAr && bannerForm.touched" style="font-size: 14px !important;">Banner Image
                is Required *</mat-error>
              <!-- <div *ngIf="bannerForm.controls.imageMobileAr.value">
                  <a *ngIf="!imageMobileAr" target="_blank" [href]="bannerForm.controls.imageMobileAr.value">
                    
                    <img src="{{bannerForm.controls.imageMobileAr.value}}" style="height: 150px;" >
                  </a>
                  <a mat-icon-button color="accent" (click)="clearFileInput('imageMobileAr')">{{imageMobileAr?.name}}
                    <mat-icon>delete</mat-icon>
                  </a>
                </div> -->
              <div class="avatar-upload" *ngIf="bannerForm.controls.imageMobileAr.value!=''">
                <div class="avatar-preview">
                  <div id="imagePreview" style="background-image: url({{bannerForm.controls.imageMobileAr.value}});">
                    <a mat-icon-button color="accent" (click)="clearFileInput('imageMobileAr')"
                      class="avatar-edit d-flex align-items-center">
                      <mat-icon class="mx-auto">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col text-right" style="margin-top: 20px;">
              <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                style="margin: 1px;">Add</button>
              <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                style="margin: 1px;">Update</button>
              <button mat-raised-button color="accent" [routerLink]="['/banner']" style="margin: 1px;"
                class="ml-2">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>