import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { CategoryCustomService } from 'src/shared/services/category-custom.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.scss']
})
export class CategoryAddComponent implements OnInit {
  public selectedFile;
  public isEdit = false;
  public editId: number;
  public isUpdateMode: boolean;

  @ViewChild('fileInput') myInputVariable: ElementRef;

  categoryForm = new FormGroup({
    id: new FormControl(0),
    categoryName: new FormControl('', Validators.required),
    categoryNameAr: new FormControl('', Validators.required),
    imagePath: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required),
    isDisplayedInHomePage: new FormControl(false, Validators.required),
    order: new FormControl('', Validators.required)
  });

  getFormControlByName(controlName: string): FormControl {
    return this.categoryForm.get(controlName) as FormControl;
  }

  constructor(
    private categoryCustomService: CategoryCustomService,
    public spinner: NgxSpinnerService,
    private baseService: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    public notification: NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.editId = params.editId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Category;
        this.baseService.getById(Controller, this.editId).subscribe(res => {
          console.log('res: ', res);
          this.categoryForm.patchValue(res);
        });

      }
    });
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  checkDisplayInHomePage(checked: boolean): void {
    this.getFormControlByName('isDisplayedInHomePage').setValue(checked ? true : false);
  }
  handleFileInput(file): void {
    this.selectedFile = file;
    this.getFormControlByName('imagePath').setValue(file.name);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.getFormControlByName('imagePath').setValue(event.target.result);
    }
    reader.readAsDataURL(file);

  }

  clearFileInput(): void {
    this.myInputVariable.nativeElement.value = '';
    this.selectedFile = null;
    this.getFormControlByName('imagePath').setValue('');
  }

  submitForm(): void {
    if (this.categoryForm.invalid || !this.selectedFile) {
      this.categoryForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const categoryForm = this.categoryForm.value;
      this.categoryCustomService.postCategoryWithFile(categoryForm, this.selectedFile).subscribe(response => {

        this.notification.showNotification('Add Category', 'Category Added Successfully', 'success');
        this.router.navigate(['/category'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Category Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Category Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.categoryForm.invalid) {
      this.categoryForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const categoryForm = this.categoryForm.value;
      this.categoryCustomService.updateCategoryWithFile(categoryForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();

        this.notification.showNotification('Edit Category', 'Category Modified Successfully', 'success');
        this.router.navigate(['/category'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Category Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Category Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
