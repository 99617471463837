import { AccountTypes, accountTypes } from './../../shared/global-variables/lookups';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { Controllers } from 'src/shared/global-variables/api-config';

@Component({
  selector: 'app-edit-users',
  templateUrl: './edit-users.component.html',
  styleUrls: ['./edit-users.component.scss']
})
export class EditUsersComponent implements OnInit {

  public AccountType: any[] = []
  public Addresses: any[] = []
  public isContractor: boolean = false;
  public selectedFile;

  @ViewChild('fileInput') myInputVariable: ElementRef ;

  public userId;

  userForm = new FormGroup({
    id : new FormControl(0, Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    mobileNumber: new FormControl('', [Validators.required, Validators.pattern("^[0-9\-\+]{9,15}$")]),
    fullName: new FormControl('', Validators.required),
    accountType: new FormControl('', Validators.required),
    crNumber: new FormControl(''),
    certifiedRegistration: new FormControl(''),

    
  })

  addressesForm = new FormGroup({
    id : new FormControl(0, Validators.required),
    name: new FormControl('', Validators.required),
    mobileNumber: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    street: new FormControl('', Validators.required),
    postalCode: new FormControl(''),
    isDefault: new FormControl(false)
  })


  constructor(private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private yesNoDialog: MatDialog,
    private route: Router,
    public notification: NotificationService,
    public activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.subscribe(params => {
        this.userId = params.userId;
      });
    }

  ngOnInit(): void {
    this.getUserById()
    this.AccountType = accountTypes

  }


  getUserById() {
    this.spinner.show();
    const Controller = Controllers.User;
    this.baseService.getById(Controller, this.userId ).subscribe(res => {
      this.userForm.patchValue(res);
      this.userForm.controls.id.setValue(parseInt(this.userId));
      this.Addresses = res.addresses;
      this.onAccountTypeChange();
      this.spinner.hide();
    },error => {
      console.log(error);
      this.spinner.hide();
    });
  }


  EditUser() {
    if (this.userForm.invalid || this.Addresses.length < 1) {
      this.userForm.markAllAsTouched()
      // this.addressesForm.markAllAsTouched()
    } else {

      // if (!this.Addresses.some(x => x.isDefault == true)) {
      //   this.notification.showNotification('User Failed', 'One of the addresses must be set as deafult address', 'error');
      //   return;
      // }

      if (this.Addresses.filter(x => x.isDefault == true).length > 1) {
        this.notification.showNotification('User Failed', 'Only one address must be set as deafult address', 'error');
        return;
      }

      const form = this.userForm.value
      form.addresses = this.Addresses
      this.spinner.show()

      this.baseService.EditUser(form, this.selectedFile).subscribe(res => {
        this.spinner.hide()
        this.notification.showNotification('User', 'User Has Been Registered Successfully', 'success')
        this.route.navigate(['/setup/users'])
      }, error => {
        this.spinner.hide()
        if (error.status === 400) {
          this.notification.showNotification('User Failed', 'Mobile number or email already exist', 'error');
        }
        else {
          this.notification.showNotification('User Failed', 'Something went wrong please contact system admin', 'error');
        }
      }
      )
    }

  }

  //#region events
  onAccountTypeChange() {
    if (this.formControlValue('accountType') == AccountTypes.Contractor) {
      this.isContractor = true
      this.formControlValidation('crNumber')
      this.formControlValidation('certifiedRegistration')
    } else {
      this.isContractor = false
      this.clearFormControlValidator('crNumber')
      this.clearFormControlValidator('certifiedRegistration')
      this.userForm.controls.crNumber.setValue("");
      this.clearFileInput()
    }
  }

  onAddAddresses() {
    if (this.addressesForm.invalid) {
      this.addressesForm.markAllAsTouched()
    } else {
      this.Addresses.push(this.addressesForm.value)
      this.addressesForm.reset()
      this.addressesForm.get('isDefault').setValue(false)
    }

  }
  onDeleteAddresses(address) {
    this.Addresses = this.Addresses.filter(x => x != address)
  }
  //#endregion

  //#region form functions
  formControlValue(formControlName) {
    return this.userForm.get(formControlName).value
  }
  formControlValidation(formControlName) {
    this.userForm.get(formControlName).setValidators(Validators.required)
    this.userForm.updateValueAndValidity()
  }
  clearFormControlValidator(formControlName) {
    this.userForm.get(formControlName).clearValidators()
    this.userForm.updateValueAndValidity()
  }
  //#endregion

  handleFileInput(file): void {
    this.selectedFile = file;
    this.userForm.controls.certifiedRegistration.setValue(file.name)
  }

  clearFileInput(): void {
    this.userForm.controls.certifiedRegistration.setValue('')
    this.myInputVariable = null
    this.selectedFile = null;
  }

}
