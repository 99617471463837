<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{!isEdit ? 'Add Sale': 'Edit Sale'}}
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="saleForm">
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <input type="text" placeholder="Item" aria-label="Number" matInput [formControl]="ItemControl"  [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWith" (optionSelected)="onItemSelected()">
                  <mat-option *ngFor="let option of filteredItems | async" [value]="option">
                    {{ option.titleEn }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <input type="text" placeholder="Sub Category" aria-label="Number" matInput [formControl]="SubCategoryControl" [matAutocomplete]="auto1">
                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayWithCategory" (optionSelected)="onSubCategoriesSelected()">
                  <mat-option *ngFor="let option of filteredSubCategories | async" [value]="option">
                    {{ option.name }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <input type="text" placeholder="Clients" aria-label="Number" matInput [formControl]="ClientsControl" [matAutocomplete]="auto2">
                <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayWithClient" (optionSelected)="onClientSelected()">
                  <mat-option *ngFor="let option of filteredClients | async" [value]="option">
                    {{ option.fullName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            </div>
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <input type="number" placeholder="Percentage"  matInput formControlName="percentage">
                </mat-form-field>
              </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>End Date</mat-label>
                <input formControlName="endDate" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col" style="margin-top: 20px;">
              <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">
                Is Active</mat-checkbox>
            </div>
            
          </div>
          <div class="row">
            <div class="col text-right" style="margin-top: 20px;">
              <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                style="margin: 1px;">Add</button>
              <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                style="margin: 1px;">Edit</button>
              <button mat-raised-button color="accent" (click)="cancel()" style="margin: 1px;">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-content>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Zara" #input>
    </mat-form-field>

    <div class="row">
      <mat-card *ngFor="let item of saleList" class="col-xs-12 col-xs-12 col-md-4" style="margin-top: 5px;">
        <div class="row">
          <div class="col-8">
            <b> Percentage:</b> {{item.percentage}}
          </div>
          <div class="col-8">
            <b> Item:</b> {{getItemName(item.itemId)}}
          </div>
          <div class="col-8">
            <b> Client:</b> {{getClientName(item.clientId) }}
          </div>
          
          <div class="col-8">
            <b> Sub Category:</b> {{ getSubCategorytName(item.subCategory) }}
          </div>
          <div class="col-4 text-right">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="accent" selected *ngIf="item?.status == 1">Active</mat-chip>
              <mat-chip color="accent" *ngIf="item?.status != 1">InActive</mat-chip>
            </mat-chip-list>

          </div>
        </div>
        <hr>
        <mat-card-actions class="text-right">
          <button mat-raised-button color="accent" *ngIf="item?.status == 2" (click)="toggleStatus(item.id)">Activate</button>
          <button mat-raised-button color="accent" *ngIf="item?.status == 1" (click)="toggleStatus(item.id)">DeActivate</button>

          <button mat-raised-button color="accent" (click)="editItem(item)">Edit</button>

          <button mat-raised-button color="warn" (click)="deleteItem(item)">Delete</button>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex" 
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>