<h2>Choose Gender</h2> 
<div id="root">
    <div class="container pt-5">
      <div class="row align-items-stretch">
        <div class="c-dashboardInfo col-lg-3 col-md-6" (click)="next(1)" style="cursor: pointer;">
          <div class="wrap">
            <img mat-card-image src="../../assets/Images/male.png" alt="Default Gender Image">
            <h3 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">   Male
               </h3>
          </div>
        </div>
        <div class="c-dashboardInfo col-lg-3 col-md-6" (click)="next(2)" style="cursor: pointer;">
            <div class="wrap">
                <img mat-card-image src="../../assets/Images/female.png" alt="Default Gender Image">
              <h3 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">   Famale
                 </h3>
            </div>
          </div>
          <div class="c-dashboardInfo col-lg-3 col-md-6" (click)="next(3)" style="cursor: pointer;">
            <div class="wrap">
                <img mat-card-image src="../../assets/Images/all.png" alt="Default Gender Image">
              <h3 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">   All
                 </h3>
            </div>
          </div>
      </div>
    </div>
  </div>