import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-change-password-admin',
  templateUrl: './change-password-admin.component.html',
  styleUrls: ['./change-password-admin.component.scss']
})
export class ChangePasswordAdminComponent implements OnInit {


  changePasswordForm = new FormGroup({
    mobileNumber: new FormControl('', Validators.required),
    newPassword: new FormControl('', Validators.required)
  })

  constructor(
    private baseService: BaseService,
    public notification: NotificationService,
    public spinner: NgxSpinnerService,
    private route : Router
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched()
    } else {
      this.spinner.show()
      this.baseService.ChangePasswordAdmin(this.changePasswordForm.value).subscribe(res => {
        this.spinner.hide()
        this.notification.showNotification('Change Password', 'Password Changed Successfully', 'success')
        this.route.navigate(['/setup/users'])
      }, error => {
        this.spinner.hide()
        this.notification.showNotification('Change Password', 'something went wrong please contact your adminsitrator', 'error')
      })
    }
  }

}
