import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';

@Component({
  selector: 'app-drivers-requests',
  templateUrl: './drivers-requests.component.html',
  styleUrls: ['./drivers-requests.component.scss']
})
export class DriversRequestsComponent implements OnInit {

  displayedColumns: string[] = ['FullName', 'MobileNumber', 'RegistrationDocument', 'CarSpecifications', 'DriverLicence', 'VehicleRegistration'];

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public driverRegistrationList;
  public baseSearch;
  public totalListCount;
  pageIndex = 0;
  pageSize=10;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getAllDriversRequest({ pageSize: 10, pageNumber: 1 });
  }
  searchValue: string;
  applyFilter( ): void {
     
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex =0;
    this.getAllDriversRequest({ pageSize: this.pageSize, pageNumber: 1, nameEn:  this.searchValue });
  }

  getAllDriversRequest(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.DriverRegistration;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.driverRegistrationList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.driverRegistrationList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllDriversRequest({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn:  this.searchValue  });
  }
}
