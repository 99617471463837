<mat-card>
  <mat-card-header>
    <mat-card-title>
      Item Details
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="productForm">
          <div class="row" hidden="true">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select [formControl]="category">
                  <mat-option *ngFor="let category of categoryList" [value]="category.id"
                    (onSelectionChange)="getSubCategoryByCategories(category.id)">
                    {{category.categoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Sub Category</mat-label>
                <mat-select formControlName="subCategoryId">
                  <mat-option *ngFor="let subCategory of subCategoryList" [value]="subCategory.id">
                    {{subCategory.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Title En</mat-label>
                <input type="text" matInput formControlName="titleEn">
                <mat-error *ngIf="productForm.controls.titleEn.invalid" 
                  style="font-size: 14px !important;">Title En is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Title Ar</mat-label>
                <input type="text" matInput formControlName="titleAr">
                <mat-error *ngIf="productForm.get('titleAr').invalid"  style="font-size: 14px !important;">
                  Title Ar is Required *
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Item Order</mat-label>
                <input type="number" min="1" matInput formControlName="itemOrder">
                <mat-error *ngIf="productForm.controls.itemOrder.invalid" 
                  style="font-size: 14px !important;">Item Order is Required *</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Brand</mat-label>
                <mat-select formControlName="brandId">
                  <mat-option *ngFor="let brand of brandsList" [value]="brand.id">
                    {{brand.brandNameEn}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.brandId.invalid" 
                  style="font-size: 14px !important;">Brand is Required *</mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Provider</mat-label>
                <mat-select formControlName="providerId">
                  <mat-option *ngFor="let provider of providersList" [value]="provider.id">
                    {{provider.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.providerId.invalid" 
                  style="font-size: 14px !important;">Provider is Required *</mat-error>

              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>SKU #</mat-label>
                <input type="text" matInput formControlName="sku">
                <mat-error *ngIf="productForm.controls.sku.invalid"  style="font-size: 14px !important;">SKU
                  is Required *</mat-error>

              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Description En</mat-label>
                <textarea matInput formControlName="descriptionEn"></textarea>
                <mat-error *ngIf="productForm.controls.descriptionEn.invalid" 
                  style="font-size: 14px !important;">Description En is Required *</mat-error>

              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Description Ar</mat-label>
                <textarea matInput formControlName="descriptionAr"></textarea>
                <mat-error *ngIf="productForm.controls.descriptionAr.invalid" 
                  style="font-size: 14px !important;">Description Ar is Required *</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Availability date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="availability">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="productForm.controls.availability.invalid"  style="font-size: 14px !important;">This field is Required *</mat-error>

              </mat-form-field>
            </div> -->

            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="1">Active </mat-option>
                  <mat-option [value]="2">In Active </mat-option>
                  <mat-option [value]="8">Empty </mat-option>
                </mat-select>
                <mat-error *ngIf="productForm.controls.status.invalid" 
                  style="font-size: 14px !important;">Status is Required *</mat-error>
              </mat-form-field>
            </div>

            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>Madar Id</mat-label>
                <input type="text" matInput formControlName="madarIntegrationId">
              </mat-form-field>
            </div>



            <!-- <div class="col align-self-center">
              <mat-checkbox [checked]="getProductFormControlByName('isFeaturedProduct').value == 1" (change)="check($event.checked)">Is Featured Product?</mat-checkbox>
            </div> -->
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Custom Description And Specification
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-label>
          English Custom Description And Specification
        </mat-label>
        <br />
        <ckeditor [editor]="customDescriptionEditorEn" (change)="onChangeDescriptionEn($event)"></ckeditor>
      </div>
      <div class="col">
        <mat-label>
          Arabic Custom Description And Specification
        </mat-label>
        <br />
        <ckeditor [editor]="customDescriptionEditorAr" (change)="onChangeDescriptionAr($event)"></ckeditor>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Item Price
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
   
      <div class="col-12" >
      <form #form="ngForm" novalidate [formGroup]="itemPriceForm" (submit)="onAddItemPrice()">
        <div class="row">
          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Pricing Method</mat-label>
              <mat-select formControlName="pricingMethod" (selectionChange)="changeSizeValidation()">
                <mat-option *ngFor="let method of pricingMethods" [value]="method.id">
                  {{method.nameEn}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="itemPriceForm.controls.pricingMethod.invalid" 
                style="font-size: 14px !important;">Pricing Method is Required *</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Item Price</mat-label>
              <input type="number" min="1" matInput formControlName="priceBeforDiscount">
              <mat-error
                *ngIf="itemPriceForm.controls.priceBeforDiscount.invalid "
                 style="font-size: 14px !important;">Item Price is Required *</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>UnitType</mat-label>
              <input type="text" matInput formControlName="unitType">
              <mat-error *ngIf="itemPriceForm.controls.unitType.invalid  "
                 style="font-size: 14px !important;">UnitType is Required *</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Discount</mat-label>
              <input type="text" matInput formControlName="discount" appPercentageDirective>
            </mat-form-field>
          </div>

          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Stock</mat-label>
              <input type="text" matInput formControlName="stock">
            </mat-form-field>
          </div>

          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Size</mat-label>
              <input type="number" matInput formControlName="size">
              <mat-error *ngIf="itemPriceForm.controls.size  " 
                style="font-size: 14px !important;">Size is Required*</mat-error>
            </mat-form-field>
          </div>
          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Integration Id</mat-label>
              <input type="text" matInput formControlName="integrationId">
            </mat-form-field>
          </div>
          <div class="col-md-3 text-center">
            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option [value]="1">Active </mat-option>
                <mat-option [value]="2">In Active </mat-option>
                <mat-option [value]="8">Empty </mat-option>
              </mat-select>
              <mat-error *ngIf="itemPriceForm.controls.status.invalid"
                 style="font-size: 14px !important;">Status is Required *</mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3 align-self-center">
            <button mat-raised-button color="accent">
              Save
            </button>
          </div>
        </div>
      </form>
     
      <!-- <code>
        <pre>{{itemPriceForm.get('pricingMethod').errors | json }}</pre>
        <pre>{{itemPriceForm.get('priceBeforDiscount').errors | json }}</pre>
      </code> -->
    </div> 
    <div class="row mt-2" >
      <div class="col-12 text-center">
        <table class="table" *ngIf="itemPrice.length > 0">
          <thead>
            <tr>
              <th scope="col">Unit Type</th>
              <th scope="col">Pricing Method</th>
              <th scope="col">Size</th>
              <th scope="col">Price</th>
              <th scope="col">Discount</th>
              <th scope="col">Stock</th>
              <th scope="col">Status</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let price of itemPrice">
              <td>{{price.unitType}}</td>
              <td>{{getPricingMethod(price.pricingMethod)}}</td>
              <td>{{price.size}}</td>
              <td>{{price.priceBeforDiscount}}</td>
              <td>{{price.discount}}</td>
              <td>{{price.stock}}</td>
              <td>{{GetStatusName(price.status)}}</td>
              <td>
                <button mat-raised-button color="accent" (click)="onDeleteItemPrice(price)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      Suggested Products
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <ng-select appearance="outline" placeholder="Suggested Products" [multiple]="true"
          [(ngModel)]="suggestedItemsList" [ngModelOptions]="{standalone: true}">
          <ng-option *ngFor="let item of itemsList" [value]="item.id">{{item.sku}} - {{item.titleEn}}</ng-option>
        </ng-select>
      </div>

    </div>
  </mat-card-content>
</mat-card>
<br>

<div class="row">
  <div class="col-12">
    <mat-card class="padding-35">
      <mat-card-header>
        <mat-card-title>
          Item Images / Video
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-card class="col" style="margin-top: 5px;">
            <div class="row">
              <div class="col-4">
                <mat-label>Choose item images</mat-label>
                <button mat-button color="warn" (click)="onImagesSelected()">
                  <mat-icon>file_upload</mat-icon>
                  Upload
                </button>
                <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                  style="display:none;" />
              </div>
            </div>
            <div class="row">
              <button class="remove-btn" mat-mini-fab color="accent" (click)="removeImageFromGallery()"
                *ngIf="galleryImages.length" mat-button color="warn">
                <mat-icon>close</mat-icon>
              </button>
              <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
            </div>
          </mat-card>
        </div>

        <div class="row">
          <mat-card class="col" style="margin-top: 5px;">
            <div class="row">
              <div class="col-4">
                <mat-label>Choose item Video</mat-label>
                <button mat-button color="warn" (click)="onVideoSelected()">
                  <mat-icon>file_upload</mat-icon>
                  Upload
                </button>
                <input type="file" id="videoUpload" name="videoUpload" accept="{{videoAccept}}" style="display:none;" />
              </div>
            </div>
            <div class="row" *ngIf="videoToShow">
              <button class="remove-btn" mat-mini-fab color="accent" (click)="removeVideo()" *ngIf="videoToShow"
                mat-button color="warn">
                <mat-icon>close</mat-icon>
              </button>
              <video width="270" height="220" controls disabled="true" (click)="toggleVideo()" autoplay>
                <source [src]="videoToShow">
              </video>
            </div>
          </mat-card>
        </div>
        <div class="row">
          <div class="col text-right" style="margin-top: 20px;">
            <button mat-raised-button color="accent" (click)="submitForm()" style="margin: 1px;">Add Item</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<br>

<!-- <ckeditor [editor]="customDescriptionEditorEn" (change)="onChangeDescriptionEn($event)"></ckeditor>
<ckeditor [editor]="customDescriptionEditorAr" (change)="onChangeDescriptionAr($event)"></ckeditor>
<button mat-raised-button color="accent" (click)="onFired()" style="margin: 1px;">Add Item</button> -->