<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Sub Category': 'Edit Sub Category'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="subCategoryForm">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="categoryId">
                    <mat-option *ngFor="let category of categoryList" [value]="category.id">
                      {{category.categoryName}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="subCategoryForm.controls.categoryId.invalid" 
                    style="font-size: 14px !important;">Category is Required *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Sub Category Name</mat-label>
                  <input type="text" matInput formControlName="name">
                  <mat-error *ngIf="subCategoryForm.controls.name.invalid" 
                    style="font-size: 14px !important;">Sub Category Name is Required *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>ِArabic Sub Category Name</mat-label>
                  <input type="text" matInput formControlName="nameAr">
                  <mat-error *ngIf="subCategoryForm.controls.nameAr.invalid" 
                    style="font-size: 14px !important;">Sub Category Name Ar is  Required *</mat-error>
                </mat-form-field>
              </div>
  
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Order</mat-label>
                  <input type="text" matInput formControlName="orderNumber">
                  <mat-error *ngIf="subCategoryForm.controls.orderNumber.invalid" 
                    style="font-size: 14px !important;">Should be numbers only *</mat-error>
                </mat-form-field>
              </div>
  
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Description</mat-label>
                  <textarea type="text" matInput formControlName="description"></textarea>
                  <mat-error *ngIf="subCategoryForm.controls.description.invalid" 
                    style="font-size: 14px !important;">Description is Required *</mat-error>
                </mat-form-field>
              </div>
  
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Basic Delivery Cost</mat-label>
                  <input type="text" matInput formControlName="deliveryCost">
                  <mat-error *ngIf="subCategoryForm.controls.deliveryCost.invalid" 
                    style="font-size: 14px !important;">Should be numbers only *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Max Amount of Ordered Count</mat-label>
                  <input type="text" matInput formControlName="maxAmount">
                  <mat-error *ngIf="subCategoryForm.controls.maxAmount.invalid" 
                    style="font-size: 14px !important;">Should be numbers only *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Percentage to Increase</mat-label>
                  <input type="text" matInput formControlName="maxAmountPercentage">
                  <mat-error *ngIf="subCategoryForm.controls.maxAmountPercentage.invalid" 
                    style="font-size: 14px !important;">Should be numbers only *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12" style="margin-top: 20px;">
                <input #fileInput type="file" [hidden]="true" (change)="handleFileInput($event.target.files[0])"
                  accept=".jpg,.jpeg, .tiff, .png " />
                <button *ngIf="!selectedFile" mat-raised-button color="accent"
                  style="margin-right:10px;margin-bottom:10px;" (click)="fileInput.click()" matTooltip="Upload Category"
                  matTooltipPosition="above">
                  Upload Image
                </button>
               
                <mat-error *ngIf="subCategoryForm.controls.imagePath.invalid && subCategoryForm.touched" class="mt-2"
                  style="font-size: 14px !important;">Sub Category Image is Required *</mat-error>
                <!-- <div *ngIf="subCategoryForm.controls.imagePath.value">
                  <a *ngIf="!selectedFile" target="_blank" [href]="subCategoryForm.controls.imagePath.value">
                    View Sub Category Image
                  </a>
                  <a mat-icon-button color="accent" (click)="clearFileInput()">{{selectedFile?.name}}
                    <mat-icon>delete</mat-icon>
                  </a>
                </div> -->
                <div class="avatar-upload" *ngIf="subCategoryForm.controls.imagePath.value!=''"  >
                  <div class="avatar-preview">
                    <div id="imagePreview" style="background-image: url({{subCategoryForm.controls.imagePath.value}});">
                      <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopEn')" class="avatar-edit d-flex align-items-center"> 
                        <mat-icon class="mx-auto">delete</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
  
              <div class="col-lg-4 col-sm-12" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">Is
                  Active</mat-checkbox>
              </div>
  
            </div>
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                  style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                  style="margin: 1px;">Update</button>
                <button mat-raised-button color="accent" class="m-2" [routerLink]="['/sub-category']" style="margin: 1px;">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
   