import { Route } from '@angular/compiler/src/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { MadarItemService } from '../../shared/services/madar-item.service';
import { NotificationService } from '../../shared/services/notification.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  displayedColumns: string[] = ['MadarId', 'Title', 'Price', 'Provider', 'Action'];

  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public itemsList;
  public baseSearch;
  public totalListCount;
  pageIndex = 0;
  pageSize=25;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private madarItemService: MadarItemService,
    public notification: NotificationService
  ) { }
  searchValue: string;
  ngOnInit(): void {
    this.getMadarItems({ pageSize: this.pageSize, pageNumber: 1 });
  }

  applyFilter(): void {
  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getMadarItems({ pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue });
  }

  getMadarItems(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Integration;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.itemsList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.itemsList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getMadarItems({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue });
  }


  goToProductPage(madarItem) {
    this.madarItemService.item = madarItem
    this.router.navigate(['/setup/choose-category'],)
  }

  syncItems() {
    this.spinner.show()
    this.baseService.syncMadarItems().subscribe(res => {
      this.spinner.hide()
      this.notification.showNotification('Item ', 'Madar Items Synced Successfully', 'success');
      this.getMadarItems({ pageSize: 25, pageNumber: 1 });
    }, error => {
      this.spinner.hide()
      this.notification.showNotification('Error While Syncing madar Items', '', 'error');
    }

    )
  }
}
