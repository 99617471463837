<mat-card>
  <mat-card-header class="d-flex justify-content-between align-items-center title-border">
    <mat-card-title class="mb-0">
        User Orders
      </mat-card-title>
  </mat-card-header>  
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-label>Order Id</mat-label>
                <input type="text" matInput formControlName="orderId">
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-label>Created Date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="fromDate" placeholder="Start date">
                  <input matEndDate formControlName="toDate" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-label>Total Amount</mat-label>
                <input type="number" matInput formControlName="totalAmount">
              </mat-form-field>
            </div>
            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-label>Full Name/ Mobile No.</mat-label>
                <input type="text" matInput formControlName="fullName">
              </mat-form-field>
            </div>

            <div class="col-3">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="1">Active </mat-option>
                  <mat-option [value]="2">InActive </mat-option>
                  <mat-option [value]="7">Delivered </mat-option>
                  <mat-option [value]="12">Delivering </mat-option>
                  <mat-option [value]="12">Cancelled </mat-option>
                  <mat-option [value]="5">InMyOrders </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row" style="float: right!important;">
            <div class="col">
            <button mat-raised-button color="accent" (click)="applyFilter()" style="margin: 2px;">Search</button>
            <button mat-raised-button color="accent" (click)="clearFilters()" style="margin: 2px;" class="ml-2">Clear
              Filters</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br />
<mat-card>
  <div class="table-responsive" *ngIf="dataSource" >
  <mat-table [dataSource]="dataSource">

    <!-- CategoryNameEn Column -->
    <ng-container matColumnDef="OrderId">
      <mat-header-cell *matHeaderCellDef> OrderId</mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="OrderId"> {{element.id}} </mat-cell>
    </ng-container>


    <ng-container matColumnDef="OrderDate">
      <mat-header-cell *matHeaderCellDef> Order Date </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Order Date">
        {{element.createdDate | date}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="TotalAmount">
      <mat-header-cell *matHeaderCellDef> Total Amount </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="Total Amount">
        {{element.totalAmount | number:'1.0-3'}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="UserName">
      <mat-header-cell *matHeaderCellDef> User Name </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="User Name">
        {{element.createdByNavigation.fullName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="UserAddress">
      <mat-header-cell *matHeaderCellDef> User Address </mat-header-cell>
      <mat-cell *matCellDef="let element" data-label="User Address">
        {{element.address}}
      </mat-cell>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="Status">
      <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{getStatusById(element.status)}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <button mat-raised-button color="accent" [routerLink]="'/setup/order-details/'+element.id"
          class="mx-2">Details</button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
</mat-card>

<mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex" 
  [pageSizeOptions]="[1, 10, 25, 100]">
</mat-paginator>



<!-- <div id="print-section" fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
    <mat-card fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)"
        *ngFor="let order of ordersList" class="col-xs-12 col-xs-12 col-md-4" style="margin-top: 5px;">
        <mat-card-content>
            <div class="row">
                <div class="col">
                    <b> Order Id:</b> {{order.id}}
                </div>
                <div class="col">
                    <b> Order Date:</b> {{order.createdDate | date}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b> Total Amount:</b> {{order.totalAmount}}
                </div>
                <div class="col">
                    <b> User Name:</b> {{order.createdByNavigation.fullName}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <b> User Address:</b> {{order.createdByNavigation.address}}
                </div>
                <div class="col">
                    <b> User Mobile Number:</b>
                    <a *ngIf="isPrintEnabled" href="" target="_">{{order.createdByNavigation.mobileNumber}}</a>
                    <a *ngIf="!isPrintEnabled" mat-raised-button color="accent" class="call-btn"
                        [href]="'tel:'+order.createdByNavigation.mobileNumber">{{order.createdByNavigation.mobileNumber}}</a>
                </div>

            </div>
            <div class="row">

                <div class="col">
                    <b>Status: </b>{{getStatusById(order.status)}}

                </div>
            </div>
        </mat-card-content>
        <hr>
        <mat-card-actions *ngIf="!isPrintEnabled" class="text-right">
            <a mat-raised-button color="accent" class="call-btn"
                [routerLink]="'/setup/order-details/'+order.id">Details</a>
        </mat-card-actions>
    </mat-card>
</div> -->