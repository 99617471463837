<mat-card>
    <mat-card-header>
        <mat-card-title>
            {{!isEdit ? 'Add Provider': 'Edit Provider'}}
        </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
        <div class="row">
            <div class="col">
                <form [formGroup]="providerForm">
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <mat-form-field appearance="outline">
                                <mat-label>Provider Name </mat-label>
                                <input type="text" matInput formControlName="name">
                                <mat-error *ngIf="providerForm.controls.name.invalid" 
                                    style="font-size: 14px !important;">Provider Name is Required*</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-sm-12" style="margin-top: 20px;">
                            <mat-checkbox [checked]="getFormControlByName('status').value == 1"
                                (change)="check($event.checked)">Is Active?</mat-checkbox>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col text-right" style="margin-top: 20px;">
                            <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                                style="margin: 1px;">Add</button>
                            <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                                style="margin: 1px;">Update</button>
                            <button mat-raised-button color="accent" class=" ml-2"  [routerLink]="['/provider-managment']"
                                style="margin: 1px;">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </mat-card-content>
</mat-card>