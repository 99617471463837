import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-provider-managment-add',
  templateUrl: './provider-managment-add.component.html',
  styleUrls: ['./provider-managment-add.component.scss']
})
export class ProviderManagmentAddComponent implements OnInit {
  public isEdit = false;
  public providerId: number;
  public isUpdateMode: boolean;
  providerForm = new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required)
  });
  getFormControlByName(controlName: string): FormControl {
    return this.providerForm.get(controlName) as FormControl;
  }
  constructor(private baseService: BaseService,
    public spinner: NgxSpinnerService, public notification: NotificationService,
    private authService: AuthorizeService, private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.providerId = params.providerId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Provider;
        this.baseService.getById(Controller, this.providerId).subscribe(res => {
          console.log('res: ', res);
          this.providerForm.patchValue(res);
        });

      }
    });
  }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }
  submitForm(): void {
    if (this.providerForm.invalid) {
      this.providerForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const providerForm = this.providerForm.value;
      this.baseService.postItem(Controllers.Provider, Actions.PostItem, providerForm).subscribe(response => {
        this.spinner.hide();
        this.router.navigate(['/provider-managment'])
        this.notification.showNotification('Add Provider', 'Provider Modified Successfully', 'success');

      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Provider Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Provider Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.providerForm.invalid) {
      this.providerForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const providerForm = this.providerForm.value;
      this.baseService.postItem(Controllers.Provider, Actions.EditItem, providerForm).subscribe(response => {
        this.spinner.hide();
        this.router.navigate(['/provider-managment'])
        this.notification.showNotification('Edit Provider', 'Provider Modified Successfully', 'success');

      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Provider Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Provider Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
