import { Component, OnInit,ElementRef,ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-review-add',
  templateUrl: './review-add.component.html',
  styleUrls: ['./review-add.component.scss']
})
export class ReviewAddComponent implements OnInit {
  public isEdit = false;
  public editId: number;
  public isUpdateMode: boolean;

  @ViewChild('fileInput') myInputVariable: ElementRef;
  @ViewChild('fileInput2') myInputVariable2: ElementRef;
  
  reviewsForm = new FormGroup({
    id: new FormControl(0),
    descriptionEn: new FormControl('', Validators.required),
    descriptionAr: new FormControl('', Validators.required),
    userName: new FormControl('', Validators.required),
    userDescriptionEn: new FormControl(''),
    userDescriptionAr: new FormControl(''),
    status: new FormControl(2, Validators.required),
    logoPath: new FormControl(''),
    userImagePath: new FormControl('', Validators.required),
  });
  getFormControlByName(controlName: string): FormControl {
    return this.reviewsForm.get(controlName) as FormControl;
  }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  public selectedLogoFile;
  public selectedReviewrImageFile;

  constructor(   private baseService: BaseService,
   public spinner: NgxSpinnerService,   
    private router: Router, 
    private route:ActivatedRoute,  
    public notification: NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.editId = params.editId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Reviews;
        this.baseService.getById(Controller, this.editId).subscribe(res => {
          console.log('res: ', res);
          this.reviewsForm.patchValue(res);
        });

      }
    });
  }

  handleReviewerFileInput(file): void {
    this.selectedReviewrImageFile = file;
    this.reviewsForm.controls.userImagePath.setValue(file.name)
  }
  submitForm(): void {
    if (this.reviewsForm.invalid){
      this.reviewsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const reviewsForm = this.reviewsForm.value;
      this.baseService.postItem(Controllers.Reviews, Actions.PostItem, reviewsForm).subscribe(response => {
        this.spinner.hide();
        this.notification.showNotification('Add Reviews', 'Reviews Modified Successfully', 'success');
        this.AddLogoImage((response as any).id)
        this.AddReviewerImage((response as any).id)
       
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Add Reviews Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Reviews Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.reviewsForm.invalid){
      this.reviewsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const reviewsForm = this.reviewsForm.value;
      this.baseService.postItem(Controllers.Reviews, Actions.EditItem, reviewsForm).subscribe(response => {
        this.spinner.hide();
        
        this.notification.showNotification('Edit Reviews', 'Reviews Modified Successfully', 'success');
        this.AddLogoImage((response as any).id)
        this.AddReviewerImage((response as any).id)
        this.router.navigate(['/review-managment'])
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Edit Reviews Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Reviews Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }


  AddReviewerImage(reviewId) {
    this.spinner.show()
    this.baseService.AddImage(Controllers.Reviews, Actions.AddUserImage, reviewId, this.selectedReviewrImageFile).subscribe(res => {
      this.spinner.hide()
    }, error => {
      //this.notification.showNotification('Reviewr Attachment', 'Something went wrong', 'error')
      this.spinner.hide()
    })
  }
  AddLogoImage(reviewId){
    console.log('logo image before response')

    this.spinner.show()
    this.baseService.AddImage(Controllers.Reviews, Actions.AddLogoImage, reviewId, this.selectedLogoFile).subscribe(res => {
      this.spinner.hide()
      this.router.navigate(['/review-managment'])
     

    }, error => {
     // this.notification.showNotification('Logo Attachment', 'Something went wrong', 'error')
      this.spinner.hide()
      console.log('error in logo image')
    })
  }


  handleLogoFileInput(file): void {
    this.selectedLogoFile = file;
    this.reviewsForm.controls.logoPath.setValue(file.name)
  }

  clearLogoFileInput(): void {
    this.myInputVariable.nativeElement.value = '';
    this.selectedLogoFile = null;
    this.reviewsForm.controls.logoPath.setValue('')
  }



  clearReviewerFileInput(): void {
    this.myInputVariable2.nativeElement.value = '';
    this.selectedReviewrImageFile = null;
    this.reviewsForm.controls.userImagePath.setValue('')
  }
}
