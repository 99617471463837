import { Actions } from '../../shared/global-variables/api-config';
import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { environment } from 'src/environments/environment';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BannerService } from 'src/shared/services/banner.service';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss']
})
export class LookupsComponent implements OnInit {
  @Input() title = '';
  displayedColumns: string[] = ['itemDescription', 'Image', 'Status', 'actions'];
  dataSource: MatTableDataSource<any>;
  emptyData = new MatTableDataSource([{ empty: "row" }]);

  constructor(
    private baseService: BaseService,  
    public spinner: NgxSpinnerService, 
    private yesNoDialog: MatDialog,
    private route: Router,
    public notification: NotificationService
  ) { }
  public itemList;
  public totalListCount;
  @Input() groupId = '';
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchValue: string; // Property to hold the input value
  pageIndex = 0;
  pageSize=10;
  ngOnInit(): void {
    this.getAllCategories({ pageSize: this.pageSize, pageNumber: 1, status: 3 });
  }

  applyFilter( ): void {
    this.pageIndex =0;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getAllCategories({ pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue, status: 3 });
  }


  getAllCategories(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Lookups;
    
    this.baseService.getLookupsListForAdmin(Controller, Actions.GetListForAdmin, baseSearch, this.groupId).subscribe(res => {
      this.itemList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.itemList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }


  editItem(element: any): void {   
    this.route.navigate(['/edit-business-partners/' + element.id+'/true']) 
  }
  deleteItem(item): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Item',
          content: 'Are you sure you want to delete a business partner?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Lookups;
        this.baseService.removeItem(Controller, item.id).subscribe(res => {
          this.notification.showNotification('Item ', 'Item Deleted Successfully', 'success');
          this.deleteItemFromList(item.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Item Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Item Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllCategories({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1 , nameEn: this.searchValue, status: 3});
  }
 
  pushItemToList(item): void {
    this.itemList.push(item);
    this.dataSource = new MatTableDataSource(this.itemList)
  }

  deleteItemFromList(id: number): void {
    this.itemList = this.itemList.filter(item => item.id !== id);
    this.dataSource = new MatTableDataSource(this.itemList)
  }


}
