<mat-card>
  <mat-card-header>
    <mat-card-title>
      Integrations
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>

    <div class="row">
      <div class="col">
        <button mat-raised-button color="accent"
                style="margin-right:10px;margin-bottom:10px;" (click)="syncItems()">
          <mat-icon>sync</mat-icon> Sync Madar Items
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 ml-auto">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" #input>
          <button type="button"  (click)="applyFilter()" class="search-btn btn"><mat-icon>search</mat-icon></button>
          
        </mat-form-field>
      </div>
    </div>
    <!-- <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" #input>
    </mat-form-field> -->

    <div class="table-responsive" *ngIf="dataSource" >

      <mat-table [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">

      <ng-container matColumnDef="MadarId">
        <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="MadarId">
          {{element.madarId}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Title">
        <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Title">
          {{element.title}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Price">
        <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Price">
          {{element.price}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Provider">
        <mat-header-cell *matHeaderCellDef>Provider</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Provider">
          Madar.com
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="Action">
        <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
        <mat-cell *matCellDef="let element" data-label="Action">
          <mat-chip-list aria-label="Fish selection">
            <mat-chip color="accent" selected *ngIf="!element.isConverted" (click)="goToProductPage(element)">Convert To Product</mat-chip>
            <mat-chip color="accent" *ngIf="element.isConverted">Converted</mat-chip>
          </mat-chip-list>
        </mat-cell>
      </ng-container>
      
      <ng-container matColumnDef="empty-row">
        <mat-cell   class="no-data-cell"   *matCellDef="let element">No Data Found</mat-cell>
      </ng-container> 
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
    </mat-table>
  </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex" 
                   [pageSizeOptions]="[25,50,100]"></mat-paginator>
  </mat-card-content>
</mat-card>
