import { element } from 'protractor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit {
  displayedColumns: string[] = ['brandNameEn','brandNameAr' ,'Status', 'actions'];
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public isEdit = false;
  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource <any>;
  pageIndex = 0;
  pageSize = 10;
  public brandList;
  public baseSearch;
  public totalListCount;


  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,

    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllBrands({ pageSize: 10, pageNumber: 1 });
  }
  searchValue: string;
  applyFilter(): void {  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getAllBrands({ pageSize: 10, pageNumber: 1, nameEn: this.searchValue });
  }

  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.router.navigate(['/edit-brand/' + element.id + '/true'])
  }
  getAllBrands(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Brand;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.brandList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      if (this.brandList.length == 0) {
        this.totalListCount = 0;
      }

      this.dataSource = new MatTableDataSource(this.brandList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
      this.spinner.hide();
    });
  }

  deleteItem(brand): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Brand',
          content: 'Are you sure you want to delete a brand?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Brand;
        this.baseService.removeItem(Controller, brand.id).subscribe(res => {
          this.notification.showNotification('Brand ', 'Brand Deleted Successfully', 'success');
          this.deleteItemFromList(brand.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Brand Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Brand Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.getAllBrands({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue });
  }

  // cancel(): void{
  //   this.isEdit = false;
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('brandNameEn').setValue('');
  //   this.getFormControlByName('brandNameAr').setValue('');
  //   this.getFormControlByName('id').setValue(0);
  // }

  pushItemToList(item): void {
    this.brandList.push(item);
    this.dataSource = new MatTableDataSource(this.brandList)
  }

  deleteItemFromList(id: number): void {
    this.brandList = this.brandList.filter(brand => brand.id !== id);
    this.dataSource = new MatTableDataSource(this.brandList)
  }


}
