import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner'; 
import { environment } from 'src/environments/environment';
import {  Controllers } from 'src/shared/global-variables/api-config';
import { BannerService } from 'src/shared/services/banner.service';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-business-partners-add',
  templateUrl: './business-partners-add.component.html',
  styleUrls: ['./business-partners-add.component.scss']
})
export class BusinessPartnersAddComponent implements OnInit {
  public isUpdateMode: boolean;
  public businessId: number;
  public isEdit = false;
  public selectedFile;
  public hostLink = environment.host;
  public imageSasToken = environment.sasToken;
  image_src = "";
  @Input() title = 'Business Partner';
  @Input() groupId = '1';
  @ViewChild('fileInput') myInputVariable: ElementRef;

  lookupsForm = new FormGroup({
    id: new FormControl(0),
    description: new FormControl('', Validators.required),
    imagePath: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required),
    parentId: new FormControl(1),
    typeId: new FormControl(),
  });
  Banner
  getFormControlByName(controlName: string): FormControl {
    return this.lookupsForm.get(controlName) as FormControl;
  }
  constructor(private baseService: BaseService,
    private itemCustomService: BannerService,
    public spinner: NgxSpinnerService,    
    private router: Router,     
    private route: ActivatedRoute,
    public notification: NotificationService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.businessId = params.businessId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Lookups;
        this.baseService.getById(Controller, this.businessId).subscribe(res => {
          console.log('res: ', res);
          this.lookupsForm.patchValue(res);
          this.image_src = this.lookupsForm.controls.imagePath.value;
        });

      }
    });
  }
  ngAfterViewInit(): void {
    this.getFormControlByName('typeId').setValue(this.groupId);
  }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  readURL(file) {
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.image_src = event.target.result;
    }
    reader.readAsDataURL(file);
  }


  handleFileInput(file): void {
    this.readURL(file);
    this.selectedFile = file;
    this.getFormControlByName('imagePath').setValue(file.name);
  }

  clearFileInput(): void {
    this.myInputVariable.nativeElement.value = '';
    this.selectedFile = null;
    this.image_src ="";
    this.getFormControlByName('imagePath').setValue('');
  }
  // cancel(): void {
  //   this.isEdit = false;
  //   this.lookupsForm.reset();
  //   this.lookupsForm.markAsUntouched()
  //   this.getFormControlByName('parentId').setValue(1);
  //   this.getFormControlByName('typeId').setValue(this.groupId);
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('id').setValue(0);
  //   this.clearFileInput()
  // }

  submitForm(): void {
    if (this.lookupsForm.invalid || !this.selectedFile) {
      this.lookupsForm.markAllAsTouched();
      // this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const lookupsForm = this.lookupsForm.value;
      this.itemCustomService.postItemWithFile(lookupsForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();
        //this.pushItemToList(response);
        this.notification.showNotification('Add Item', 'Item Added Successfully', 'success');
        //this.cancel();
        this.router.navigate(['/business-partners'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Item Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Item Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  editForm(): void {
    if (this.lookupsForm.invalid) {
      this.lookupsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const lookupsForm = this.lookupsForm.value;
      this.itemCustomService.updateItemWithFile(lookupsForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();
        // this.deleteItemFromList((response as any).id);
        // this.pushItemToList(response);
        this.notification.showNotification('Edit Item', 'Item Modified Successfully', 'success');
        // this.cancel();
        this.router.navigate(['/business-partners'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Item Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Item Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}

