 
  <mat-card>
    <mat-card-header class="d-flex justify-content-between align-items-center title-border">
      <mat-card-title class="mb-0">
        Order Details
        </mat-card-title>
    </mat-card-header>  
  <mat-card-content>
    <div class="row">
      <div class="col-12">
        <h3>Main Info</h3>
      </div>

      <div class="col-4 mt-2">
        <b> Created Date:</b> {{order?.createdDate | date}}
      </div>
      <div class="col-4 mt-2">
        <b> Prefered Shipping Date:</b> {{order?.preferedShippingDate| date}}
      </div>
      <div class="col-4 mt-2">
        <b> Preferred Time: </b> {{order?.preferredTime}}
      </div>
      <div class="col-4 mt-3">
        <b>Address : </b> {{order?.address}}
      </div>

      <div *ngIf="order?.addresses?.latitude && order?.addresses?.longitude" class="col-4 mt-3">
        <b>Pinned Location : </b> <button mat-button color="primary" (click)="openDialog()">View Location</button>
      </div>

      <!-- <div class="col-4 mt-3">
    <b>Billing Address : </b> {{order?.billingAddress}}
  </div> -->
      <div class="col-4 mt-3">
        <b> Payment Method:</b> {{getPaymentMethodName(order?.paymentMethod)}}
      </div>
      <div class="col-4 mt-3" *ngIf="order?.paymentMethod == 1">
        <b> Payment Proof:</b><a target="_blank" [href]="order?.paymentProof">
          View Payment Proof
        </a>
      </div>
      <div class="col-4 mt-3" *ngIf="order?.paymentMethod == 3 || order?.paymentMethod == 4 ">
        <b> Payment Confirmed:</b> {{yesNo(order?.isPaymentConfirmed)}}
      </div>
    </div>

    <br />
    <hr />

    <div class="row">
      <div class="col-12">
        <h3>User Info</h3>
      </div>
      <br>
      <div class="col-3 mt-3">
        <b> User Name:</b> {{order?.createdByNavigation?.fullName}}
      </div>

      <div class="col-3 mt-3">
        <b> Mobile Number:</b> {{order?.createdByNavigation?.mobileNumber}}
      </div>
      <div class="col-3 mt-3">
        <b> Vat Number:</b> {{order?.createdByNavigation.vatNumber}}
      </div>
    </div>

    <br />
    <hr />

    <div class="row">
      <div class="col-12">
        <h3>Financial Info</h3>
      </div>
      <br>
     
      <div class="col-12 mt-3">
        <b> SubTotal Amount:</b> {{order?.subTotal | currency:'SAR '}}
      </div>
      <div class="col-12 mt-3">
        <b> Delivery Costs:</b> {{order?.deliveryCost | currency:'SAR '}}
      </div>
      <div class="col-12 mt-3" *ngIf="order?.coupon">
        <b> Coupon:</b> {{order?.coupon?.code}}
      </div>
      
      <div class="col-3 mt-3" *ngIf="order?.coupon">
        <b> Coupon Percentage:</b> {{order?.coupon?.discountPercentage}} %
      </div>
      <!-- <div class="col-12 mt-3">
        <b> Tax:</b> {{order?.tax}}
      </div> -->
      <div class="col-12 mt-3">
        <b> Tax Amount:</b> {{order?.vatAmmount  | currency:'SAR '}}
      </div>
      <div class="col-3 mt-3">
        <b> Total Amount:</b> {{order?.totalAmount | currency:'SAR '}}
      </div>
    </div>

    <br />
    <hr />


    <div class="row">
      <div class="col-12">
        <h3>Order Comments</h3>
      </div>
      <div class="col-12">
        <b> comment:</b> {{order?.comment}}
      </div>
    </div>
    <br />
    <hr />

    <div class="row">

      <div *ngIf="!order?.invoicePath" class="col-lg-3 col-sm-12" style="margin-top: 20px;">

        <input #fileInput type="file" [hidden]="true" (change)="handleFileInput($event.target.files[0])"
          accept=".doc, .docx, .txt, .pdf, .jpg, .jpeg, .tiff, .png " />
        <button *ngIf="!selectedFile" mat-raised-button color="accent" style="margin-right:10px;margin-bottom:10px;"
          (click)="fileInput.click()" matTooltip="Upload Invoice" matTooltipPosition="above">
          Upload Invoice
        </button>
        <button *ngIf="selectedFile" mat-raised-button color="accent" class="col-10" (click)="clearFileInput()">
          {{selectedFile?.name}}
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <div *ngIf="order?.invoicePath" class="col-lg-3 col-sm-12" style="margin-top: 20px;">
        <a [href]="order.invoicePath" target="_blank">
          View Invoice
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-4 mt-3">
        <mat-form-field *ngIf="order" appearance="outline">
          <mat-label>Order Status</mat-label>
          <mat-select [(ngModel)]="order.status">
            <mat-option [value]="1">Active</mat-option>
            <mat-option [value]="2">InActive</mat-option>
            <mat-option [value]="7">Delivered</mat-option>
            <mat-option [value]="15">Cancelled</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4  justify-content-center align-self-center">
        <button mat-raised-button color="accent" (click)="updateStatus()" style="margin: 1px;">Save Changes</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Order Cart Items
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" ngClass.gt-xs="ml-10">
      <mat-card fxFlex.sm="0 1 calc(50%-10px)" fxFlex.md="0 1 calc(33%-10px)" fxFlex.gt-md="0 1 calc(25%-10px)"
        *ngFor="let item of order?.cartItem">
        <img mat-card-image style="height: 20vh !important;width: 20vh !important;"
          [src]="item.item.defaultImageUrl? item.item.defaultImageUrl : '../../../assets/Images/logo.png'"
          alt="Default Item Image">
        <mat-card-header style="height:8vh !important ;">
          <div class="row">
            <mat-card-title> Title: {{item.item.titleEn}}</mat-card-title>
            <!-- <mat-card-subtitle>Arabic Title: {{item.titleAr}}</mat-card-subtitle> -->
            <mat-chip-list aria-label="Status" class="right-align">
              <mat-chip *ngFor="let color of item.item.colors" [style.backgroundColor]="color"></mat-chip>
            </mat-chip-list>
          </div>
        </mat-card-header>
        <hr>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <mat-chip-list aria-label="Status">
                <mat-chip color="accent" selected *ngIf="item.item?.status == 1">Active</mat-chip>
                <mat-chip color="accent" *ngIf="item.item?.status != 1">InActive</mat-chip>
                <mat-chip color="accent" *ngIf="item.item?.isSet">Set Items</mat-chip>
              </mat-chip-list>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <b> Category:</b> {{item.item?.categoryName}}
            </div>
            <div class="col">
              <b> Sub Category:</b> {{item.item?.subCategoryName}}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b> Price:</b> {{item.price}}
            </div>
            <div class="col">
              <b> Brand:</b> {{item.item.brandName}}
            </div>
          </div>
          <div class="row">
            <div class="col">
              <b> Ordered Count:</b> {{item.count}}
            </div>
          </div>
        </mat-card-content>
        <hr>
        <mat-card-actions class="text-right">
          <button mat-raised-button color="accent" (click)="navigateToEdit(item.item.id)">Edit</button>
          <button mat-raised-button color="accent" (click)="navigateToView(item.item.id)">Details</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </mat-card-content>
</mat-card>