import { ProviderDialogComponent } from './../../shared/Components/provider-dialog/provider-dialog.component';
import { SaleDialogComponent } from './sale-dialog/sale-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';
import * as xmlbuilder from 'xmlbuilder';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public host = environment.host;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public displayedColumns = ['id', 'title', 'arabicTitle', 'category', 'subCategory', 'Brand', 'status', 'actions']
  public itemList;
  public baseSearch;
  public totalListCount;
  public imageUrl = environment.imagesUrl;
  public imageSasToken = environment.sasToken;
  public fileUpload: any
  public ImportedExcel: FormData = new FormData();
  filterForm = new FormGroup({
    minPrice: new FormControl('', [Validators.min(1)]),
    maxPrice: new FormControl('', [Validators.min(1)]),
    //gender: new FormControl(''),
    brand: new FormControl(''),
    title: new FormControl(''),
    status: new FormControl(''),
    itemId: new FormControl(''),
    subCategoryName: new FormControl(''),
    pageSize: new FormControl(''),
    pageNumber: new FormControl(''),
  });

  getFormControlByName(controlName: string): FormControl {
    return this.filterForm.get(controlName) as FormControl;
  }

  constructor(
    public dialog: MatDialog,
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getFormControlByName('pageNumber').setValue(1)
    this.getFormControlByName('pageSize').setValue(10)

    this.getAllProducts();
  }
  public navigateToAdd() {
    this.router.navigate(['/setup/choose-category'])
  }
  public navigateToEdit(itemId: number) {
    this.router.navigate(['/setup/product-form/' + itemId + '/' + true])
  }
  public navigateToView(itemId: number) {
    this.router.navigate(['/setup/product-form/' + itemId + '/' + false])
  }
  public clearFilters() {
    this.filterForm.reset();
    this.getFormControlByName('pageNumber').setValue(1)
    this.getFormControlByName('pageSize').setValue(10)
    localStorage.removeItem('searchQuery');
    this.applyFilter();
  }
  applyFilter(): void {
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getFormControlByName('pageNumber').setValue(1)
    localStorage.setItem('searchQuery', JSON.stringify(this.filterForm.value));
    this.getAllProducts();
  }

  addSale(id): void {
    const dialogRef = this.yesNoDialog.open(SaleDialogComponent, {
      data: { itemId: id }
    });
  }

  getAllProducts(): void {
    this.spinner.show();
    const Controller = Controllers.Item;
    const searchQuery = localStorage.getItem('searchQuery')
    if (searchQuery) {
      this.filterForm.patchValue(JSON.parse(searchQuery));
    }

    this.baseService.getFilteredItems(Controller, this.filterForm.value).subscribe(res => {
      this.itemList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      if (this.itemList.length == 0) {
        this.totalListCount = 0;
      }
      this.dataSource = new MatTableDataSource(this.itemList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();

    }, error => {
      this.spinner.hide();
    });
  }
  ExportXMLItems(lang: string, type: string) {
    this.spinner.show();
    this.baseService.postItem('Item/', 'ActiveListNew', {}).subscribe(res => {
      console.log('res: ', res.entities);
      if (type == "google") {
        var data = this.generateRSSFeed(res.entities, lang);
        console.log('data: ', data);


        this.downloadXml(data, lang)
        let blob = new Blob([data], { type: 'text/xml' });
        console.log('blob: ', blob);
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url); //Releases the resources

      } else if (type == "meta") {
        var data = this.generateMetaFeed(res.entities, lang);
        console.log('data: ', data);
        let blob = new Blob([data], { type: 'text/xml' });
        console.log('blob: ', blob);
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url); //Releases the resources
      }
      this.spinner.hide();
    });
  }
  generateMetaFeed(data: any, lang: string): string {
    console.log('data: ', data);

    const rss = xmlbuilder.create('rss', { version: '1.0', encoding: 'UTF-8' });        // Add channel data   
    rss.att('xmlns:g', 'http://base.google.com/ns/1.0');
    rss.att('version', '2.0');
    const channel = rss.ele('channel');
    channel.ele('title', 'Mawad');
    channel.ele('link', 'https://mawadsa.com/');
    channel.ele('description', 'Mawad - Shop Online for Your Building Materials');
    const metadata = channel.ele('metadata');
    metadata.ele('ref_application_id', '2023');
    // Add items

    data.forEach((item) => {

      const rssItem = channel.ele('item');
      rssItem.ele('g:id', item.id);
      rssItem.ele('g:quantity_to_sell_on_facebook', item.itemPrices[0].stock);
      if (lang === 'ar') {
        rssItem.ele('g:description', item.descriptionAr);
      } else {
        rssItem.ele('g:description', item.descriptionEn);
      }
      rssItem.ele('g:condition', 'new');
      rssItem.ele('g:mpn', item.categoryId);
      if (lang === 'ar') {
        rssItem.ele('g:title', item.titleAr);
      } else {
        rssItem.ele('g:title', item.titleEn);
      }
      rssItem.ele('g:availability', 'in stock');
      rssItem.ele('g:price', (item.priceAfterDiscount).toFixed(2) + ' SAR');

      rssItem.ele('g:link', 'https://mawadsa.com/#/shop/products/' + item.id);
      rssItem.ele('g:image_link', item.image);
      rssItem.ele('g:item_group_id', item.categoryId);
      if (lang === 'ar') {
        rssItem.ele('g:product_type', item.categoryNameAr);
      } else {
        rssItem.ele('g:product_type', item.categoryName);
      }
      // Add other item properties here
    });

    return rss.end({ pretty: true });
  }
  generateRSSFeed(data: any, lang: string): string {
    console.log('data: ', data);

    const rss = xmlbuilder.create('rss', { version: '1.0', encoding: 'UTF-8' });        // Add channel data
    rss.att('xmlns:g', 'http://base.google.com/ns/1.0');
    rss.att('version', '2.0');
    const channel = rss.ele('channel');
    channel.ele('title', 'Mawad');
    channel.ele('link', 'https://mawadsa.com/');
    channel.ele('description', 'Mawad - Shop Online for Your Building Materials');
    // const metadata = channel.ele('metadata');
    // metadata.ele('ref_application_id', '2023');
    // Add items

    data.forEach((item) => {

      const rssItem = channel.ele('item');
      rssItem.ele('g:id', item.id);
      if (lang === 'ar') {
        rssItem.ele('title', item.titleAr);
      } else {
        rssItem.ele('title', item.titleEn);
      }
      // rssItem.ele('g:quantity_to_sell_on_facebook', item.itemPrices[0].stock);
      if (lang === 'ar') {
        rssItem.ele('description', item.descriptionAr);
      } else {
        rssItem.ele('description', item.descriptionEn);
      }
      rssItem.ele('link', 'https://mawadsa.com/#/shop/products/' + item.id);
      rssItem.ele('g:image_link', item.image);
      rssItem.ele('g:price', (item.priceAfterDiscount).toFixed(2) + ' SAR');
      if (lang === 'ar') {
        rssItem.ele('g:brand', item.brandNameAr);
      } else {
        rssItem.ele('g:brand', item.brandNameEn);
      }
      // rssItem.ele('g:condition', 'new');
      rssItem.ele('g:mpn', item.categoryId);
      rssItem.ele('g:identifier_exists', 'TRUE');
      rssItem.ele('g:expiration_date', '-');
      if (lang === 'ar') {
        rssItem.ele('g:google_product_category', item.categoryNameAr);
      } else {
        rssItem.ele('g:google_product_category', item.categoryName);
      }
      if (lang === 'ar') {
        rssItem.ele('g:product_type', item.subCategoryNameAr);
      } else {
        rssItem.ele('g:product_type', item.subCategoryName);
      }
      rssItem.ele('g:availability', 'in stock');

      // rssItem.ele('g:item_group_id', item.categoryId);     
      // Add other item properties here
    });

    return rss.end({ pretty: true });
  }

  toggleProducts(from, to) {
    this.baseService.toggleAllItems(from, to).subscribe(response => {
      this.getAllProducts();
      this.notification.showNotification('Product ', 'Products Toggled Successfully', 'success');
    }
      , error => {
        this.notification.showNotification('Toggle Products Failed', '', 'error');
      });
  }
  deleteItem(item): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Item',
          content: 'Are you sure you want to delete a product?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Item;
        this.baseService.removeItem(Controller, item.id).subscribe(res => {
          this.notification.showNotification('Item ', 'Item Deleted Successfully', 'success');
          this.deleteItemFromList(item.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Item Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Item Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }
  changePage(event): void {
    this.getFormControlByName('pageSize').setValue(event.pageSize);
    this.getFormControlByName('pageNumber').setValue(event.pageIndex + 1);
    localStorage.setItem('searchQuery', JSON.stringify(this.filterForm.value));
    this.getAllProducts();
  }
  submitForm(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const filterForm = this.filterForm.value;
      this.baseService.postItem(Controllers.Item, Actions.PostItem, filterForm).subscribe(response => {
        this.spinner.hide();
        this.pushItemToList(response);
        this.notification.showNotification('Add Item', 'Item Modified Successfully', 'success');
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Item Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Item Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  pushItemToList(item): void {
    this.itemList.push(item);
    this.dataSource = new MatTableDataSource(this.itemList)
  }

  deleteItemFromList(id: number): void {
    this.itemList = this.itemList.filter(item => item.id !== id);
    this.dataSource = new MatTableDataSource(this.itemList)
  }

  editForm(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const filterForm = this.filterForm.value;
      this.baseService.postItem(Controllers.Item, Actions.EditItem, filterForm).subscribe(response => {
        this.spinner.hide();
        this.deleteItemFromList((response as any).id);
        this.pushItemToList(response);
        this.notification.showNotification('Edit Item', 'Item Modified Successfully', 'success');
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Item Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Item Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  ExportTemplate() {
    this.spinner.show();
    this.baseService.ExportExcel(Controllers.Excel, Actions.ExportTemplate).subscribe(res => {
      this.downLoadFile(res, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      this.spinner.hide();
    }, error => {
      console.log(error);
      if (error.status === 400) {
        this.notification.showNotification('Edit Item Failed', error.error.Message, 'error');
      }
      else {
        this.notification.showNotification('Edit Item Failed', 'Something went wrong please contact system admin', 'error');
      }
      this.spinner.hide();
    });
  }

  ExportItems() {
    const dialogRef = this.dialog.open(ProviderDialogComponent, {
      data: null
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != null) {
        this.spinner.show()
        this.baseService.ExportExcel(Controllers.Excel, Actions.ExportItems, res.providerId).subscribe(res => {
          this.downLoadFile(res, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
          this.spinner.hide()
        }, error => {
          console.log(error);
          if (error.status === 400) {
            this.notification.showNotification('Export Item ', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Export Item Failed', 'Something went wrong please contact system admin', 'error');
          }
          this.spinner.hide();
        });
      }
    })



  }

  ImportItems() {
    this.fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    this.fileUpload.onchange = () => {
      for (let index = 0; index < this.fileUpload.files.length; index++) {
        const file = this.fileUpload.files[index];
        this.ImportedExcel.append('file', file);
        this.ImportExcel()
      }
    }

    this.fileUpload.click()
  }

  ImportExcel() {
    this.spinner.show()
    this.baseService.ImportExcel(Controllers.Excel, Actions.ImportItems, this.ImportedExcel).subscribe(res => {
      var array = JSON.parse(res);      
      if(array.length==0){
        this.notification.showNotification('Import Items', 'Items Have Been Imported Successfully', 'success')
      }else{
        this.notification.showNotification('Import Item Failed',res, 'error');
      }
      this.spinner.hide()
    }, error => {
      console.log(error);
      if (error.status === 400) {
        this.notification.showNotification('Edit Item Failed', error.error.Message, 'error');
      }
      else {
        this.notification.showNotification('Edit Item Failed', 'Something went wrong please contact system admin', 'error');
      }
      this.spinner.hide();
    });
  }

  downLoadFile(data: any, type: string) {
    var blob = new Blob([data], { type: type.toString() });
    var url = window.URL.createObjectURL(blob);
    var pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }
  downloadXml(xmlData: any, lang: string) {
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = window.URL.createObjectURL(blob);
    console.log('url: ', url);
    const a = document.createElement('a');
    a.href = 'assets/file';
    // a.href = url;
    if (lang == 'ar') {
      a.download = 'google_ar.xml';
    } else {
      a.download = 'google.xml';
    }
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

  }
}
