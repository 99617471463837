import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BannerTypes } from 'src/shared/global-variables/lookups';
import { BannerCustomService } from 'src/shared/services/banner-custom.service';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';

@Component({
  selector: 'app-banner-add',
  templateUrl: './banner-add.component.html',
  styleUrls: ['./banner-add.component.scss']
})
export class BannerAddComponent implements OnInit {
  public isEdit = false;
  public bannerId: number;
  public isUpdateMode: boolean;

  @ViewChild('imageDesktopEnInput') imageDesktopEnInput: ElementRef;
  @ViewChild('imageDesktopArInput') imageDesktopArInput: ElementRef;
  @ViewChild('imageMobileEnInput') imageMobileEnInput: ElementRef;
  @ViewChild('imageMobileArInput') imageMobileArInput: ElementRef;
  public imageDesktopEn;
  public imageDesktopAr;
  public imageMobileEn;
  public imageMobileAr;
  public bannerTypes = BannerTypes;
  public bannerForm: FormGroup;

  get m() {
    return this.bannerForm.controls;
  }

  

  getFormControlByName(controlName: string): FormControl {
    return this.bannerForm.get(controlName) as FormControl;
  }

  constructor(private baseService: BaseService,
    private bannerCustomService: BannerCustomService,
    public spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private router: Router, private route: ActivatedRoute,

    public notification: NotificationService) { 
      this.bannerForm =  formBuilder.group({
        id: new FormControl(0),
        titleEn: new FormControl('', Validators.required),
        titleAr: new FormControl('', Validators.required),
        buttonTitleAr: new FormControl('', Validators.required),
        buttonTitleEn: new FormControl('', Validators.required),        
        buttonInternalLink: new FormControl('',  [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),        ]),
        descriptionAr: new FormControl('', Validators.required),
        descriptionEn: new FormControl('', Validators.required),
        bannerType: new FormControl('', Validators.required),
        imageMobileAr: new FormControl('', Validators.required),
        imageMobileEn: new FormControl('', Validators.required),
        imageDesktopAr: new FormControl('', Validators.required),
        imageDesktopEn: new FormControl('', Validators.required),
        status: new FormControl(2, Validators.required),
        order: new FormControl(1, Validators.required),
      });
    }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.bannerId = params.bannerId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        const Controller = Controllers.Banner;
        this.baseService.getById(Controller, this.bannerId).subscribe(res => {
          console.log('res: ', res);
          this.bannerForm.patchValue(res);
        });

      }
    });
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }

  checkDisplayInHomePage(checked: boolean): void {
    this.getFormControlByName('isDisplayedInHomePage').setValue(checked ? true : false);
  }

  handleFileInput(controlName, file): void {
    var _URL = window.URL || window.webkitURL;

    switch (controlName) {
      case 'imageDesktopEn': {
        this.imageDesktopEn = file; 
        this.getFormControlByName('imageDesktopEn').setValue(file.name);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.getFormControlByName('imageDesktopEn').setValue(event.target.result);
        }
        reader.readAsDataURL(file);
        break;
      }
      case 'imageDesktopAr': {
        this.imageDesktopAr = file;
        this.getFormControlByName('imageDesktopAr').setValue(file.name);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.getFormControlByName('imageDesktopAr').setValue(event.target.result);
        }
        reader.readAsDataURL(file);
        break;
      }
      case 'imageMobileEn': {
        this.imageMobileEn = file;
        this.getFormControlByName('imageMobileEn').setValue(file.name);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.getFormControlByName('imageMobileEn').setValue(event.target.result);
        }
        reader.readAsDataURL(file);
        break;
      }
      case 'imageMobileAr': {
        this.imageMobileAr = file;
        this.getFormControlByName('imageMobileAr').setValue(file.name);
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.getFormControlByName('imageMobileAr').setValue(event.target.result);
        }
        reader.readAsDataURL(file);
        break;
      }
    }
  }

  clearFileInput(controlName): void {
    switch (controlName) {
      case 'imageDesktopEn': {
        this.imageDesktopEnInput.nativeElement.value = '';
        this.imageDesktopEn = null;
        this.getFormControlByName('imageDesktopEn').setValue('');
        break;
      }
      case 'imageDesktopAr': {
        this.imageDesktopArInput.nativeElement.value = '';
        this.imageDesktopAr = null;
        this.getFormControlByName('imageDesktopAr').setValue('');
        break;
      }
      case 'imageMobileEn': {
        this.imageMobileEnInput.nativeElement.value = '';
        this.imageMobileEn = null;
        this.getFormControlByName('imageMobileEn').setValue('');
        break;
      }
      case 'imageMobileAr': {
        this.imageMobileArInput.nativeElement.value = '';
        this.imageMobileAr = null;
        this.getFormControlByName('imageMobileAr').setValue('');
        break;
      }
    }

  } submitForm(): void {
    if (this.bannerForm.invalid || !this.imageDesktopEn || !this.imageDesktopAr || !this.imageMobileAr || !this.imageMobileEn) {
      this.bannerForm.markAllAsTouched();
      // this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const bannerForm = this.bannerForm.value;
      this.bannerCustomService.postBannerWithFile(bannerForm, this.imageDesktopEn, this.imageDesktopAr, this.imageMobileEn, this.imageMobileAr).subscribe(response => {
        this.spinner.hide();
        this.notification.showNotification('Add Banner', 'Banner Added Successfully', 'success');
        this.router.navigate(['/banner'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Banner Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Banner Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.bannerForm.invalid) {
      this.bannerForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const bannerForm = this.bannerForm.value;
      this.bannerCustomService.updateBannerWithFile(bannerForm, this.imageDesktopEn, this.imageDesktopAr, this.imageMobileEn, this.imageMobileAr).subscribe(response => {
        this.spinner.hide();
        this.notification.showNotification('Edit Banner', 'Banner Modified Successfully', 'success');
        this.router.navigate(['/banner'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit Banner Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit Banner Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
