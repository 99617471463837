import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { SubCategoryCustomService } from 'src/shared/services/sub-category-custom.service';

@Component({
  selector: 'app-sub-category-add',
  templateUrl: './sub-category-add.component.html',
  styleUrls: ['./sub-category-add.component.scss']
})
export class SubCategoryAddComponent implements OnInit {
  public isEdit = false;
  public editId: number;
  public isUpdateMode: boolean;
  public selectedFile;
  public categoryList;
  @ViewChild('fileInput') myInputVariable: ElementRef;
  
  subCategoryForm = new FormGroup({
    id: new FormControl(0),
    categoryId: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    nameAr: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    createdDate: new FormControl(''),
    modifiedDate: new FormControl(''),
    deliveryCost: new FormControl(0),
    maxAmountPercentage: new FormControl(0),
    maxAmount: new FormControl(0),
    orderNumber: new FormControl('', [Validators.pattern('[0-9]+'), Validators.required]),
    status: new FormControl(2, Validators.required),
    imagePath: new FormControl('', Validators.required),
  });

  getFormControlByName(controlName: string): FormControl {
    return this.subCategoryForm.get(controlName) as FormControl;
  }
  constructor( private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private route:ActivatedRoute,
    private yesNoDialog: MatDialog,
    public notification: NotificationService,
    public subCategoryService: SubCategoryCustomService) { }

  ngOnInit(): void {
    this.getAllCategories();
    this.route.params.subscribe(params => {
      this.editId = params.editId;
      var stringValue = params.isUpdateMode;
      this.isUpdateMode = (stringValue == "true");
      if (this.isUpdateMode == true) {
        this.isEdit = true;
        this.selectedFile=true;
        const Controller = Controllers.SubCategory;
        this.baseService.getById(Controller, this.editId).subscribe(res => {
          console.log('res: ', res);
          this.subCategoryForm.patchValue(res);
        });

      }
    });
  }
  
  getAllCategories(): void {
    this.spinner.show();
    const Controller = Controllers.Category;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.categoryList = res;

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }
  submitForm(): void {
    if (this.subCategoryForm.invalid) {
      this.subCategoryForm.markAllAsTouched();
    }
    else {
      this.spinner.show();
      const subCategoryForm = this.subCategoryForm.value;
      this.subCategoryService.addSubCategoryWithFile(subCategoryForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();
        
        this.notification.showNotification('Add SubCategory', 'SubCategory Modified Successfully', 'success');
        this.router.navigate(['/sub-category'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add SubCategory Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add SubCategory Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  editForm(): void {
    if (this.subCategoryForm.invalid) {
      this.subCategoryForm.markAllAsTouched();
    }
    else {
      this.spinner.show();
      const subCategoryForm = this.subCategoryForm.value;
      this.subCategoryService.updateSubCategoryWithFile(subCategoryForm, this.selectedFile).subscribe(response => {
        this.spinner.hide();
        
        this.notification.showNotification('Edit SubCategory', 'SubCategory Modified Successfully', 'success');
        this.router.navigate(['/sub-category'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Edit SubCategory Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit SubCategory Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  
  handleFileInput(file): void {
    this.selectedFile = file;
    this.getFormControlByName('imagePath').setValue(file.name);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.getFormControlByName('imagePath').setValue(event.target.result);
    }
    reader.readAsDataURL(file);

  }

  clearFileInput(): void {
    this.myInputVariable.nativeElement.value = '';
    this.selectedFile = null;
    this.getFormControlByName('imagePath').setValue('');
  }

}
