import { NavItem } from '../models/models';

export const navItems: NavItem[] = [
  {
    displayName: 'Dashboard',
    route: '/dashboard',
    icon: 'analytics',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Business Partners',
    route: '/business-partners',
    icon: 'web_stories',
    roles: ['SuperAdmin', 'Admin']
  },

  // {
  //   displayName: 'News',
  //   route: '/news',
  //   icon: 'feed'
  // },
  {
    displayName: 'Brand',
    route: '/setup/brand',
    icon: 'polymer',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Banner',
    route: '/setup/banner',
    icon: 'polymer',
    roles: ['SuperAdmin', 'Admin', 'BannerUser', 'ContentCreator']
  },
  {
    displayName: 'Provider Managment',
    route: '/provider-managment',
    icon: 'corporate_fare',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Categories',
    route: '/setup/category',
    icon: 'category',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Sub Categories',
    route: '/setup/sub-category',
    icon: 'subdirectory_arrow_right',
    roles: ['SuperAdmin', 'Admin']
  },
  //  {
  //   displayName: 'Sub Categories Users',
  //   route: '/setup/choose-sub-category-users',
  //   icon: 'subdirectory_arrow_right'
  // },
  {
    displayName: 'Products',
    route: '/setup/product',
    icon: 'shopping_cart',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Modify Products Prices',
    route: '/setup/bulk-product-prices',
    icon: 'list_alt',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Coupon Managment',
    route: '/coupon-managment',
    icon: 'local_offer',
    roles: ['SuperAdmin', 'Admin']
  },
  // {
  //   displayName: 'Sale',
  //   route: '/setup/sale',
  //   icon: 'local_offer'
  // },
  {
    displayName: 'User Orders',
    route: '/setup/user-orders',
    icon: 'list_alt',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Users',
    route: '/setup/users',
    icon: 'person_pin',
    roles: ['SuperAdmin', 'Admin', 'ContentCreator']
  },

  // {
  //   displayName: 'Users Approver',
  //   route: '/setup/user-approver-list',
  //   icon: 'person_pin'
  // },
  {
    displayName: 'Change Password',
    route: '/auth/change-password',
    icon: 'person_pin',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Push Notifications',
    route: '/setup/push-notifications',
    icon: 'notifications_active',
    roles: ['SuperAdmin', 'Admin', 'ContentCreator']
  },
  // {
  //   displayName: 'Timer',
  //   route: '/timer',
  //   icon: 'notifications_active'
  // },



  {
    displayName: 'FAQs',
    route: '/faqs-managment',
    icon: 'quiz',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Reviews',
    route: '/review-managment',
    icon: 'reviews',
    roles: ['SuperAdmin', 'Admin']
  }
  ,
  {
    displayName: 'Settings',
    route: '/settings',
    icon: 'reviews',
    roles: ['SuperAdmin', 'Admin']
  }
  ,
  {
    displayName: 'Contact Us',
    route: '/contact-us',
    icon: 'contact_mail',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Drivers Requests',
    route: '/setup/drivers-requests',
    icon: 'contact_mail',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Missing Items',
    route: '/setup/missing-items',
    icon: 'contact_mail',
    roles: ['SuperAdmin', 'Admin']
  },
  {
    displayName: 'Integrations',
    route: '/setup/integrations',
    icon: 'sync',
    roles: ['SuperAdmin', 'Admin']
  },
];
