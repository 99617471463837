import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';
import { SubCategoryCustomService } from '../../shared/services/sub-category-custom.service';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
  displayedColumns: string[] = ['subCategoryNameEn', 'subCategoryNameAr', 'description', 'deliveryCost', 'maxAmountPercentage', 'maxAmount', 'Image', 'Status', 'actions'];
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public subCategoryList;

  public totalListCount;

  pageSize=10;
  pageIndex = 0;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService,
    public subCategoryService: SubCategoryCustomService
  ) { }

  ngOnInit(): void {
      this.getAllSubCategorys({ pageSize: 10, pageNumber: 1 });
  }
  searchValue: string;
  applyFilter( ): void {
    
    this.pageIndex =0;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getAllSubCategorys({ pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue });

  }

 
  editItem(element: any): void {
    this.router.navigate(['/edit-sub-category/' + element.id+'/true'])  
  }


  getAllSubCategorys(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.SubCategory;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.subCategoryList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.subCategoryList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  deleteItem(subCategory): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete SubCategory',
          content: 'Are you sure you want to delete a subCategory?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.SubCategory;
        this.baseService.removeItem(Controller, subCategory.id).subscribe(res => {
          this.notification.showNotification('SubCategory ', 'SubCategory Deleted Successfully', 'success');
          this.deleteItemFromList(subCategory.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete SubCategory Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete SubCategory Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize
    this.getAllSubCategorys({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue });
  }

 

  // cancel(): void {
  //   this.isEdit = false;
  //   this.subCategoryForm.reset();
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('id').setValue(0);
  //   this.clearFileInput()
  // }

  pushItemToList(item): void {
    this.subCategoryList.push(item);
    this.dataSource = new MatTableDataSource(this.subCategoryList);
  }

  deleteItemFromList(id: number): void {
    this.subCategoryList = this.subCategoryList.filter(subCategory => subCategory.id !== id);
    this.dataSource = new MatTableDataSource(this.subCategoryList);
  }

 
}
