export const environment = {
  production: true,
  //apiPreLink: 'https://mawadsoliveapinew.azurewebsites.net/api/',  
  apiPreLink: 'https://mawadsoapi.azurewebsites.net/api/',
  token: 'acessToken',
  host: 'https://mawaddev.azurewebsites.net/',
  imagesUrl: 'https://mawad.file.core.windows.net/appattachments/appimages/',
  sasToken: '?sv=2020-08-04&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2022-10-01T03:28:42Z&st=2022-06-01T19:28:42Z&spr=https&sig=Ii7kp14GWkBwQhAQj3%2F%2FYbXU%2FZyMXxIoBz07hwDvEkw%3D',
  roleClaim: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  addressClaim: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/streetaddress',
  mobileClaim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone',
  userName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  idClaim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  firebaseConfig: {
    apiKey: "AIzaSyDdClcUYZQ62QJMi4F466jdQyxVVuvYSo8",
    authDomain: "mawad-1358f.firebaseapp.com",
    projectId: "mawad-1358f",
    storageBucket: "mawad-1358f.appspot.com",
    messagingSenderId: "468022485923",
    appId: "1:468022485923:web:29740e913045259cb13016"
  }
};
