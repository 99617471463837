<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Reviews': 'Edit Reviews'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="reviewsForm">
            <div class="row">
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <mat-form-field appearance="outline">
                  <mat-label>Description EN </mat-label>
                  <input type="text" matInput formControlName="descriptionEn">
                  <mat-error *ngIf="reviewsForm.controls.descriptionEn.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <mat-form-field appearance="outline">
                  <mat-label>Description AR </mat-label>
                  <input type="text" matInput formControlName="descriptionAr">
                  <mat-error *ngIf="reviewsForm.controls.descriptionAr.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-6 col-xs-6">
                <mat-form-field appearance="outline">
                  <mat-label>User Name</mat-label>
                  <input type="text" matInput formControlName="userName">
                  <mat-error *ngIf="reviewsForm.controls.userName.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div>
              <!-- <div class="col-lg-4 col-sm-6 col-xs-6">
                <mat-form-field appearance="outline">
                  <mat-label>User Description En</mat-label>
                  <input type="text" matInput formControlName="userDescriptionEn">
                  <mat-error *ngIf="reviewsForm.controls.userDescriptionEn.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div> -->
              <!-- <div class="col-lg-4 col-sm-6 col-xs-6">
                <mat-form-field appearance="outline">
                  <mat-label>User Description AR </mat-label>
                  <input type="text" matInput formControlName="userDescriptionAr">
                  <mat-error *ngIf="reviewsForm.controls.userDescriptionAr.invalid" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

                </mat-form-field>
              </div> -->
              <div class="col-lg-4 col-sm-6 col-xs-6 mt-2"  >
                <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">Is Active?</mat-checkbox>
              </div>
              <!-- <div class="col-lg-4 col-sm-6 col-xs-6 mt-2" >
                <input #fileInput type="file" [hidden]="true" (change)="handleLogoFileInput($event.target.files[0])"
                  accept=".jpg,.jpeg, .tiff, .png , .pdf" />
                <button *ngIf="!selectedLogoFile" mat-raised-button color="accent"
                  style="margin-right:10px;margin-bottom:10px;" (click)="fileInput.click()"
                  matTooltip="Upload Certificate" matTooltipPosition="above">Upload Logo image</button>
                <button *ngIf="selectedLogoFile" mat-raised-button color="accent"
                  (click)="clearLogoFileInput()">{{selectedLogoFile?.name}}
                  <mat-icon>delete</mat-icon>
                </button>
                <div *ngIf="reviewsForm.controls.logoPath.value">
                  <a *ngIf="!selectedFile" target="_blank" [href]="reviewsForm.controls.logoPath.value">
                    View Logo
                  </a>
  
                  <a mat-icon-button  color="accent" (click)="clearLogoFileInput()">{{selectedFile?.name}}
                    <mat-icon >delete</mat-icon>
                  </a>
                </div>
                <mat-error *ngIf="reviewsForm.controls.logoPath.invalid && reviewsForm.touched" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>
              </div> -->
              
              <div class="col-lg-4 col-sm-6 col-xs-6 mt-2"  >
                <input #fileInput2 type="file" [hidden]="true" (change)="handleReviewerFileInput($event.target.files[0])"
                  accept=".jpg,.jpeg, .tiff, .png , .pdf" />
                <button *ngIf="!selectedReviewrImageFile" mat-raised-button color="accent"
                  style="margin-right:10px;margin-bottom:10px;" (click)="fileInput2.click()"
                  matTooltip="Upload Certificate" matTooltipPosition="above">Upload Reviewer image</button>
                <button *ngIf="selectedReviewrImageFile" mat-raised-button color="accent"
                  (click)="clearReviewerFileInput()">{{selectedReviewrImageFile?.name}}
                  <mat-icon>delete</mat-icon>
                </button>
                <div *ngIf="reviewsForm.controls.userImagePath.value">
                  <a *ngIf="!selectedFile" target="_blank" [href]="reviewsForm.controls.userImagePath.value">
                    View Reviewer
                  </a>
  
                  <a mat-icon-button color="accent" (click)="clearReviewerFileInput()">{{selectedFile?.name}}
                    <mat-icon >delete</mat-icon>
                  </a>
  
                </div>
                <mat-error *ngIf="reviewsForm.controls.userImagePath.invalid && reviewsForm.touched" class="mt-2" style="font-size: 14px !important;">This field is requird *</mat-error>

              </div>
            </div>
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"  class="ml-2" style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"  class="ml-2" style="margin: 1px;">Edit</button>
                <button mat-raised-button color="accent"   [routerLink]="['/review-managment']" class="ml-2"  style="margin: 1px;">Cancel</button>
               </div>
            </div>
          </form>
        </div>
      </div>
  </mat-card-content>
  </mat-card>