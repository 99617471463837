import { Actions } from './../../../shared/global-variables/api-config';
import { FormGroup, FormControl, Validators, FormControlName, FormBuilder } from '@angular/forms';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Controllers } from 'src/shared/global-variables/api-config';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { NgxGalleryComponent, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { environment } from 'src/environments/environment';
import { fakeProducts } from 'src/shared/global-variables/fake-data';
import { MatSelect } from '@angular/material/select';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { MadarItemService } from '../../../shared/services/madar-item.service';
import { PricingMethods } from 'src/shared/global-variables/lookups';

@Component({
  selector: 'app-product-form-add',
  templateUrl: './product-form-add.component.html',
  styleUrls: ['./product-form-add.component.scss']
})
export class ProductFormAddComponent implements OnInit {
 
  public pricingMethods = PricingMethods;
  public customDescriptionEditorEn = ClassicEditor;
  public customDescriptionEditorAr = ClassicEditor;
  public customeDescriptionAndValueEn = '';
  public customeDescriptionAndValueAr = '';
  public categoryList;
  public subCategoryList;
  public salesList;
  public subCategoryIdParam: number;
  public itemImagesList: any[] = [];
  public itemImagesToAdd: FormData = new FormData();
  public itemVideoToAdd: FormData = new FormData();
  public setItemsList: any[] = [];
  public setIds: any[] = [];
  public setName = new FormControl();
  public category = new FormControl('', Validators.required);
  public setItems = new FormControl();
  public brandsList;
  public providersList;
  public fileUpload: any;
  public VideoUpload: any;
  public isEdit = false;
  public galleryOptions: NgxGalleryOptions[];
  public galleryImages: NgxGalleryImage[];
  public accept = 'image/*';
  public videoAccept = 'video/*';
  public imageUrl = environment.imagesUrl;
  public imageSasToken = environment.sasToken;
  public interval: NodeJS.Timer;
  public itemPrice: any[] = [];
  public videoToShow;

  public suggestedItemsList: any[];
  public itemsList: any[];

  status: any[] = [{ id: 1, Name: 'Active' }, { id: 2, Name: 'InActive' }, { id: 8, Name: 'Empty' }]
 
  @ViewChild(NgxGalleryComponent) ngxGalleryComponent;
  @ViewChild('allItemsList') allItemsList: MatSelect;
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  public productForm = new FormGroup({
    id: new FormControl(0),
    subCategoryId: new FormControl({ value: '', disabled: true }, Validators.required),
    brandId: new FormControl('', Validators.required),
    providerId: new FormControl('', Validators.required),
    isItemhidden: new FormControl(false),
    titleEn: new FormControl('', Validators.required),
    titleAr: new FormControl('', Validators.required),
    descriptionEn: new FormControl('', Validators.required),
    descriptionAr: new FormControl('', Validators.required),
    status: new FormControl(1, Validators.required),
    itemOrder: new FormControl('', [Validators.min(1)]),
    isItemIDHidden: new FormControl(false),
    sku: new FormControl(''),
    customDescriptionEn: new FormControl(''),
    customDescriptionAr: new FormControl(''),
    //availability: new FormControl(''),
    isFeaturedProduct: new FormControl(''),
    madarIntegrationId: new FormControl(''),

  });
  @ViewChild('form') form: any;
  itemPriceForm: FormGroup;
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public notification: NotificationService,
    public router: Router,
    private madarItemService: MadarItemService
  ) { 
    this.itemPriceForm =  this.fb.group({
      id: new FormControl(0),
      price: new FormControl(''),
      unitType: new FormControl('', Validators.required),
      discount: new FormControl(''),
      stock: new FormControl(''),
       size: new FormControl('', Validators.required),
      itemId: new FormControl(''),
      integrationId: new FormControl(''),
      pricingMethod: new FormControl(''),
       status: new FormControl('', Validators.required),
      priceBeforDiscount: new FormControl('', Validators.required)
    })
  }

  getProductFormControlByName(controlName): FormControl {
    return this.productForm.get(controlName) as FormControl;
  }

  ngOnInit(): void {
    this.fetchParams();
    this.initializeGalleryConfigs();
    this.getAllCategories();
    this.getAllBrands();
    this.getAllProviders();
    this.getAllItems();
  }
  public startSimulator() {
    const randomProduct = fakeProducts[Math.floor(Math.random() * fakeProducts.length)];
    this.getProductFormControlByName('brandId').setValue(randomProduct.brandId);

    if (randomProduct.itemOrder) {
      this.getProductFormControlByName('itemOrder').setValue(randomProduct.itemOrder);
    }

  }
  changeSizeValidation() {
    if (this.itemPriceForm.controls.pricingMethod.value == 2) {
      this.itemPriceForm.controls.size.setValidators(Validators.pattern("^-?[0-9]*[0-9,\.]+$"));
      this.itemPriceForm.controls.size.updateValueAndValidity();
    }
    else {
      this.itemPriceForm.controls.size.clearValidators();
      this.itemPriceForm.controls.size.setValidators(Validators.required);
      this.itemPriceForm.controls.size.updateValueAndValidity();
    }    
  }
  getPricingMethod(id: number) {
    if (id > 0) {
      return this.pricingMethods.find(x => x.id == id).nameEn
    }
    return this.pricingMethods[0].nameEn
  }

  public stopSimulator() {
    clearInterval(this.interval);
  }
  public initializeGalleryConfigs() {
    this.galleryOptions = [
      {
        width: '1000px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: 'auto',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [];
  }
  submitForm(): void {
    if (this.productForm.invalid ||
      this.itemPrice.length == 0 ||
      !this.galleryImages.length ||
      !this.category.value
    ) {
      this.productForm.markAllAsTouched();

      if (!this.category.value) {
        this.category.markAsTouched();
      }
      if(this.itemPrice.length == 0){
        this.itemPriceForm.markAllAsTouched();
      }
      if (!this.itemPriceForm.value && this.itemPrice.length == 0) {
        this.itemPriceForm.markAllAsTouched();
      }
      if(this.galleryImages.length==0 && this.productForm.valid){
        this.notification.showNotification('Form Validation','Image is required', 'warn');
      }
      // this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      this.getProductFormControlByName('customDescriptionEn').setValue(this.customeDescriptionAndValueEn)
      this.getProductFormControlByName('customDescriptionAr').setValue(this.customeDescriptionAndValueAr)
      const productForm = this.productForm.value;
      productForm.ItemPrice = this.itemPrice;

      this.baseService.postItem(Controllers.Item, Actions.PostItem, productForm).subscribe(response => {
        this.UploadVideo(response.id)
        this.uploadItemImages(response.id);
        this.addSuggestions(response.id)
        this.router.navigate(['/setup/product'])
      }, error => {
        console.log(error);
        if (error.status === 400) {
          this.notification.showNotification('Add Product Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add Product Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
  private UploadVideo(itemId: number) {
    this.spinner.show()
    this.baseService.postItemImages(Controllers.Item, Actions.AddItemVideo, itemId, this.itemVideoToAdd).subscribe(response => {
      this.spinner.hide()
    }, error => {
      this.spinner.hide()
      this.removeVideo()
      if (error.status === 400) {
        this.notification.showNotification('Add Product Failed', 'Uploaded files are invalid', 'error');
      }
      else {
        this.notification.showNotification('Add Product Failed', 'Something went wrong please contact system admin', 'error');
      }
    })
  }
  private uploadItemImages(itemId: number) {
    this.baseService.postItemImages(Controllers.Item, Actions.AddItemImages, itemId, this.itemImagesToAdd).subscribe(response => {
      this.notification.showNotification('Add Product', 'Product Added Successfully', 'success');
      this.resetForm();
      this.spinner.hide();
    }, error => {
      if (error.status === 400) {
        this.notification.showNotification('Add Product Failed', 'Uploaded files are invalid', 'error');
      }
      else {
        this.notification.showNotification('Add Product Failed', 'Something went wrong please contact system admin', 'error');
      }
      this.resetImages();
      this.spinner.hide();
    })
  }
  /* Reset Changable Form Fields */
  public resetForm() {
    this.getProductFormControlByName('brandId').reset();
    this.getProductFormControlByName('itemOrder').reset();
    this.itemImagesToAdd = new FormData();
    this.setName.reset();
    this.setIds = [];
    this.galleryImages = [];
    this.setItemsList = [];
    this.setItems.reset();
    this.fileUpload = null;
    this.resetImages();
  }
  public resetImages() {
    this.itemImagesToAdd = new FormData();
    this.galleryImages = [];
    this.fileUpload = null;
  }
  /* Get Lookups */
  getAllCategories(): void {
    this.spinner.show();
    const Controller = Controllers.Category;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.categoryList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  getAllBrands(): void {
    this.spinner.show();
    const Controller = Controllers.Brand;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.brandsList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  getAllProviders(): void {
    this.spinner.show();
    const Controller = Controllers.Provider;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.providersList = res;

      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  getAllSales(): void {
    this.spinner.show();
    const Controller = Controllers.Sale;
    this.baseService.getAllItems(Controller).subscribe(res => {
      this.salesList = res;
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }
  getSubCategoryByCategories(categoryId: number): void {
    this.spinner.show();
    const Controller = Controllers.SubCategory;
    this.baseService.getByParentId(Controller, categoryId).subscribe(res => {
      this.subCategoryList = res;
      this.getProductFormControlByName('subCategoryId').enable();
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

  itemSelectionChange(event) {
    const originalItem = this.itemsList.find(x => x.id == event.value)
    const selectedItem = this.setItemsList.find(x => x.id == event.value)
    if (!selectedItem && event.selected) {
      this.setItemsList.push(originalItem);
      this.setIds.push(event.value);
    }
    if (selectedItem && !event.selected) {
      this.setItemsList = this.setItemsList.filter(x => x != originalItem);
      this.setIds = this.setIds.filter(x => x != event.value);
    }
  }

  public onImagesSelected() {
    this.spinner.show()
    this.fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    console.log(this.fileUpload);

    this.fileUpload.onchange = () => {
      for (let index = 0; index < this.fileUpload.files.length; index++) {
        const file = this.fileUpload.files[index];
        this.itemImagesToAdd.append('files', file);
        var imageToShow;
        let reader = new FileReader();
        reader.addEventListener("load", () => {
          imageToShow = reader.result;
          this.galleryImages.push({ big: imageToShow, medium: imageToShow, small: imageToShow });
        }, false);
        if (file) {
          imageToShow = reader.readAsDataURL(file);
        }
      }
    };
    this.spinner.hide();
    this.fileUpload.click();
  }
  public onFakeImagesSelected() {
    var blob = null;
    var xhr = new XMLHttpRequest();
    var fakearray = [1, 2, 3, 4, 5, 6, 7];
    xhr.open("GET", './assets/fakeImages/p1.jpeg');
    xhr.responseType = "blob";//force the HTTP response, response-type header to be blob
    xhr.onload = () => {
      blob = xhr.response;//xhr.response is now a blob object
      var file = new File([blob], 'p1.jpeg', { type: 'image/jpeg', lastModified: Date.now() });
      console.log(file);
      this.itemImagesToAdd.append('files', file);
      var imageToShow;
      let reader = new FileReader();
      reader.addEventListener("load", () => {
        imageToShow = reader.result;
        this.galleryImages.push({ big: imageToShow, medium: imageToShow, small: imageToShow });
        this.spinner.hide();
      }, false);
      if (file) {
        imageToShow = reader.readAsDataURL(file);
        this.spinner.hide();
      }
    }
    xhr.send()
  }
  public removeImageFromGallery() {
    const imageIndex = this.ngxGalleryComponent.selectedIndex;
    const deltedImage = this.galleryImages[imageIndex];
    this.galleryImages = this.galleryImages.filter(image => image != deltedImage)
  }

  isItemIdHiddenChanged(event) {
    if (event.checked) {
      this.setItems.setValidators(Validators.required);
    } else {
      this.setItemsList = [];
    }
  }
  public fetchParams() {
    this.route.params.subscribe(params => {
      this.category.setValue(Number(params.categoryId));
      this.getProductFormControlByName('subCategoryId').setValue(Number(params.subCategoryId));
      this.subCategoryIdParam = params.subCategoryId;
    });

    if (this.madarItemService.item) {
      const madarItem = this.madarItemService.item;
      this.getProductFormControlByName('titleEn').setValue(madarItem.title);
      this.getProductFormControlByName('madarIntegrationId').setValue(madarItem.madarId);
      this.itemPriceForm.controls.priceBeforDiscount.setValue(madarItem.price)
      this.madarItemService.clearItem()
    }
  }
  check(checked: boolean): void {
    this.getProductFormControlByName('isFeaturedProduct').setValue(checked);
  }

  onAddItemPrice( ) {
    if (this.itemPriceForm.valid) {
      this.itemPrice.push(this.itemPriceForm.value)
      this.form.resetForm();
      this.itemPriceForm.controls.id.setValue(0)
      this.itemPriceForm.controls.itemId.setValue(0)
    }
  }
  onDeleteItemPrice(itemPrice) {
    if (itemPrice.id > 0) {

    } else {
      this.itemPrice = this.itemPrice.filter(x => x != itemPrice)
    }
  }

  GetStatusName(statusId) {
    return this.status.find(x => x.id == statusId) ? this.status.find(x => x.id == statusId).Name : null
  }

  //#region Ck Editor Handler
  /**
   * used for english editor ,handling input
   * @param param0
   */
  public onChangeDescriptionEn({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.customeDescriptionAndValueEn = data
  }
  /**
 * used for Arabic editor ,handling input
 * @param param0
 */
  public onChangeDescriptionAr({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.customeDescriptionAndValueAr = data
  }
  //#endregion

  //#region Video Handler
  toggleVideo() {
    this.videoplayer.nativeElement.play();
  }

  removeVideo() {
    this.videoToShow = null
    this.itemVideoToAdd = new FormData();
  }

  onVideoSelected() {
    this.spinner.show()
    this.VideoUpload = document.getElementById('videoUpload') as HTMLInputElement;
    console.log(this.VideoUpload);
    this.VideoUpload.onchange = () => {
      for (let index = 0; index < this.VideoUpload.files.length; index++) {
        const file = this.VideoUpload.files[index];
        this.itemVideoToAdd.append('file', file);

        let reader = new FileReader();
        reader.addEventListener("load", () => {

          this.videoToShow = reader.result;
          //this.galleryImages.push({ big: imageToShow, medium: imageToShow, small: imageToShow });
        }, false);
        if (file) {
          this.videoToShow = reader.readAsDataURL(file);
        }
      }
    };
    this.spinner.hide();
    this.VideoUpload.click();
  }

  getAllItems() {
    this.baseService.getAllItems(Controllers.Item).subscribe(res => {
      this.itemsList = (res as any)
    })
  }

  addSuggestions(itemId) {
    if (this.suggestedItemsList && this.suggestedItemsList.length > 0) {
      this.baseService.addSuggestedItems(itemId, this.suggestedItemsList).subscribe(res => {
      })
    }
  }

  //#endregion
}
