<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Coupon': 'Edit Coupon'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="couponForm">
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Coupon Code </mat-label>
                  <input type="text" matInput formControlName="code">
                  <mat-error *ngIf="couponForm.controls.code.invalid" 
                    style="font-size: 14px !important;">Coupon Code is requird *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Discount Percentage</mat-label>
                  <input type="text" matInput formControlName="discountPercentage" appPercentageDirective>
                  <mat-error
                    *ngIf="couponForm.controls.discountPercentage.invalid && !couponForm.controls.discountPercentage.value"
                     style="font-size: 14px !important;">
                    Discount Percentage is requird *
                  </mat-error>
                  <mat-error
                    *ngIf="couponForm.controls.discountPercentage.invalid && couponForm.controls.discountPercentage.value"
                     style="font-size: 14px !important;">
                    Discount Percentage accepts numbers only *
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Order Limit</mat-label>
                  <input type="text" matInput formControlName="orderLimit">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Max Discount</mat-label>
                  <input type="text" matInput formControlName="maxDiscount">
                </mat-form-field>
              </div>
              <div class="col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>Expiry Date</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="expiryDate" [min]="tomorrow"  (dateChange)="addEvent('change', $event)">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                  <mat-error *ngIf="couponForm.controls.expiryDate.invalid" 
                    style="font-size: 14px !important;">Expiry Date is requird *</mat-error>
  
                </mat-form-field>
              </div>
              <div class="col-lg-2 col-md-4" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('isForOneUser').value == 1"
                  (change)="checkBit('isForOneUser',$event.checked)">Is For One User ?</mat-checkbox>
              </div>
              <div class="col-lg-2 col-md-4" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('isFreeDelivery').value == 1"
                  (change)="checkBit('isFreeDelivery',$event.checked)">Is Free Delivery ?</mat-checkbox>
              </div>
              <div class="col-lg-2 col-md-4" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('status').value == 1"
                  (change)="check('status',$event.checked)">Is Active?</mat-checkbox>
              </div>
  
            </div>
            <!-- <mat-card class="mt-3 custom-card">
              <mat-card-header>
                <mat-card-title>
                  Category Discount
                </mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <form #form="ngForm" novalidate [formGroup]="couponRules" (submit)="onAddCategoryDiscount()">        
                  <div class="row">
                    <div class="col-lg-3 col-md-12 text-center">
                      <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <mat-select formControlName="categoryId">
                          <mat-option *ngFor="let method of categories" [value]="method.id">
                            {{method.categoryName}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="couponRules.controls.categoryId.invalid" 
                          style="font-size: 14px !important;">Category
                          is Required *</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3 col-md-12 text-center">
                      <mat-form-field appearance="outline">
                        <mat-label>Discount Percentage</mat-label>
                        <input type="number" min="1" matInput formControlName="discountPercentage" appPercentageDirective>
                        <mat-error *ngIf="couponRules.controls.discountPercentage.invalid" 
                          style="font-size: 14px !important;">Discount Percentage is Required *</mat-error>
                      </mat-form-field>
                    </div>  
                    <div class="col-lg-3 col-md-12 align-self-center">
                      <button mat-raised-button color="accent"  >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
            </mat-card-content>
                <div class="row">
                  <div class="col-12 text-center">
                    <table class="table" *ngIf="categoriesDiscountList.length > 0">
                      <thead>
                        <tr>
                          <th scope="col">Category</th>
                          <th scope="col">Discount Percentage</th>
  
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let discount of categoriesDiscountList">
                          <td>{{getCategoryName(discount.categoryId)}}</td>
  
                          <td>{{discount.discountPercentage}} %</td>
                          <td>
                            <button mat-raised-button color="accent" (click)="onDeleteCategoryDiscount(discount)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
            
            </mat-card> -->
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                  style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                  style="margin: 1px;">Update</button>
                <button mat-raised-button color="accent"  [routerLink]="['/coupon-managment']"  class="ml-2" style="margin: 1px;">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>