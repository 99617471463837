import { SaleDialogComponent } from './products/sale-dialog/sale-dialog.component';
import { BulkSaleComponent } from './bulk-sale/bulk-sale.component';
import { ProductFormAddComponent } from './products/product-form-add/product-form-add.component';
import { SizeComponent } from './size/size.component';
import { ColorComponent } from './color/color.component';
import { ProductsComponent } from './products/products.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrandComponent } from './brand/brand.component';
import { MaterialModule } from './../app/material.module';
import { SetupRoutingModule } from './setup-routing.module';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SetupComponent } from './setup.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { UsersComponent } from './users/users.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { OrdersComponent } from './user-orders/orders/orders.component';
import { OrderDetailsComponent } from './user-orders/order-details/order-details.component';
import { SaleComponent } from './sale/sale.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizeInterceptor } from 'src/auth/authorize.interceptor';
import { ProductFormComponent } from './products/product-form/product-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChooseCategoryComponent } from './products/choose-category/choose-category.component';
import { ChooseSubCategoryComponent } from './products/choose-sub-category/choose-sub-category.component';
import { ChooseGenderComponent } from './products/choose-gender/choose-gender.component';
import { NewsComponent } from './news/news.component';
import { ChooseSubCategoryUsersComponent } from './sub-category-users/choose-sub-category-users/choose-sub-category-users.component';
import { SubCategoryUsersComponent } from './sub-category-users/sub-category-users/sub-category-users.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApplicationExceptionsComponent } from './application-exceptions/application-exceptions.component';
import { UserApproverListComponent } from './user-approver-list/user-approver-list.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { ChangePasswordAdminComponent } from './change-password-admin/change-password-admin.component';
import { ProviderManagmentComponent } from './provider-managment/provider-managment.component';
import { CouponManagementComponent } from './coupon-management/coupon-management.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FaqsComponent } from './faqs/faqs.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LookupsComponent } from './lookups-management/lookups.component';
import { BusinessPartnersComponent } from './business-partners/business-partners.component';
import { SettingsComponent } from './settings/settings.component';
import { BulkProductPricesComponent } from './bulk-product-prices/bulk-product-prices.component';
import { DriversRequestsComponent } from './drivers-requests/drivers-requests.component';
import { MissingItemsComponent } from './missing-items/missing-items.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { BannersComponent } from './Banners/banners.component';
import { BusinessPartnersAddComponent } from './business-partners-add/business-partners-add.component';
import { BrandAddComponent } from './brand-add/brand-add.component';
import { BannerAddComponent } from './banner-add/banner-add.component';
import { ProviderManagmentAddComponent } from './provider-managment-add/provider-managment-add.component';
import { CategoryAddComponent } from './category-add/category-add.component';
import { SubCategoryAddComponent } from './sub-category-add/sub-category-add.component';
import { CouponAddComponent } from './coupon-add/coupon-add.component';
import { FaqAddComponent } from './faq-add/faq-add.component';
import { ReviewAddComponent } from './review-add/review-add.component';
import { PercentageDirective } from './bulk-product-prices/percentage.directive';


@NgModule({
  imports: [
    CommonModule,
    SetupRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    MaterialModule,
    NgxGalleryModule,
    CKEditorModule,
    FlexLayoutModule,
    NgSelectModule
  ],
  entryComponents: [
    SaleDialogComponent
  ],
  declarations: [
    SetupComponent,
    LookupsComponent,
    SaleDialogComponent,
    BannersComponent,
    BannerAddComponent,
    BrandComponent,
    BrandAddComponent,
    BulkSaleComponent,
    SubCategoryComponent,
    SubCategoryAddComponent,
    CategoryComponent,
    CategoryAddComponent,
    ApplicationExceptionsComponent,
    ProductsComponent,
    ProductFormComponent,
    ColorComponent,
    SizeComponent,
    ProductFormAddComponent,
    SizeComponent,
    UsersComponent,
    PushNotificationsComponent,
    UserOrdersComponent,
    OrdersComponent,
    OrderDetailsComponent,
    SaleComponent,
    ChooseCategoryComponent,
    ChooseSubCategoryComponent,
    ChooseGenderComponent,
    NewsComponent,
    ChooseSubCategoryUsersComponent,
    SubCategoryUsersComponent,
    UserApproverListComponent,
    AddUsersComponent,
    EditUsersComponent,
    ChangePasswordAdminComponent,
    ProviderManagmentComponent,
    ProviderManagmentAddComponent,
    CouponManagementComponent,
    CouponAddComponent,
    FaqsComponent,FaqAddComponent,
    ReviewsComponent,ReviewAddComponent,
    ContactUsComponent,
    BusinessPartnersComponent,
    BusinessPartnersAddComponent,
    SettingsComponent,
    BulkProductPricesComponent,PercentageDirective,
    DriversRequestsComponent,
    MissingItemsComponent,
    IntegrationsComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },DatePipe
  ],
})
export class SetupModule { }
