<mat-card>
  <mat-card-header class="d-flex justify-content-between align-items-center title-border">
    <mat-card-title class="mb-0">
      Category
    </mat-card-title>
    <div>
      <button mat-raised-button color="accent" [routerLink]="['/add-category']" class="m-1">Add New Category</button>
    </div>
  </mat-card-header>

  <mat-card-content>
    <!-- <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Zara" #input>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-3 ml-auto">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" #input>
          <button type="button" (click)="applyFilter()" class="search-btn btn"><mat-icon>search</mat-icon></button>

        </mat-form-field>
      </div>
    </div>
    <div class="table-responsive" *ngIf="dataSource" >
      <mat-table [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">

        <!-- CategoryNameEn Column -->
        <ng-container matColumnDef="CategoryNameEn">
          <mat-header-cell *matHeaderCellDef> Category Name En</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Category Name En"> {{element.categoryName}} </mat-cell>
        </ng-container>

        <!-- CategoryNameAr Column -->
        <ng-container matColumnDef="CategoryNameAr">
          <mat-header-cell *matHeaderCellDef> Arabic Category Name </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Arabic Category Name"> {{element.categoryNameAr}} </mat-cell>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="Image">
          <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Image">
            <a [href]="element.imagePath" _download>
              <img src="{{element.imagePath}}" style="height: 30px;" alt="">
            </a> </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="accent" selected *ngIf="element?.status == 1">Active</mat-chip>
              <mat-chip color="accent" *ngIf="element?.status != 1">InActive</mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-raised-button color="accent" (click)="editItem(element)" class="mx-2">Edit</button>

            <button mat-raised-button color="warn" (click)="deleteItem(element)" class="mx-2">Delete</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell   class="no-data-cell"   *matCellDef="let element">No Data Found</mat-cell>
        </ng-container> 
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
      </mat-table>
    </div>

    <mat-paginator (page)="changePage($event)"   [pageIndex]="pageIndex"  [length]="totalListCount" [pageSize]="pageSize"
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>