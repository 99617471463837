<mat-card>
  <mat-card-header class="d-flex justify-content-between align-items-center title-border">
    <mat-card-title class="mb-0">
      Sub Category
    </mat-card-title>
    <div>
      <button mat-raised-button color="accent" [routerLink]="['/add-sub-category']" class="m-1">Add New Sub
        Category</button>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="filter" #input>
    </mat-form-field> -->
    <div class="row">
      <div class="col-lg-3 ml-auto">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Search</mat-label>
          <input matInput placeholder="Search" [(ngModel)]="searchValue" #input>
          <button type="button" (click)="applyFilter()" class="search-btn btn"><mat-icon>search</mat-icon></button>

        </mat-form-field>
      </div>
    </div>

    <div class="table-responsive" *ngIf="dataSource" >
      <mat-table [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">

        <!-- CategoryNameEn Column -->
        <ng-container matColumnDef="subCategoryNameEn">
          <mat-header-cell *matHeaderCellDef> Sub Category Name En</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Sub Category Name En"> {{element.name}} </mat-cell>
        </ng-container>

        <!-- CategoryNameAr Column -->
        <ng-container matColumnDef="subCategoryNameAr">
          <mat-header-cell *matHeaderCellDef> Sub Category Name Ar</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Sub Category Name Ar"> {{element.nameAr}} </mat-cell>
        </ng-container>

        <!-- CategoryNameAr Column -->
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Description"> {{element.description}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deliveryCost">
          <mat-header-cell *matHeaderCellDef> Delivery Cost</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Description"> {{element.deliveryCost}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="maxAmountPercentage">
          <mat-header-cell *matHeaderCellDef> Percentage</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Description"> {{element.maxAmountPercentage}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="maxAmount">
          <mat-header-cell *matHeaderCellDef> Max Amount</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Description"> {{element.maxAmount}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Image">
          <mat-header-cell *matHeaderCellDef> Image </mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Image">
            <a *ngIf="element.imagePath" [href]="element.imagePath" _download>
              <img src="{{element.imagePath}}" style="height: 30px;" alt="">
            </a>
          </mat-cell>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="Status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip color="accent" selected *ngIf="element?.status == 1">Active</mat-chip>
              <mat-chip color="accent" *ngIf="element?.status != 1">InActive</mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <button mat-raised-button color="accent" (click)="editItem(element)" class="mx-2">Edit</button>

            <button mat-raised-button color="warn" (click)="deleteItem(element)" class="mx-2">Delete</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell   class="no-data-cell"   *matCellDef="let element">No Data Found</mat-cell>
        </ng-container> 
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
      </mat-table>
    </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount" [pageSize]="pageSize"   [pageIndex]="pageIndex" 
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>