<mat-card>
  <mat-card-header>
    <mat-card-title>
      {{!isEdit ? 'Add ': 'Edit '}} {{title}}
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="lookupsForm">
          <div class="row">
            <div class="col-lg-6">
              <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input type="text" matInput formControlName="description">
                <mat-error *ngIf="lookupsForm.controls.description.invalid" 
                  style="font-size: 14px !important;">Description is Required *</mat-error>
              </mat-form-field>
              <!-- </div>
            <div class="col" style="margin-top: 20px;"> -->
              <mat-checkbox style="margin-top: 20px;" [checked]="getFormControlByName('status').value == 1"
                (change)="check($event.checked)">Is
                Active</mat-checkbox>
            </div> 
            <div class="col-lg-6">
              <input #fileInput type="file" [hidden]="true" (change)="handleFileInput($event.target.files[0])"
                accept=".jpg,.jpeg, .tiff, .png " />
                
              <button *ngIf="!selectedFile" mat-raised-button color="accent"
                 (click)="fileInput.click()" matTooltip="Upload Banner"
                matTooltipPosition="above" class="mt-3">Upload Image</button>

              <button *ngIf="selectedFile" mat-raised-button color="accent"  class="mt-3"
                (click)="clearFileInput()">{{selectedFile?.name}}
                <mat-icon>delete</mat-icon>
              </button>
              <!-- <div >
                <a   target="_blank" [href]="lookupsForm.controls.imagePath.value">
                  <img src="{{image_src}}">
                </a>
                <a mat-icon-button color="accent" (click)="clearFileInput()">{{selectedFile?.name}}
                  <mat-icon>delete</mat-icon>
                </a>
              </div> -->
              <div class="avatar-upload"  *ngIf="image_src">
                <div class="avatar-preview">
                  <div id="imagePreview" style="background-image: url({{image_src}});">
                    <a mat-icon-button color="accent" (click)="clearFileInput()" class="avatar-edit d-flex align-items-center"> 
                      <mat-icon class="mx-auto">delete</mat-icon>
                    </a>
                  </div>
                </div>
              </div>
              <mat-error *ngIf="lookupsForm.controls.imagePath.invalid && lookupsForm.touched" class="mt-2"
                style="font-size: 14px !important;">Image is Required *</mat-error>
            </div>

          </div>
          <div class="row">
            <div class="col text-right" style="margin-top: 20px;">
              <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                style="margin: 1px;">Add</button>
              <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                style="margin: 1px;">Update</button>
              <button mat-raised-button color="accent" [routerLink]="['/business-partners']" style="margin: 1px;"
                class="ml-2">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>