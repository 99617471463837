<div class="text-center">
  <div class="row">
    <div class="col-lg-6 mx-auto">
      <form [formGroup]="changePasswordForm">
      <mat-card class="text-center">
        <div class="row">
          <div class="col">
           <h2>Change Password</h2>
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Mobile Number</mat-label>
              <input matInput formControlName="mobileNumber" type="text" id="username" placeholder="Must be in intenational format ex : +966123456789">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>New Password</mat-label>
              <input matInput  type="text" formControlName="newPassword" id="password">
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
           <button mat-raised-button color="accent" (click)="submitForm()">Change Password</button>
          </div>
        </div>
      </mat-card>
    </form>
    </div>
  </div>
</div>


