<h2>Choose Sub Category</h2> 
<div id="root">
    <div class="container pt-5">
      <div class="row align-items-stretch">
        <div class="c-dashboardInfo col-lg-3 col-md-6" *ngFor="let item of subCategoryList" (click)="next(item.id)" style="cursor: pointer;">
          <div class="wrap">
            <h3 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">   {{item.name}}
               </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
