import { ContactUsComponent } from './contact-us/contact-us.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { FaqsComponent } from './faqs/faqs.component';
import { CouponManagementComponent } from './coupon-management/coupon-management.component';
import { ProviderManagmentComponent } from './provider-managment/provider-managment.component';
import { ChangePasswordAdminComponent } from './change-password-admin/change-password-admin.component';
import { ProductFormAddComponent } from './products/product-form-add/product-form-add.component';
import { SizeComponent } from './size/size.component';
import { ColorComponent } from './color/color.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ProductsComponent } from './products/products.component';
import { CategoryComponent } from './category/category.component';
import { BrandComponent } from './brand/brand.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users/users.component';
import { PushNotificationsComponent } from './push-notifications/push-notifications.component';
import { UserOrdersComponent } from './user-orders/user-orders.component';
import { OrderDetailsComponent } from './user-orders/order-details/order-details.component';
import { SaleComponent } from './sale/sale.component';
import { ProductFormComponent } from './products/product-form/product-form.component';
import { ChooseCategoryComponent } from './products/choose-category/choose-category.component';
import { ChooseSubCategoryComponent } from './products/choose-sub-category/choose-sub-category.component';
import { ChooseGenderComponent } from './products/choose-gender/choose-gender.component';
import { RoleGuard } from 'src/auth/role.guard';
import { NewsComponent } from './news/news.component';
import { ChooseSubCategoryUsersComponent } from './sub-category-users/choose-sub-category-users/choose-sub-category-users.component';
import { SubCategoryUsersComponent } from './sub-category-users/sub-category-users/sub-category-users.component';
import { ApplicationExceptionsComponent } from './application-exceptions/application-exceptions.component';
import { UserApproverListComponent } from './user-approver-list/user-approver-list.component';
import { AddUsersComponent } from './add-users/add-users.component';
import { EditUsersComponent } from './edit-users/edit-users.component';
import { LookupsComponent } from './lookups-management/lookups.component';
import { BusinessPartnersComponent } from './business-partners/business-partners.component';
import { settings } from 'cluster';
import { SettingsComponent } from './settings/settings.component';
import { BulkProductPricesComponent } from './bulk-product-prices/bulk-product-prices.component';
import { DriversRequestsComponent } from './drivers-requests/drivers-requests.component';
import { MissingItemsComponent } from './missing-items/missing-items.component';
import { IntegrationsComponent } from './integrations/integrations.component';
import { BannersComponent } from './Banners/banners.component';
import { BusinessPartnersAddComponent } from './business-partners-add/business-partners-add.component';
import { BrandAddComponent } from './brand-add/brand-add.component';
import { BannerAddComponent } from './banner-add/banner-add.component';
import { ProviderManagmentAddComponent } from './provider-managment-add/provider-managment-add.component';
import { CategoryAddComponent } from './category-add/category-add.component';
import { SubCategoryAddComponent } from './sub-category-add/sub-category-add.component';
import { CouponAddComponent } from './coupon-add/coupon-add.component';
import { FaqAddComponent } from './faq-add/faq-add.component';
import { ReviewAddComponent } from './review-add/review-add.component';


const routes: Routes = [
  { component: LookupsComponent, path: 'lookups', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BrandComponent, path: 'brand', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BrandAddComponent, path: 'add-brand', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BrandAddComponent, path: 'edit-brand/:brandId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: NewsComponent, path: 'news', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: CategoryComponent, path: 'category', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: CategoryAddComponent, path: 'add-category', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: CategoryAddComponent, path: 'edit-category/:editId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BannersComponent, path: 'banner', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin', 'BannerUser', 'ContentCreator'] } },
  { component: BannerAddComponent, path: 'add-banner', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin', 'BannerUser', 'ContentCreator'] } },
  { component: BannerAddComponent, path: 'edit-banner/:bannerId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin', 'BannerUser', 'ContentCreator'] } },
  
  { component: ProductsComponent, path: 'product', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SubCategoryComponent, path: 'sub-category', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SubCategoryAddComponent, path: 'add-sub-category', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SubCategoryAddComponent, path: 'edit-sub-category/:editId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ColorComponent, path: 'color', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SizeComponent, path: 'size', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SettingsComponent, path: 'settings', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  {
    component: ProductFormAddComponent, path: 'product-form-add/:categoryId/:subCategoryId',
    canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] }
  },
  { component: ProductFormComponent, path: 'product-form/:itemId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: UsersComponent, path: 'users', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin', 'ContentCreator'] } },
  { component: PushNotificationsComponent, path: 'push-notifications', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin', 'ContentCreator'] } },
  { component: UserOrdersComponent, path: 'user-orders', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: OrderDetailsComponent, path: 'order-details/:orderId', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SaleComponent, path: 'sale', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ChooseCategoryComponent, path: 'choose-category', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ChooseSubCategoryComponent, path: 'choose-sub-category/:categoryId', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ChooseGenderComponent, path: 'choose-gender/:categoryId/:subCategoryId', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ChooseSubCategoryUsersComponent, path: 'choose-sub-category-users', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: SubCategoryUsersComponent, path: 'sub-category-users/:subCategoryId', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: UserApproverListComponent, path: 'user-approver-list', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: LookupsComponent, path: 'lookups', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BusinessPartnersComponent, path: 'business-partners', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BusinessPartnersAddComponent, path: 'add-business-partners', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BusinessPartnersAddComponent, path: 'edit-business-partners/:businessId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: AddUsersComponent, path: 'add-users', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: EditUsersComponent, path: 'edit-users/:userId', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ChangePasswordAdminComponent, path: 'change-password', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ProviderManagmentComponent, path: 'provider-managment', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ProviderManagmentAddComponent, path: 'add-provider-managment', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ProviderManagmentAddComponent, path: 'edit-provider-managment/:providerId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },

  { component: CouponManagementComponent, path: 'coupon-managment', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: CouponAddComponent, path: 'add-coupon', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: CouponAddComponent, path: 'edit-coupon/:editId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },

  { component: FaqsComponent, path: 'faqs-managment', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: FaqAddComponent, path: 'add-faq', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: FaqAddComponent, path: 'edit-faq/:editId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ReviewsComponent, path: 'review-managment', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ReviewAddComponent, path: 'add-review', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: ReviewAddComponent, path: 'edit-review/:editId/:isUpdateMode', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },

  { component: ContactUsComponent, path: 'contact-us', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: BulkProductPricesComponent, path: 'bulk-product-prices', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: DriversRequestsComponent, path: 'drivers-requests', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: MissingItemsComponent, path: 'missing-items', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },
  { component: IntegrationsComponent, path: 'integrations', canActivate: [RoleGuard], data: { expectedRole: ['SuperAdmin', 'Admin'] } },

]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SetupRoutingModule {
}
