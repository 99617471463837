<mat-card>
  <mat-card-header>
    <mat-card-title>
     Timer
    </mat-card-title>
  </mat-card-header>
<mat-card-content>
<div class="text-center">
  <div class="row">
    <div class="col">
      <form [formGroup]="reactiveForm">
      <mat-card class="text-center">
        <mat-label></mat-label>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Timer Input</mat-label>
              <input matInput value="{{timerValue}}" type="datetime-local" formControlName="timer" autocomplete="off"   name="timer" placeholder="">
          
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input type="checkbox" (change)='ChangeStatus()'  [checked]="isActive" id="chbx" style="cursor: pointer;outline: none;">
            &nbsp;&nbsp;<mat-label (click)='ChangeStatus()'>Is Active</mat-label>
          </div>
        </div>
        <bR>
        <div class="row">
          <div class="col">
           <button mat-raised-button color="accent" (click)="SubmitForm()">Submit</button>
          </div>
        </div>
      </mat-card>
    </form>
    </div>
  </div>
</div>  </mat-card-content>
</mat-card>

