<mat-card>
  <mat-card-content>

    <div class="row">
      <mat-card *ngFor="let item of applicationExceptionsList" class="col-xs-12 col-xs-12 col-md-4" style="margin-top: 5px;">
        <div class="row">
        <div class="col-12">
          <b> User info:</b>  {{item.loggedInUser  +' -  '+ item.innerException}}
          <br>
          <b> Error:</b> {{item.errorMessage}}
        </div>

      </div>
        <hr>
        <mat-card-actions class="text-right">
          <a mat-raised-button color="accent" class="call-btn" [href]="'tel:'+item.innerException">Call</a>
        </mat-card-actions>
      </mat-card>
    </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount"  [pageSize]="pageSize"    [pageIndex]="pageIndex"  [pageSizeOptions]="[1, 10, 25, 100]" ></mat-paginator>
  </mat-card-content>
</mat-card>
