<h2>Sub Category Users</h2> 

<div id="root">
    <div class="container pt-5">
     <!--  <div class="row">
        <div class="col-12">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Search Users</mat-label>
            <input type="text" (keyup)="filterUsers($event)" matInput [(ngModel)]="searchValue">
          </mat-form-field>
        </div>
      </div> -->
      <div class="row align-items-stretch">
        <div class="c-dashboardInfo col-lg-3 col-md-6" *ngFor="let item of users" style="cursor: pointer;">
          <div class="wrap">
            <h3 class="heading heading5 hind-font medium-font-weight c-dashboardInfo__title">   {{item.fullName}}
               </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
