<mat-card>
  <mat-card-header>
    <mat-card-title>
      Product Details
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="productForm">
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Title En</mat-label>
                <input type="text" matInput formControlName="titleEn">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Title Ar</mat-label>
                <input type="text" matInput formControlName="titleAr">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select formControlName="categoryId">
                  <mat-option *ngFor="let category of categoryList" [value]="category.id"
                    (onSelectionChange)="getSubCategoryByCategories(category.id)">
                    {{category.categoryName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Sub Category</mat-label>
                <mat-select formControlName="subCategoryId">
                  <mat-option *ngFor="let subCategory of subCategoryList" [value]="subCategory.id">
                    {{subCategory.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Brand</mat-label>
                <mat-select formControlName="brandId">
                  <mat-option *ngFor="let brand of brandsList" [value]="brand.id">
                    {{brand.brandNameEn}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Provider</mat-label>
                <mat-select formControlName="providerId">
                  <mat-option *ngFor="let provider of providersList" [value]="provider.id">
                    {{provider.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>SKU #</mat-label>
                <input type="text" matInput formControlName="sku">
              </mat-form-field>
            </div>
            <!-- <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Availability date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="availability">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div> -->
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Description En</mat-label>
                <textarea matInput formControlName="descriptionEn"></textarea>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Description Ar</mat-label>
                <textarea matInput formControlName="descriptionAr"></textarea>
              </mat-form-field>
            </div>
            <hr>
            <hr>
            <hr>

          </div>

          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Item Order</mat-label>
                <input type="number" min="1" matInput formControlName="itemOrder">
                <mat-hint>Leave it empty for default order</mat-hint>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="1">Active </mat-option>
                  <mat-option [value]="2">In Active </mat-option>
                  <mat-option [value]="8">Empty </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Madar Id</mat-label>
                <input type="text" matInput formControlName="madarIntegrationId">
              </mat-form-field>
            </div>

            <!-- <div class="col align-self-center">
    <mat-checkbox [disabled]="!isUpdateMode" [checked]="getProductFormControlByName('isFeaturedProduct').value == 1" (change)="check($event.checked)">Is Featured Product?</mat-checkbox>
  </div> -->
          </div>
          <!-- <div class="row">
            <div class="col">
              <mat-checkbox class="example-margin" [checked]="getProductFormControlByName('isItemIdhidden').value" (change)="isItemHiddenChanged($event)" formControlName="isItemIDHidden">Is Item Id hidden
              </mat-checkbox>
            </div>
          </div> -->
          <div class="row" *ngIf="setItemsList.length>0 && getProductFormControlByName('isSet').value==true">
            <div class="col-4">
              <mat-label>Selected Items</mat-label><br>
              <img *ngFor="let item of setItemsList" class="selected-img"
                [src]="item.defaultImageUrl? imageUrl+ item.defaultImageUrl+imageSasToken :'../../assets/Images/logo.png'"
                alt="Default Item Image">
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card *ngIf="showEditor">
  <mat-card-header>
    <mat-card-title>
      Custom Description And Specification
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <div class="row">
      <div class="col">
        <mat-label>
          English Custom Description And Specification
        </mat-label>
        <br />
        <ckeditor [editor]="customDescriptionEditorEn" [data]="customeDescriptionAndValueEn"
          (change)="onChangeDescriptionEn($event)"></ckeditor>
      </div>
      <div class="col">
        <mat-label>
          Arabic Custom Description And Specification
        </mat-label>
        <br />
        <ckeditor [editor]="customDescriptionEditorAr" [data]="customeDescriptionAndValueAr"
          (change)="onChangeDescriptionAr($event)"></ckeditor>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<mat-card>
  <mat-card-header>
    <mat-card-title>
      Item Price
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content>
    <form #form="ngForm" novalidate [formGroup]="itemPriceForm" (submit)="onAddItemPrice()" *ngIf="isUpdateMode">
    <!-- <form [formGroup]="itemPriceForm" class="col-12"> -->
      <div class="row">
        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Pricing Method</mat-label>
            <mat-select formControlName="pricingMethod" (selectionChange)="changeSizeValidation()">
              <mat-option *ngFor="let method of pricingMethods" [value]="method.id">
                {{method.nameEn}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="itemPriceForm.controls.pricingMethod.invalid"  
              style="font-size: 14px !important;">This field
              is Required *</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Item Price</mat-label>
            <input type="number" min="1" matInput formControlName="priceBeforDiscount">
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>UnitType</mat-label>
            <input type="text" matInput formControlName="unitType">
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Discount</mat-label>
            <input type="text" matInput formControlName="discount" appPercentageDirective>
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Stock</mat-label>
            <input type="text" matInput formControlName="stock">
          </mat-form-field>
        </div>

        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Size</mat-label>
            <input type="text" matInput formControlName="size">
          </mat-form-field>
        </div>
        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Integration Id</mat-label>
            <input type="text" matInput formControlName="integrationId">
          </mat-form-field>
        </div>
        <div class="col-md-3 text-center">
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="status">
              <mat-option [value]="1">Active </mat-option>
              <mat-option [value]="2">In Active </mat-option>
              <mat-option [value]="8">Empty </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-md-3 align-self-center">
          <button mat-raised-button color="accent" 
            *ngIf="!(itemPriceForm.controls['id'].value > 0)">
            Save
          </button>
          <button mat-raised-button color="accent" 
            *ngIf="itemPriceForm.controls['id'].value > 0">
            Save
          </button>
        </div>
      </div>
    </form>
    <div class="row mt-2">
      <div class="col-12 text-center">
        <table class="table" *ngIf="itemPrice.length > 0">
          <thead>
            <tr>
              <th scope="col">UnitType</th>
              <th scope="col">Pricing Method</th>
              <th scope="col">Size</th>
              <th scope="col">Price</th>
              <th scope="col">Discount</th>
              <th scope="col">Stock</th>
              <th scope="col">Status</th>
              <th scope="col">Integration Id</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let price of itemPrice">
              <td>{{price.unitType}}</td>
              <td>{{getPricingMethod(price.pricingMethod)}}</td>
              <td>{{price.size}}</td>
              <td>{{price.priceBeforDiscount}}</td>
              <td>{{price.discount}}</td>
              <td>{{price.stock}}</td>
              <td>{{GetStatusName(price.status)}}</td>
              <td>{{price.integrationId}}</td>
              <td>
                <button mat-raised-button color="accent" class="mx-1" (click)="onEditItemPrice(price)"
                  *ngIf="isUpdateMode">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-raised-button color="accent" class="mx-1" (click)="onDeleteItemPrice(price)"
                  *ngIf="isUpdateMode">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<br>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      Suggested Products
    </mat-card-title>
  </mat-card-header>
  <hr>
  <mat-card-content >
    <div class="row">
      <div class="col">
        <ng-select appearance="outline" placeholder="Suggested Products" [multiple]="true"   
          [(ngModel)]="suggestedItemsList" [ngModelOptions]="{standalone: true}">
          <ng-option *ngFor="let item of itemsList" [value]="item.id">{{item.sku}} - {{item.titleEn}}</ng-option>
        </ng-select>
      </div>

    </div>
  </mat-card-content>
</mat-card>

<br>
<div class="row">
  <div class="col-12">
    <mat-card class="padding-35">
      <mat-card-header>
        <mat-card-title>
          Product Images
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-card class="col" style="margin-top: 5px;">
            <div class="row" *ngIf="isUpdateMode">
              <div class="col-4">
                <mat-label>Choose product images</mat-label>
                <button mat-button color="warn" (click)="onImagesSelected()">
                  <mat-icon>file_upload</mat-icon>
                  Upload
                </button>
                <input type="file" id="fileUpload" name="fileUpload" multiple="multiple" accept="{{accept}}"
                  style="display:none;" />
              </div>
            </div>
            <div class="row" *ngIf="isUpdateMode">
              <button class="remove-btn" mat-mini-fab color="accent" (click)="removeImageFromGallery()"
                *ngIf="galleryImages.length" mat-button color="warn">
                <mat-icon>close</mat-icon>
              </button>
              <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
            </div>
            <div class="row" *ngIf="!isUpdateMode">
             
              <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
            </div>
          </mat-card>
        </div>
        <div class="row">
          <mat-card class="col" style="margin-top: 5px;">
            <div class="row">
              <div class="col-4">
                <mat-label>Choose item Video</mat-label>
                <button mat-button color="warn" (click)="onVideoSelected()" *ngIf="isUpdateMode">
                  <mat-icon>file_upload</mat-icon>
                  Upload
                </button>
                <input type="file" id="videoUpload" name="videoUpload" accept="{{videoAccept}}" style="display:none;" />
              </div>
            </div>
            <div class="row" *ngIf="videoToShow">
              <button class="remove-btn" mat-mini-fab color="accent" (click)="removeVideo()" *ngIf="videoToShow"
                mat-button color="warn">
                <mat-icon>close</mat-icon>
              </button>
              <video width="270" height="220" controls disabled="true" (click)="toggleVideo()" autoplay>
                <source [src]="videoToShow">
              </video>
            </div>
          </mat-card>
        </div>
        <div class="row">
          <div class="col text-right" style="margin-top: 20px;">
            <button mat-raised-button color="accent" (click)="submitForm()" style="margin: 1px;"
              *ngIf="isUpdateMode">Update Product</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<br>