import { environment } from 'src/environments/environment';
import { CategoryCustomService } from './../../shared/services/category-custom.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Controllers, Actions } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  displayedColumns: string[] = ['CategoryNameEn','CategoryNameAr', 'Image','Status', 'actions'];
  
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  public hostLink = environment.host;
  @ViewChild(MatPaginator, { read: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<any>;
  public categoryList;

  public totalListCount;
  pageIndex = 0;
  pageSize=10;
  constructor(
    private baseService: BaseService,
    private categoryCustomService: CategoryCustomService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }

  ngOnInit(): void {
    this.getAllCategories({ pageSize: 10, pageNumber: 1 });
  }
  searchValue: string;
  applyFilter( ): void {
   
    this.pageIndex =0;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.getAllCategories({ pageSize: this.pageSize, pageNumber: 1, nameAr: this.searchValue ,nameEn:this.searchValue});
  }


  editItem(element: any): void {
    this.router.navigate(['/edit-category/' + element.id+'/true'])  
  }

  getAllCategories(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.Category;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.categoryList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.categoryList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.log(error);
    });
  }

 
  deleteItem(category): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete Category',
          content: 'Are you sure you want to delete a category?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.Category;
        this.baseService.removeItem(Controller, category.id).subscribe(res => {
          this.notification.showNotification('Category ', 'Category Deleted Successfully', 'success');
          this.deleteItemFromList(category.id);
          this.spinner.hide();
        }, error => {
          this.spinner.hide();
          if (error.status === 400) {
            this.notification.showNotification('Delete Category Failed', error.error.Message, 'error');
          }
          else {
            this.notification.showNotification('Delete Category Failed', 'Something went wrong please contact system admin', 'error');
          }
        });
      }
    });
  }

  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllCategories({ pageSize: event.pageSize, pageNumber: event.pageIndex + 1 , nameAr: this.searchValue ,nameEn:this.searchValue});
  }


  // cancel(): void {
  //   this.isEdit = false;
  //   this.categoryForm.reset();
  //   this.categoryForm.markAsUntouched()
  //   this.getFormControlByName('status').setValue(2);
  //   this.getFormControlByName('id').setValue(0);
  //   this.getFormControlByName('isDisplayedInHomePage').setValue(false);
  //   this.clearFileInput()
  // }

  pushItemToList(item): void {
    this.categoryList.push(item);
    this.dataSource = new MatTableDataSource(this.categoryList)
  }

  deleteItemFromList(id: number): void {
    this.categoryList = this.categoryList.filter(category => category.id !== id);
    this.dataSource = new MatTableDataSource(this.categoryList)
  }

  
}
