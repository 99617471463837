<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Brand': 'Edit Brand'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="brandForm">
            <div class="row">
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Brand Name En </mat-label>
                  <input type="text" matInput formControlName="brandNameEn">
               
                <mat-error *ngIf="brandForm.controls.brandNameEn.invalid" 
                style="font-size: 14px !important;">Brand Name En is Required *</mat-error>
              </mat-form-field>
              </div>
              <div class="col-lg-4 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Brand Name Ar</mat-label>
                  <input type="text" matInput formControlName="brandNameAr">
              
                <mat-error *ngIf="brandForm.controls.brandNameAr.invalid" 
                style="font-size: 14px !important;">Brand Name Ar is Required *</mat-error>
              </mat-form-field>
              </div>
              <div class="col" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">
                  Is Active?</mat-checkbox>
              </div>
  
            </div>
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                  style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                  style="margin: 1px;">Update</button>
                <button mat-raised-button color="accent"  [routerLink]="['/brand']"  style="margin: 1px;" class="ml-2">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>