<mat-card>
  <mat-card-header class="d-flex justify-content-between align-items-center title-border">
    <mat-card-title class="mb-0">
      Products List
    </mat-card-title>
    <div><button mat-raised-button color="accent" (click)="navigateToAdd()" class="m-1">Add New Product</button></div>
  </mat-card-header>
  <div class="text-right text-nowrap overflow-auto  title-border">

    <!-- <button mat-raised-button color="accent" class="m-1" (click)="toggleProducts(2, 1)">
      Mark All Products As Active
    </button>

    <button mat-raised-button color="accent" class="m-1" (click)="toggleProducts(1, 2)">
      Mark All Products As Inactive
    </button> -->

    <button mat-raised-button color="warn" (click)="ImportItems()" class="m-1"><mat-icon>file_upload</mat-icon>Import
      Products</button>

    <button mat-raised-button color="accent" (click)="ExportTemplate()" class="m-1">Export Template</button>

    <input type="file" id="fileUpload" name="fileUpload"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" style="display:none;" />

    <button mat-raised-button color="accent" (click)="ExportItems()" class="m-1">Export Products</button>

    <!-- <button mat-raised-button color="accent" (click)="ExportXMLItems('en','google')" class="m-1">Google en</button>
    <button mat-raised-button color="accent" (click)="ExportXMLItems('ar','google')" class="m-1">Google ar</button>
    
    <button mat-raised-button color="accent" (click)="ExportXMLItems('en','meta')" class="m-1">Meta en</button>
    <button mat-raised-button color="accent" (click)="ExportXMLItems('ar','meta')" class="m-1">Meta ar</button> -->

  </div>

  <mat-card-content>
    <div class="row">
      <div class="col">
        <form [formGroup]="filterForm">
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input type="text" matInput formControlName="title">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Brand</mat-label>
                <input type="text" matInput formControlName="brand">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Sub Category Name</mat-label>
                <input type="text" matInput formControlName="subCategoryName">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Item Id</mat-label>
                <input type="number" matInput formControlName="itemId">
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Minimum Price</mat-label>
                <input type="number" matInput formControlName="minPrice" maxlength="10">
              </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Maximum Price</mat-label>
                <input type="number" matInput formControlName="maxPrice"  maxlength="10">
              </mat-form-field>
            </div>

            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option [value]="1">Active </mat-option>
                  <mat-option [value]="2">In Active </mat-option>
                  <mat-option [value]="8">Empty </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col text-right" style="margin-top: 20px;">
              <button mat-raised-button color="accent" (click)="applyFilter()" style="margin: 2px;">Search</button>
              <button mat-raised-button class="ml-2" color="accent" (click)="clearFilters()" style="margin: 2px;">
                Clear
                Filters
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
    <br>
    <hr>
    <br>
    <div class="table-responsive" *ngIf="dataSource" >
      <mat-table
        [dataSource]="dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? dataSource : emptyData">

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Id">
            {{element.id}}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef>Title</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Title">
            {{element.titleEn}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="arabicTitle">
          <mat-header-cell *matHeaderCellDef>Arabic Title</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Address">
            {{element.titleAr}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="category">
          <mat-header-cell *matHeaderCellDef>Category</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Category">
            {{element.categoryName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subCategory">
          <mat-header-cell *matHeaderCellDef>Sub Category</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Sub Category">
            {{element.subCategoryName}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Price">
          <mat-header-cell *matHeaderCellDef>Price</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Price">
            {{element.price}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Brand">
          <mat-header-cell *matHeaderCellDef>Brand</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Brand">
            {{element.brandNameEn}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="itemCount">
          <mat-header-cell *matHeaderCellDef>Item Count</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Item Count">
            {{element.itemCount}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Status">
            <mat-chip-list aria-label="Status">
              <mat-chip color="accent" selected *ngIf="element?.status == 1">Active</mat-chip>
              <mat-chip color="accent" *ngIf="element?.status == 2" style="white-space: nowrap;">In Active</mat-chip>
              <mat-chip color="warn" *ngIf="element?.status == 8">Empty</mat-chip>
              <mat-chip color="accent" *ngIf="element?.isSet">Set Items</mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>


        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef><span
              style="margin-left: 10px; display: inline-block;">Action</span></mat-header-cell>
          <mat-cell *matCellDef="let element" data-label="Actions" class="text-right">
            <button mat-raised-button color="accent" class="mx-2" (click)="navigateToEdit(element.id)">
              Edit
            </button>
            <button mat-raised-button color="accent" (click)="navigateToView(element.id)" class="mx-2"> Details
            </button>
            <button mat-raised-button color="warn" (click)="deleteItem(element)" class="mx-2">Delete</button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="empty-row">
          <mat-cell class="no-data-cell" *matCellDef="let element">No Data Found</mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: dataSource.data.length > 0 && dataSource.filteredData.length > 0 ? displayedColumns : ['empty-row'];"></mat-row>
      </mat-table>
    </div>
    <mat-paginator (page)="changePage($event)" [length]="totalListCount"
      [pageIndex]="getFormControlByName('pageNumber').value-1" [pageSize]="getFormControlByName('pageSize').value"
      [pageSizeOptions]="[1, 10, 25, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>