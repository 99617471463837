<mat-card>
    <mat-card-header>
      <mat-card-title>
        {{!isEdit ? 'Add Category': 'Edit Category'}}
      </mat-card-title>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <form [formGroup]="categoryForm">
            <div class="row">
              <div class="col-lg-3 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>English Category Name</mat-label>
                  <input type="text" matInput formControlName="categoryName">
                  <mat-error *ngIf="categoryForm.controls.categoryName.invalid&& categoryForm.controls.categoryName.touched"  style="font-size: 14px !important;">Category Name En is Required *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Arabic Category Name</mat-label>
                  <input type="text" matInput formControlName="categoryNameAr">
                  <mat-error *ngIf="categoryForm.controls.categoryNameAr.invalid"  style="font-size: 14px !important;">Category Name Ar is Required *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>Order</mat-label>
                  <input type="text" matInput formControlName="order">
                  <mat-error *ngIf="categoryForm.controls.order.invalid"  style="font-size: 14px !important;">Order is Required *</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-3 col-sm-12" style="margin-top: 20px;">
                <input #fileInput type="file" [hidden]="true" (change)="handleFileInput($event.target.files[0])"
                  accept=".jpg,.jpeg, .tiff, .png " />
                <button *ngIf="!selectedFile" mat-raised-button color="accent"
                  style="margin-right:10px;margin-bottom:10px;" (click)="fileInput.click()" matTooltip="Upload Category"
                  matTooltipPosition="above">Upload Image</button>
                <button *ngIf="selectedFile" mat-raised-button color="accent" class="col-10"
                  (click)="clearFileInput()"> 
                </button>
                <mat-error *ngIf="!selectedFile && categoryForm.touched" class="mt-2" style="font-size: 14px !important;">Category Image is Required *</mat-error>
                <!-- <div *ngIf="categoryForm.controls.imagePath.value">
                  <a *ngIf="!selectedFile" target="_blank" [href]="categoryForm.controls.imagePath.value">
                    View  Category Image
                  </a>
                  <a mat-icon-button  color="accent" (click)="clearFileInput()">{{selectedFile?.name}}
                    <mat-icon >delete</mat-icon>
                  </a>
                </div> -->
                <div class="avatar-upload" *ngIf="categoryForm.controls.imagePath.value!=''"  >
                  <div class="avatar-preview">
                    <div id="imagePreview" style="background-image: url({{categoryForm.controls.imagePath.value}});">
                      <a mat-icon-button color="accent" (click)="clearFileInput('imageDesktopEn')" class="avatar-edit d-flex align-items-center"> 
                        <mat-icon class="mx-auto">delete</mat-icon>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-sm-12" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('status').value == 1" (change)="check($event.checked)">
                  Is Active</mat-checkbox>
              </div>
              <div class="col-lg-2 col-sm-12" style="margin-top: 20px;">
                <mat-checkbox [checked]="getFormControlByName('isDisplayedInHomePage').value == 1"
                  (change)="checkDisplayInHomePage($event.checked)">Display in Home Page?</mat-checkbox>
              </div>
  
            </div>
            <div class="row">
              <div class="col text-right" style="margin-top: 20px;">
                <button mat-raised-button color="accent" *ngIf="!isEdit" (click)="submitForm()"
                  style="margin: 1px;">Add</button>
                <button mat-raised-button color="accent" *ngIf="isEdit" (click)="editForm()"
                  style="margin: 1px;">Update</button>
                <button mat-raised-button color="accent" [routerLink]="['/category']" class="ml-2" style="margin: 1px;">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>