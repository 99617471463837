import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthorizeService } from 'src/auth/authorize.service';
import { Actions, Controllers } from 'src/shared/global-variables/api-config';
import { BaseService } from 'src/shared/services/base.service';
import { NotificationService } from 'src/shared/services/notification.service';
import { YesNoDialogComponent } from 'src/shared/shared-components/yes-no-dialog/yes-no-dialog.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  emptyData = new MatTableDataSource([{ empty: "row" }]);
  displayedColumns: string[] = ['createdDate','Name', 'Email', 'subject','message'];
  public isEdit = false;
  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource <any>;
  public contactUsList;
  public baseSearch;
  public totalListCount;
  contactUsForm = new FormGroup({
    id: new FormControl(0),
    name: new FormControl('', Validators.required),
    status: new FormControl(2, Validators.required)
  });
  getFormControlByName(controlName: string): FormControl {
    return this.contactUsForm.get(controlName) as FormControl;
  }
  constructor(
    private baseService: BaseService,
    public spinner: NgxSpinnerService,
    private authService: AuthorizeService,
    private router: Router,
    private yesNoDialog: MatDialog,
    public notification: NotificationService
  ) { }
  pageIndex =0;
  pageSize=10;
  ngOnInit(): void {
    this.getAllContactUss({pageSize: this.pageSize, pageNumber: 1});
  }
  searchValue: string;
  applyFilter( ): void {  
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.pageIndex = 0;
    this.getAllContactUss({pageSize: this.pageSize, pageNumber: 1, nameEn: this.searchValue});
  }

  check(checked: boolean): void {
    this.getFormControlByName('status').setValue(checked ? 1 : 2);
  }
  // tslint:disable-next-line: no-shadowed-variable
  editItem(element: any): void {
    this.isEdit = true;
    this.contactUsForm.patchValue(element);
  }
  getAllContactUss(baseSearch): void {
    this.spinner.show();
    const Controller = Controllers.ContactUs;
    this.baseService.getList(Controller, baseSearch).subscribe(res => {
      this.contactUsList = (res as any).entities;
      this.totalListCount = (res as any).totalCount;
      this.dataSource = new MatTableDataSource(this.contactUsList);
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, 1);
      this.spinner.hide();
    });
  }

  deleteItem(contactUs): void {
    const dialogRef = this.yesNoDialog.open(
      YesNoDialogComponent,
      {
        maxWidth: '400px',
        data:
        {
          title: 'Delete ContactUs',
          content:  'Are you sure you want to delete a contactUs?'
        }
      });
    dialogRef.afterClosed().subscribe(response => {

      if (response) {
        this.spinner.show();
        const Controller = Controllers.ContactUs;
        this.baseService.removeItem(Controller, contactUs.id).subscribe(res => {
          this.notification.showNotification('ContactUs ', 'ContactUs Deleted Successfully', 'success');
          this.deleteItemFromList(contactUs.id);
          this.spinner.hide();
        }, error => {
            this.spinner.hide();
            if (error.status === 400){
              this.notification.showNotification('Delete ContactUs Failed', error.error.Message, 'error');
            }
            else {
              this.notification.showNotification('Delete ContactUs Failed', 'Something went wrong please contact system admin', 'error');
            }
        });
      }
    });
}
  changePage(event): void {
    this.pageIndex = event.pageIndex;
    this.pageSize=event.pageSize;
    this.getAllContactUss({pageSize: event.pageSize, pageNumber: event.pageIndex + 1, nameEn: this.searchValue});
  }
  submitForm(): void {
    if (this.contactUsForm.invalid){
      this.contactUsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const contactUsForm = this.contactUsForm.value;
      this.baseService.postItem(Controllers.ContactUs, Actions.PostItem, contactUsForm).subscribe(response => {
        this.spinner.hide();
        this.pushItemToList(response);
        this.notification.showNotification('Add ContactUs', 'ContactUs Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Add ContactUs Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Add ContactUs Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }

  cancel(): void{
    this.isEdit = false;
    this.getFormControlByName('status').setValue(2);
    this.getFormControlByName('contactUsName').setValue('');
    this.getFormControlByName('id').setValue(0);
  }

  pushItemToList(item): void {
    this.contactUsList.push(item);
    this.dataSource = new MatTableDataSource(this.contactUsList)
  }

  deleteItemFromList(id: number): void{
    this.contactUsList = this.contactUsList.filter(contactUs => contactUs.id !== id);
    this.dataSource = new MatTableDataSource(this.contactUsList)
  }

  editForm(): void {
    if (this.contactUsForm.invalid){
      this.contactUsForm.markAllAsTouched();
      this.notification.showNotification('Form Validation', 'Please Check Form Fields', 'warn');
    }
    else {
      this.spinner.show();
      const contactUsForm = this.contactUsForm.value;
      this.baseService.postItem(Controllers.ContactUs, Actions.EditItem, contactUsForm).subscribe(response => {
        this.spinner.hide();
        this.deleteItemFromList((response as any).id);
        this.pushItemToList(response);
        this.notification.showNotification('Edit ContactUs', 'ContactUs Modified Successfully', 'success');
        this.cancel();
      }, error => {
        console.log(error);
        if (error.status === 400){
          this.notification.showNotification('Edit ContactUs Failed', error.error.Message, 'error');
        }
        else {
          this.notification.showNotification('Edit ContactUs Failed', 'Something went wrong please contact system admin', 'error');
        }
        this.spinner.hide();
      });
    }
  }
}
